import React, { useEffect } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";

interface LoadingModalProps {
  modalLoadingOpen: boolean;
  closeLoadingModal: () => void;
}

const LoadingModal = ({
  modalLoadingOpen,
  closeLoadingModal,
}: LoadingModalProps) => {
  useEffect(() => {}, [modalLoadingOpen]);

  return (
    <div>
      <Modal
        backdrop="static"
        show={modalLoadingOpen}
        onHide={closeLoadingModal}
      >
        <ModalBody>
          <div className="loadingDiv">
            <Spinner className="loadingSpinner" animation="border" />
          </div>
          <br />
          Ihre Daten werden geladen. Dies kann einen Moment dauern.
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LoadingModal;
