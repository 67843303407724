/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useContext } from "react";
import { Button, Card, Tab } from "react-bootstrap";
import { Accordion, Col, Row, Tabs } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { HomePatientContext } from "../../contexts/HomePatientContext";
import NavigationBar from "../navigation/NavigationBar";
import TimeoutSession from "../../helper/TimeoutSession";
import SidebarPatient from "../sidebars/SidebarPatient";
import Footer from "../Footer";
import { AppContext } from "../../AppProvider";
interface HomePatientProps {
  title: string;
}
const HomePatient: React.FC<HomePatientProps> = ({ title }) => {
  const {
    state: homeState,
    openReport,
    openSurvey,
    handleAccordion,
  } = useContext(HomePatientContext);
  const { state: appState } = useContext(AppContext);

  React.useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="header-fixed" id="body">
      <TimeoutSession />
      <div className="skip-link-div">
        <a className="skip-link" href="#main-content">
          Direkt zum Inhalt springen
        </a>
      </div>
      <NavigationBar />
      <div className="page-wrapper-new">
        <div className="wrapper">
          <div className="skip-sidebar-div">
            <a className="skip-sidebar" href="#main-content">
              Navigation überspringen
            </a>
          </div>
          <SidebarPatient />
          <div className="content-wrapper">
            <div role="main" id="main-content" className="content">
              <Row>
                <Col>
                  <Accordion
                    defaultActiveKey="0"
                    activeKey={appState.accordionData?.accordionCurrent}
                    className="card-default"
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        onClick={() => handleAccordion("current")}
                      >
                        Aktuelles
                      </Accordion.Header>
                      <Accordion.Body tabIndex={0}>
                        {homeState.cntStws.current === 0
                          ? "Keine Befragung ausstehend. Die bisherigen Befragungen, abgelaufenen Befragungen und geplanten Befragungen werden Ihnen unter Befragungen angezeigt."
                          : homeState.stws &&
                            homeState.stws.length > 0 &&
                            homeState.stws.map((stw) => {
                              return stw.start <= homeState.today &&
                                stw.end >= homeState.today ? (
                                <>
                                  {homeState.survey ? (
                                    <Card key={stw.type} border="primary">
                                      <Card.Body>
                                        {(() => {
                                          switch (stw.type) {
                                            case "PRE":
                                              return (
                                                <Card.Title
                                                  role="heading"
                                                  aria-level={5}
                                                >
                                                  Aktuelle Befragung:
                                                  Erstgespräch
                                                </Card.Title>
                                              );
                                            case "INTERMEDIATE":
                                              return (
                                                <Card.Title
                                                  role="heading"
                                                  aria-level={5}
                                                >
                                                  Aktuelle Befragung:
                                                  Zwischenbefragung
                                                </Card.Title>
                                              );
                                            case "POST":
                                              return (
                                                <Card.Title
                                                  role="heading"
                                                  aria-level={5}
                                                >
                                                  Aktuelle Befragung:
                                                  Therapieende
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_1":
                                              return (
                                                <Card.Title
                                                  role="heading"
                                                  aria-level={5}
                                                >
                                                  Aktuelle Befragung: Nachsorge
                                                  6 Monate
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_2":
                                              return (
                                                <Card.Title
                                                  role="heading"
                                                  aria-level={5}
                                                >
                                                  Aktuelle Befragung: Nachsorge
                                                  12 Monate
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_3":
                                              return (
                                                <Card.Title
                                                  role="heading"
                                                  aria-level={5}
                                                >
                                                  Aktuelle Befragung: Nachsorge
                                                  24 Monate
                                                </Card.Title>
                                              );

                                            default:
                                              return null;
                                          }
                                        })()}
                                        <Card.Text>
                                          Die Befragung muss im Zeitraum von{" "}
                                          <strong>{stw.start_show}</strong> bis{" "}
                                          <strong>{stw.end_show}</strong>{" "}
                                          ausgefüllt werden.
                                        </Card.Text>
                                        {homeState.survey ? (
                                          <Button onClick={openSurvey}>
                                            Befragung öffnen
                                          </Button>
                                        ) : (
                                          <Button disabled aria-disabled>
                                            Befragung abgeschlossen
                                          </Button>
                                        )}
                                      </Card.Body>
                                    </Card>
                                  ) : (
                                    "Die aktuelle Befragung wurde bereits ausgefüllt."
                                  )}
                                </>
                              ) : null;
                            })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col className="col-8">
                  <Accordion
                    activeKey={appState.accordionData?.accordionInformation}
                    className="card-default"
                  >
                    <Accordion.Item eventKey="1">
                      <Accordion.Header onClick={() => handleAccordion("info")}>
                        Profil
                      </Accordion.Header>
                      <Accordion.Body tabIndex={0}>
                        <Row className="profile-data">
                          <Col sm={4}>SysDok-ID</Col>
                          <Col sm={8}>{homeState.userInfo?.userName}</Col>
                        </Row>

                        <Row className="profile-data">
                          <Col sm={4}>Ambulanz</Col>
                          <Col sm={8}>{homeState.userInfo?.clinicName}</Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
              <Accordion
                activeKey={appState.accordionData?.accordionSurvey}
                className="card-default"
              >
                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={() => handleAccordion("survey")}>
                    Befragungen
                  </Accordion.Header>
                  <Accordion.Body>
                    <Tabs
                      defaultActiveKey="current"
                      id=""
                      className="mb-3 tabs-custom"
                    >
                      <Tab eventKey="current" title="Aktuelle Befragung">
                        <Row xs={1} md={2} lg={3}>
                          {homeState.cntStws.current !== 0 ? (
                            Array.isArray(homeState.stws) &&
                            homeState.stws.length > 0 ? (
                              homeState.stws.map((stw, index) => {
                                return stw.start <= homeState.today &&
                                  stw.end >= homeState.today ? (
                                  <Card
                                    tabIndex={0}
                                    className="card-patient-custom"
                                    key={index}
                                  >
                                    <Card.Body>
                                      {(() => {
                                        switch (stw.type) {
                                          case "PRE":
                                            return (
                                              <Card.Title
                                                role="heading"
                                                aria-level={5}
                                              >
                                                Aktuelle Befragung: Erstgespräch
                                              </Card.Title>
                                            );
                                          case "INTERMEDIATE":
                                            return (
                                              <Card.Title
                                                role="heading"
                                                aria-level={5}
                                              >
                                                Aktuelle Befragung:
                                                Zwischenbefragung
                                              </Card.Title>
                                            );
                                          case "POST":
                                            return (
                                              <Card.Title
                                                role="heading"
                                                aria-level={5}
                                              >
                                                Aktuelle Befragung: Therapieende
                                              </Card.Title>
                                            );
                                          case "FOLLOW_UP_1":
                                            return (
                                              <Card.Title
                                                role="heading"
                                                aria-level={5}
                                              >
                                                Aktuelle Befragung: Nachsorge 6
                                                Monate
                                              </Card.Title>
                                            );
                                          case "FOLLOW_UP_2":
                                            return (
                                              <Card.Title
                                                role="heading"
                                                aria-level={5}
                                              >
                                                Aktuelle Befragung: Nachsorge 12
                                                Monate
                                              </Card.Title>
                                            );
                                          case "FOLLOW_UP_3":
                                            return (
                                              <Card.Title
                                                role="heading"
                                                aria-level={5}
                                              >
                                                Aktuelle Befragung: Nachsorge 24
                                                Monate
                                              </Card.Title>
                                            );

                                          default:
                                            return null;
                                        }
                                      })()}
                                      <Card.Text>
                                        Die Befragung muss im Zeitraum von{" "}
                                        <strong>{stw.start_show}</strong> bis{" "}
                                        <strong>{stw.end_show}</strong>{" "}
                                        ausgefüllt werden.
                                      </Card.Text>
                                      {homeState.survey ? (
                                        <Button onClick={openSurvey}>
                                          Befragung öffnen
                                        </Button>
                                      ) : (
                                        <Button disabled>
                                          Befragung abgeschlossen
                                        </Button>
                                      )}
                                    </Card.Body>
                                  </Card>
                                ) : null;
                              })
                            ) : null
                          ) : (
                            <div tabIndex={0}>
                              Es gibt momentan keine aktuelle Befragung.
                            </div>
                          )}{" "}
                        </Row>
                      </Tab>

                      <Tab eventKey="completed" title="Abgelaufene Befragungen">
                        <Row xs={1} md={2} lg={3}>
                          {homeState.cntStws.completed !== 0 ? (
                            homeState.stws &&
                            homeState.stws.length > 0 &&
                            homeState.stws.map((stw, index) => {
                              return stw.end < homeState.today ? (
                                <Card
                                  key={index}
                                  className="card-patient-custom"
                                  tabIndex={0}
                                >
                                  <Card.Body>
                                    {(() => {
                                      switch (stw.type) {
                                        case "PRE":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Abgelaufene Befragung:
                                              Erstgespräch
                                            </Card.Title>
                                          );
                                        case "INTERMEDIATE":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Abgelaufene Befragung:
                                              Zwischenbefragung
                                            </Card.Title>
                                          );
                                        case "POST":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Abgelaufene Befragung:
                                              Therapieende
                                            </Card.Title>
                                          );
                                        case "FOLLOW_UP_1":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Abgelaufene Befragung: Nachsorge 6
                                              Monate
                                            </Card.Title>
                                          );
                                        case "FOLLOW_UP_2":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Abgelaufene Befragung: Nachsorge
                                              12 Monate
                                            </Card.Title>
                                          );
                                        case "FOLLOW_UP_3":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Abgelaufene Befragung: Nachsorge
                                              24 Monate
                                            </Card.Title>
                                          );

                                        default:
                                          return null;
                                      }
                                    })()}
                                    <Card.Text>
                                      Die Befragung war im Zeitraum vom{" "}
                                      <strong>{stw.start_show}</strong> bis{" "}
                                      <strong>{stw.end_show}</strong> verfügbar.
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              ) : null;
                            })
                          ) : (
                            <div tabIndex={0}>
                              {" "}
                              Es gibt momentan keine abgelaufenen Befragungen.
                            </div>
                          )}{" "}
                        </Row>
                      </Tab>
                      <Tab eventKey="planned" title="Geplante Befragungen">
                        <Row xs={1} md={2} lg={3}>
                          {homeState.cntStws.planned !== 0 ? (
                            homeState.stws &&
                            homeState.stws.length > 0 &&
                            homeState.stws.map((stw, index) => {
                              return stw.start > homeState.today ? (
                                <Card
                                  key={index}
                                  className="card-patient-custom"
                                  tabIndex={0}
                                >
                                  <Card.Body>
                                    {(() => {
                                      switch (stw.type) {
                                        case "PRE":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Geplante Befragung: Erstgespräch
                                            </Card.Title>
                                          );
                                        case "INTERMEDIATE":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Geplante Befragung:
                                              Zwischenbefragung
                                            </Card.Title>
                                          );
                                        case "POST":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Geplante Befragung: Therapieende
                                            </Card.Title>
                                          );
                                        case "FOLLOW_UP_1":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Geplante Befragung: Nachsorge 6
                                              Monate
                                            </Card.Title>
                                          );
                                        case "FOLLOW_UP_2":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Geplante Befragung: Nachsorge 12
                                              Monate
                                            </Card.Title>
                                          );
                                        case "FOLLOW_UP_3":
                                          return (
                                            <Card.Title
                                              role="heading"
                                              aria-level={5}
                                            >
                                              Geplante Befragung: Nachsorge 24
                                              Monate
                                            </Card.Title>
                                          );

                                        default:
                                          return null;
                                      }
                                    })()}
                                    <Card.Text>
                                      Die Befragung wird im Zeitraum von{" "}
                                      <strong>{stw.start_show}</strong> bis{" "}
                                      <strong>{stw.end_show}</strong> aktiv
                                      sein.
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              ) : null;
                            })
                          ) : (
                            <div tabIndex={0}>
                              {" "}
                              Es gibt momentan keine geplanten Befragungen.
                            </div>
                          )}
                        </Row>
                      </Tab>
                    </Tabs>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion
                className="card-default"
                activeKey={appState.accordionData?.accordionReport}
              >
                <Accordion.Item eventKey="3">
                  <Accordion.Header onClick={() => handleAccordion("report")}>
                    Berichte
                  </Accordion.Header>
                  <Accordion.Body>
                    {homeState.reports.length === 0 ? (
                      <div tabIndex={0}>
                        Momentan sind keine Berichte verfügbar.
                      </div>
                    ) : null}
                    {homeState.reports &&
                      homeState.reports.length > 0 &&
                      homeState.reports.map((report, index) => {
                        return report !== null ? (
                          <Card
                            key={index}
                            id={String(index)}
                            className="card-patient-custom"
                            tabIndex={0}
                          >
                            <Card.Body>
                              {(() => {
                                switch (report.stw_type) {
                                  case "FOLLOW_UP_1":
                                    return (
                                      <Card.Title role="heading" aria-level={5}>
                                        Bericht: Nachsorge 6 Monate
                                      </Card.Title>
                                    );
                                  case "FOLLOW_UP_2":
                                    return (
                                      <Card.Title role="heading" aria-level={5}>
                                        Bericht: Nachsorge 12 Monate
                                      </Card.Title>
                                    );

                                  default:
                                    return null;
                                }
                              })()}
                              <Card.Text>
                                Sie können Ihren Bericht hier einsehen.
                              </Card.Text>
                              <Button onClick={openReport}>
                                Bericht öffnen
                              </Button>
                            </Card.Body>
                          </Card>
                        ) : null;
                      })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion
                defaultActiveKey="0"
                activeKey={appState.accordionData?.accordionMyClinics}
                onClick={() => handleAccordion("clinics")}
              >
                <Accordion.Item eventKey="4" className="card-default">
                  <Accordion.Header>Ambulanz</Accordion.Header>
                  <Accordion.Body>
                    Ambulanz-Name: <em>{homeState.userInfo?.userName}</em>{" "}
                    <br />
                    Kontakt:{" "}
                    <em>{homeState.userInfo?.["clinicContact"] ?? ""}</em>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Row>
                <Card className="card-default card-custom" tabIndex={0}>
                  <Card.Body>
                    <Card.Title role="heading" aria-level={5}>
                      Anleitung
                    </Card.Title>
                    <Card.Text>
                      Hier finden Sie eine Anleitung zur Benutzung der
                      SysDok-Plattform.
                    </Card.Text>

                    <a
                      tabIndex={-1}
                      href="https://sysdok.de/%5Efiler/canonical/1698387252/146/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button variant="primary">
                        <FiDownload /> Anleitung herunterladen
                      </Button>
                    </a>
                  </Card.Body>
                </Card>
                {/* TODO: for now:
                          `Hilfreiche Anlaufstellen bei psychischer Belastung`
                          should not be shown for SysDok.
                          Code stays here in case it might be required later.*/}
                {/*
                <Card className="card-default card-custom" tabIndex={0}>
                  <Card.Body>
                    <Card.Title role="heading" aria-level={5}>
                      Hilfreiche Anlaufstellen bei psychischer Belastung
                    </Card.Title>
                    <Card.Text>
                      Hier finden Sie eine Sammlung an hilfreichen Anlaufstellen
                      bis hin zu Selbsthilfeangeboten.
                    </Card.Text>

                    <a
                      tabIndex={-1}
                      href={`${process.env.PUBLIC_URL}/files/Hilfreiche_Anlaufstellen.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button variant="primary">
                        <FiDownload /> Liste herunterladen
                      </Button>
                    </a>
                  </Card.Body>
                </Card>
*/}
              </Row>
            </div>
          </div>{" "}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePatient;
