import React from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { getClinicianReport, ReportEntry } from "../../services/getFunctions";
import NavigationBar from "../navigation/NavigationBar";
import SidebarClinician from "../sidebars/SidebarClinician";
import { MdViewList, MdLocalPrintshop } from "react-icons/md";
import { appendScript } from "../../helper/appendScript";
import { removeScript } from "../../helper/removeScript";
import { reportGetSurveySessionReportError } from "../../helper/alertBoxes";
import { RouteComponentProps } from "react-router-dom";
import Footer from "../Footer";
import TimeoutSession from "../../helper/TimeoutSession";

/**Clinician reports component. */
interface ClinicianReportState {
  user_type: string;
  clinic_id: string;
  clinic_name: string;
  username: string;
  survey_session_id: string;
  patient_code: string;
  birthday: string;
  gender: string;
  stw_type: string;
  started_time: string;
  report: string;
  patientReport: boolean;
  patientReports: ReportEntry[];
  reportContent: string;
  today: string;
  accordionMyClinics: string;
  accordionMyPatients: string;
  value: string;
}
interface ClinicianReportProps {
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any;
  title: string;
  history: RouteComponentProps["history"];
}

class ClinicianReport extends React.Component<
  ClinicianReportProps,
  ClinicianReportState
> {
  constructor(props: ClinicianReportProps) {
    super(props);
    this.state = {
      user_type: "clinician",
      clinic_id: "",
      value: "",
      clinic_name: "",
      username: "",
      survey_session_id: "",
      patient_code: "",
      birthday: "",
      gender: "",
      stw_type: "",
      started_time: "",
      report: "",
      patientReport: false,
      patientReports: [],
      reportContent: "",
      today: new Date().toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      accordionMyClinics: "-1",
      accordionMyPatients: "-1",
    };
  }

  /**Method for loading the clinician report from backend.
   * The final_report script would be appended to the component after loading the report from backend.
   */
  componentDidMount() {
    const formattedStartTime = this.props.location.state.started_time.slice(
      0,
      10,
    );
    window.scrollTo(0, 0);
    document.title = this.props.title;
    this.setState({
      clinic_id: this.props.location.state.clinic_id,
      clinic_name: this.props.location.state.clinic_name,
      username: this.props.location.state.username,
      survey_session_id: this.props.location.state.survey_session_id,
      patient_code: this.props.location.state.patient_code,
      birthday: this.props.location.state.birthday,
      gender: this.props.location.state.gender,
      stw_type: this.props.location.state.stw_type,
      started_time: formattedStartTime,
    });

    try {
      getClinicianReport(this.props.location.state.survey_session_id)
        .then((report) => {
          console.log("Report: ", report);
          this.setState({ report: report });
          appendScript(`${process.env.PUBLIC_URL}/js/final_report.js`);
          this.extractScript();
        })
        .catch((error) => {
          reportGetSurveySessionReportError(error);
        });
    } catch (error) {
      /*empty*/
    }

    //TODO: fix after release 1.0.0
    //try {
    //  clinicianGetPatientReportsList(this.props.location.state.patient_code)
    //    .then((reports) => {
    //      console.log("Reports List: ", reports.report_entries);
    //      this.setState({ patientReports: reports.report_entries });
    //      this.checkPatientReport();
    //    })
    //    .catch(() => {
    //      console.error("No patient reports.");
    //    });
    //} catch (error) {
    /*empty*/
    //}
  }

  /**Before component will be destroyed, the final_report script would be removed. */
  componentWillUnmount() {
    removeScript(`${process.env.PUBLIC_URL}/js/final_report.js`);
  }

  /**Check if there is a related patient report. */
  checkPatientReport() {
    for (let i = 0; i < this.state.patientReports.length; i++) {
      if (
        this.state.survey_session_id ===
        this.state.patientReports[i].survey_session_id.toString()
      ) {
        this.setState({ patientReport: true });
      }
    }
  }

  /**Extract scripts in html-response.
   * Run clean javascript-code with window.eval().
   */
  extractScript() {
    const content = this.state.report;
    console.log("Content: ", content);
    if (/<script\s*.*>[\s\S]*<\/script>/gi.exec(content) != null) {
      //<script\s*.*>[\s\S]*<\/script>/gi.exec(content)[0]; here '!' non-null assertion operator
      //asserts that it is not null
      // TODO: remove eslint disables, see issue rehacat_frontend#335
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const extract = /<script\s*.*>[\s\S]*<\/script>/gi.exec(content)![0];
      console.log("Script: ", extract);
      this.setState({ reportContent: extract });
    }

    if (this.state.reportContent !== "") {
      const runScript = this.state.reportContent;
      if (runScript !== undefined) {
        const updateScript = runScript.replace(
          /<\/script>([\s\S]*?)<script[^>]*>/gm,
          "",
        );
        const cleanScript = updateScript.replace(
          /<script[^>]*>([\s\S]*?)<\/script>/gm,
          "$1",
        );

        console.log("Update: ", updateScript);

        console.log("Clean: ", cleanScript);
        // let reportValues = cleanScript.replace(
        //   /let reportValues = {([\s\S]*?)}/gm,
        //   "$1"
        // );
        // console.log("Report Values: ", reportValues);
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-eval
        window.eval(cleanScript);
      }
    }
  }

  /**When clicking on sidebar, check which menu item is selected.
   * Open on dashboard the right accordion.
   */
  handleAccordion = (input) => {
    console.log("Accordion Input: ", input);
    let accPatients = "-1";
    let accClinics = "-1";
    if (input === "myPatients") {
      accPatients = "0";
    } else if (input === "clinics") {
      accClinics = "1";
    }
    if (input === "myPatients" || input === "clinics") {
      this.props.history.push({
        pathname: "/clinician",
        state: {
          clinic_id: this.state.value,
          clinic_name: this.state.clinic_name,
          username: this.state.username,
          accordionMyClinics: accClinics,
          accordionMyPatients: accPatients,
        },
      });
    }
  };

  /**Open patients report. */
  openPatientReport = (event) => {
    event.preventDefault();
    this.props.history.push({
      pathname: "/clinician/patient-report",
      state: {
        clinic_id: this.state.value,
        clinic_name: this.state.clinic_name,
        username: this.state.username,
        survey_session_id: this.state.survey_session_id,
        patient_code: this.state.patient_code,
        birthday: this.state.birthday,
        gender: this.state.gender,
        stw_type: this.state.stw_type,
        started_time: this.state.started_time,
        user_type: this.state.user_type,
      },
    });
  };

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <div className="skip-link-div">
          <a className="skip-link" href="#final-report">
            Direkt zum Inhalt springen
          </a>
        </div>
        <NavigationBar
          clinicId={this.state.clinic_id}
          clinicName={this.state.clinic_name}
          username={this.state.username}
          userType={this.state.user_type}
        />
        <div className="page-wrapper-new">
          <div className="wrapper">
            <div className="skip-sidebar-div">
              <a className="skip-sidebar" href="#final-report">
                Navigation überspringen
              </a>
            </div>
            <SidebarClinician handleAccordion={this.handleAccordion} />
            <div className="content-wrapper">
              <div
                className="content ergebnisbericht_container"
                id="final-report"
              >
                <Col id="report-heading" lg="12">
                  <h1>Ergebnisbericht</h1>
                </Col>

                <Card
                  tabIndex={0}
                  id="report-head-info"
                  className="card-default card-report-custom widget-media p-4 active border"
                >
                  <Card.Body className="m-0 p-0">
                    <Row>
                      <Col className="col-12 col-sm-6 col-print-6">
                        <Row>
                          <Col md="3">
                            <div className="icon rounded-circle-outline ml-2">
                              <i className="mdi mdi-comment-account" />
                            </div>
                          </Col>
                          <Col md="9">
                            <Row className="profile-data profile-data-report">
                              <Col md={5}>SysDok-ID:</Col>
                              <Col md={5} className="text-grey">
                                {this.state.patient_code}
                              </Col>
                            </Row>
                            <hr className="separator text-grey" />
                            <Row className="profile-data profile-data-report">
                              <Col md={5}>Geburtsdatum:</Col>
                              <Col md={5} className="text-grey">
                                {this.state.birthday}
                              </Col>
                            </Row>
                            <hr className="separator text-grey" />
                            <Row className="profile-data profile-data-report">
                              <Col md={5}>Geschlecht:</Col>
                              <Col md={5} className="text-grey">
                                {this.state.gender === "MALE"
                                  ? "Männlich"
                                  : this.state.gender === "FEMALE"
                                    ? "Weiblich"
                                    : this.state.gender === "DIVERSE"
                                      ? "Divers"
                                      : this.state.gender}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-12 col-sm-6 col-print-6">
                        <Row>
                          <Col md="3">
                            <div className="icon rounded-circle-outline ml-2">
                              <i className="mdi mdi-timetable"></i>
                            </div>
                          </Col>
                          <Col md="9">
                            <Row className="profile-data profile-data-report">
                              <Col md={5}>Messzeitpunkt:</Col>
                              <Col md={5} className="text-grey">
                                {(() => {
                                  switch (this.state.stw_type) {
                                    case "PRE":
                                      return "Aufnahme";
                                    case "INTERMEDIATE":
                                      return "Zwischenbefragung";
                                    case "POST":
                                      return "Entlassung";
                                    case "FOLLOW_UP_1":
                                      return "Nachsorge 6 Monate";
                                    case "FOLLOW_UP_2":
                                      return "Nachsorge 12 Monate";
                                    case "FOLLOW_UP_3":
                                      return "Nachsorge 18 Monate";
                                    default:
                                      return null;
                                  }
                                })()}
                              </Col>
                            </Row>
                            <hr className="separator text-grey" />
                            <Row className="profile-data profile-data-report">
                              <Col md={5}>Screening Datum:</Col>
                              <Col md={5} className="text-grey">
                                {this.state.started_time}
                              </Col>
                            </Row>
                            <hr className="separator text-grey" />
                            <Row className="profile-data profile-data-report">
                              <Col md={5}>Abrufdatum:</Col>
                              <Col md={5} className="text-grey">
                                {this.state.today}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Row>
                  <Col>
                    <Accordion
                      defaultActiveKey="0"
                      className="card-default accordion"
                      id="report_overview_box"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <MdViewList className="mr-1" /> Seitenübersicht
                        </Accordion.Header>
                        <Accordion.Body className="p-0 pt-3 ">
                          <ul
                            className="list-unstyled"
                            id="final_report_overview"
                          >
                            <li>
                              <a
                                href="#dep_clinician_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_dep_status"
                                    id="list_item_dep_status"
                                  ></div>
                                  <h4 className="title">
                                    Screening Ergebnis: Depression
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#anx_clinician_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_anx_status"
                                    id="list_item_anx_status"
                                  ></div>
                                  <h4 className="title">
                                    Screening Ergebnis: Angst
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#exis_clinician_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_exis_status"
                                    id="list_item_exis_status"
                                  ></div>
                                  <h4 className="title">
                                    Screening Ergebnis: Exis
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#evos_clinician_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_evos_status"
                                    id="list_item_evos_status"
                                  ></div>
                                  <h4 className="title">
                                    Screening Ergebnis: Evos
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#eri_clinician_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_eri_status"
                                    id="list_item_eri_status"
                                  ></div>
                                  <h4 className="title">
                                    Screening Ergebnis: ERI
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#wai_clinician_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_wai_status"
                                    id="list_item_wai_status"
                                  ></div>
                                  <h4 className="title">
                                    Screening Ergebnis: WAI
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#socio_clinician_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_socio_status"
                                    id="list_item_socio_status"
                                  ></div>
                                  <h4 className="title">
                                    Screening Ergebnis: Socio
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#eq5d_clinician_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_eq5d_status"
                                    id="list_item_eq5d_status"
                                  ></div>
                                  <h4 className="title">
                                    Screening Ergebnis: EQ5D
                                  </h4>
                                </div>
                              </a>
                            </li>

                            <li
                              id="list_item_management_container"
                              style={{ display: "none" }}
                            >
                              <a
                                href="#management_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <h4 className="title">
                                    Weitere Gesundheitsdimensionen
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li
                              id="list_item_entlassbericht_container"
                              style={{ display: "none" }}
                            >
                              <a
                                href="#entlassbericht_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <h4 className="title">Dokumentationshilfe</h4>
                                </div>
                              </a>
                            </li>
                          </ul>

                          {this.state.patientReport ? (
                            <div className="card-footer">
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <Button
                                  variant="primary"
                                  onClick={this.openPatientReport}
                                >
                                  <i className="mdi mr-1 mdi-clipboard-account" />
                                  Bericht für Patient*innen einsehen
                                </Button>
                              </div>
                            </div>
                          ) : null}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col>
                    <Accordion
                      defaultActiveKey="1"
                      className="card-default accordion"
                      id="report_printview_box"
                    >
                      <Accordion.Item eventKey="1">
                        <Accordion.Header id="heading_report_printview_box">
                          <MdLocalPrintshop className="mr-1" />
                          Druckoptionen
                        </Accordion.Header>
                        <Accordion.Body className="p-0 pt-3">
                          <Row id="final_report_printview">
                            <Col
                              className="col-12 my-2"
                              id="print_toggle_core_container"
                            >
                              <Row>
                                <Col md="9">
                                  <h6 className="text-dark">
                                    Depression & Angst
                                  </h6>
                                  {/* <p className="text-grey">
                                    Screening Ergebnisse
                                  </p> */}
                                </Col>
                              </Row>
                              <Row className="report-switch-row">
                                <Col md="9">
                                  <p className="text-grey text-print-sub">
                                    Screening Ergebnisse
                                  </p>
                                </Col>
                                <Col md="3">
                                  <Form.Label className="switch switch-icon switch-primary switch-pill form-control-label">
                                    <input
                                      type="checkbox"
                                      disabled
                                      className="switch-input form-check-input disabled"
                                      id="print_toggle_detailinfo_btn"
                                      value="on"
                                      checked
                                    />
                                    <span
                                      className="switch-label"
                                      id="switch-label-disabled"
                                    ></span>
                                    <span className="switch-handle"></span>
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="col-12 my-2"
                              id="print_toggle_detailinfo_container"
                            >
                              <Row className="report-switch-row" tabIndex={0}>
                                <Col md="9">
                                  {/* <h6 className="text-dark">
                                    Detailinformationen
                                  </h6> */}
                                  <p className="text-grey text-print-sub">
                                    Antworten im Detail
                                  </p>
                                </Col>
                                <Col md="3">
                                  <Form.Label className="switch switch-icon switch-primary switch-pill form-control-label">
                                    <input
                                      type="checkbox"
                                      className="switch-input form-check-input"
                                      id="print_detail"
                                      value="off"
                                    />
                                    <span className="switch-label"></span>
                                    <span className="switch-handle"></span>
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Col>

                            <Col
                              className="col-12 my-2"
                              id="print_toggle_management_container"
                              style={{ display: "none" }}
                            >
                              <Row>
                                <Col md="9">
                                  <h6 className="text-dark">
                                    Weitere Gesundheitsdimensionen
                                  </h6>
                                </Col>
                                <Col md="3">
                                  <Form.Label className="switch switch-icon switch-primary switch-pill form-control-label">
                                    <input
                                      type="checkbox"
                                      className="switch-input form-check-input"
                                      id="print_management"
                                      value="off"
                                    />
                                    <span className="switch-label"></span>
                                    <span className="switch-handle"></span>
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              className="col-12 my-2"
                              id="print_toggle_entlassbericht_container"
                              style={{ display: "none" }}
                            >
                              <Row>
                                <Col md="9">
                                  <h6 className="text-dark">
                                    Dokumentationshilfe
                                  </h6>
                                </Col>
                                <Col md="3">
                                  <Form.Label className="switch switch-icon switch-primary switch-pill form-control-label">
                                    <input
                                      type="checkbox"
                                      className="switch-input form-check-input"
                                      id="print_entlassbericht_toggle"
                                      value="off"
                                    />
                                    <span className="switch-label"></span>
                                    <span className="switch-handle"></span>
                                  </Form.Label>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <div className="card-footer">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <Button variant="primary" id="print_final_report">
                                <MdLocalPrintshop /> Drucken
                              </Button>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
                <div
                  id="final-html"
                  dangerouslySetInnerHTML={{ __html: this.state.report }}
                ></div>

                {/* END html start */}

                <Row className="mt-3" id="management_container">
                  <Col lg="12">
                    <Accordion
                      className="accordion print-keep-together"
                      id="management_clinician"
                    >
                      <Accordion.Item eventKey="1">
                        <Accordion.Header id="heading_management_clinician">
                          Weitere Gesundheitsdimensionen
                        </Accordion.Header>
                        <Accordion.Body id="management_content_wrapper"></Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>

                <Row
                  className="entlassbericht mt-3"
                  id="entlassbericht_container"
                >
                  <Col lg="12">
                    <Accordion
                      id="entlassbericht_clinician"
                      className="accordion"
                    >
                      <Accordion.Item eventKey="1">
                        <Accordion.Header id="heading_entlassbericht_clinician">
                          Dokumentationshilfe
                        </Accordion.Header>
                        <Accordion.Body id="entlassbericht_content_wrapper"></Accordion.Body>
                        <div className="card-footer">
                          <div className="d-grip gap-2 d-md-flex justify-content-md-end">
                            <Button
                              variant="primary"
                              id="print_entlassbericht"
                              className="not_printable"
                            >
                              <MdLocalPrintshop /> Dokumentationshilfe drucken
                            </Button>
                            <Button
                              variant="outline-secondary"
                              id="copy_entlassbericht"
                              className="not_printable"
                            >
                              In Zwischenablage kopieren
                            </Button>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
                {/* END html end*/}
              </div>
            </div>
            <Footer>
              {this.state.clinic_id}
              {this.state.clinic_name}
              {this.state.username}
              {this.state.user_type}
            </Footer>
          </div>
        </div>
      </div>
    );
  }
}
export default ClinicianReport;
