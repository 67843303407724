import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import IdleTimer from "react-idle-timer";
import AuthService from "../services/AuthService";
import { refreshToken } from "../services/sessionManagement";
import globalVar from "./globalVar";

let countdownInterval;
/**Helper component with idle timer. */
class TimeoutSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      countdown: 20,
    };
    this.idleTimer = null;
    this.secondIdleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  /**Method to set modalOpen on true and start the logout countdown if the user is idle. */
  openModal = () => {
    this.secondIdleTimer.start();
    this.secondIdleTimer.timeout = 20 * 1000;
    this.setState({
      modalOpen: true,
    });

    let countdown = 20;
    countdownInterval = setInterval(() => {
      if (countdown > 0) {
        countdown--;
        this.setState({ countdown: countdown });
      } else if (this.state.modalOpen) {
        console.log("Modal Open: ", this.state.modalOpen);

        AuthService.logout();
      }
    }, 1000);
  };

  /**Set modalOpen on false, pause the countdown and clear the countdownInterval. */
  closeModal = () => {
    console.log("Modal schließen");
    this.setState({ modalOpen: false });
    clearInterval(countdownInterval);
    this.secondIdleTimer.timeout = 20 * 1000;
    this.secondIdleTimer.pause();
    refreshToken();
  };

  /**If the user triggers a mousedown or keydown event the refreshToken() method is called so that the token is refreshed.
   * Due to an error with the event key "F5" it would be ignored and the method refreshToken() would not be called in this case.
   */
  handleOnAction(event) {
    if (event.type === "mousedown" || event.type === "keydown") {
      if (event.key !== "F5") {
        refreshToken();
      }
      console.log("Event Action: ", event);
    }
    console.log("Timeout: ", globalVar.timeoutCountdown);
  }

  handleOnActive(event) {
    refreshToken();
    console.log("user is active", event);
  }

  /**Open modal if the user is idle and start the second idle timer. */
  handleOnIdle(event) {
    console.log("user is idle", event);
    this.openModal();
  }

  /**If the user is still idle after the countdown, the logout method is called.  */
  handleOnIdleSecond() {
    AuthService.logout();
  }

  /**React render method. */
  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          startOnMount
          timeout={parseInt(globalVar.timeoutCountdown) * 60 * 1000 - 20000}
          events={["mousedown", "keydown"]}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
        />
        <IdleTimer
          ref={(ref) => {
            this.secondIdleTimer = ref;
          }}
          startManually
          onIdle={this.handleOnIdleSecond}
          onAction={this.handleOnActionSecond}
        />
        <Modal
          role="dialog"
          show={this.state.modalOpen}
          onHide={this.closeModal}
          backdrop="static"
        >
          <ModalHeader closeButton>
            <ModalTitle>Achtung!</ModalTitle>
          </ModalHeader>
          <ModalBody>
            Sie werden ausgeloggt, wenn Sie nichts tun. Es verbleiben{" "}
            <b>{this.state.countdown}</b> Sekunden.
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.closeModal}>Eingeloggt bleiben</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default TimeoutSession;
