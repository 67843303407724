import { Col, Row, Tooltip } from "react-bootstrap";
import React from "react";
import { Card, Container, Form, OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Clinic, getClinics } from "../../services/getFunctions";
import Footer from "../Footer";
import { RiInformationFill } from "react-icons/ri";
import { BiHide, BiShow } from "react-icons/bi";
import {
  reportGetClinicListError,
  reportNoClinicSelected,
  showAlertModal,
} from "../../helper/alertBoxes";
import { RouteComponentProps } from "react-router-dom";
import Logo from "../../img/Logo.svg";
import { AuthContextType, withAuthContext } from "../../AuthProvider";
import { ILoginData } from "../../types/authTypes";
/**
 * Component for patient login.
 */
interface LoginPatientState {
  value: string;
  clinicname: string;
  clinic_contact: string;
  username: string;
  password: string;
  result: string;
  isInvalid: boolean;
  unauthorized: boolean;
  clinics: Clinic[];
  hiddenPw: boolean;
}
interface LoginPatientProps {
  title: string;
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any;
  history: RouteComponentProps["history"];
  authContext: AuthContextType;
}
class LoginPatient extends React.Component<
  LoginPatientProps,
  LoginPatientState
> {
  constructor(props: LoginPatientProps) {
    super(props);
    this.state = {
      value: "",
      clinicname: "",
      clinic_contact: "",
      username: "",
      password: "",
      result: "",
      isInvalid: true,
      unauthorized: true,
      clinics: [],
      hiddenPw: true,
    };
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleClinicId = this.handleClinicId.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.showPassword = this.showPassword.bind(this);
  }

  /**Method for loading the clinic list from backend API. */
  componentDidMount() {
    sessionStorage.removeItem("auth");
    document.title = this.props.title;

    try {
      getClinics()
        .then((clinicList) => {
          console.log("Klinik-Liste: ", clinicList);
          this.setState({
            clinics: clinicList.clinics,
          });
        })
        .catch((error) => {
          reportGetClinicListError(error);
        });
    } catch (error) {
      /* empty */
    }
  }

  /**Method to update the clinic id state, when it is changed.
   * @param event {ChangeEvent} onChange delivers the event with the selected option
   */
  handleClinicId(event) {
    const clinicId = event.target.value;
    const clinic = this.state.clinics.find((c) => {
      return c.id === clinicId;
    });

    if (!clinic) {
      this.setState({ value: clinicId, clinicname: "", clinic_contact: "" });
      return;
    }

    const clinicName = clinic.name;
    const clinicContact = clinic.contact;
    this.setState({
      value: clinicId,
      clinicname: clinicName,
      clinic_contact: clinicContact,
    });
    console.log(
      "Klinik-ID: ",
      this.state.value,
      "Klinikname: ",
      clinicName,
      "Kontakt: ",
      clinicContact,
    );
  }

  /**Method to update the username state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleUsername(text) {
    console.log("Username", this.state.username, " ausgefüllt");
    this.setState({ username: text.target.value });
  }

  /**Method to update the password state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handlePassword(text) {
    console.log("Passwort", this.state.password, " ausgefüllt");
    this.setState({ password: text.target.value });
  }

  /**
   * Method for handle the login.
   * The input data are stringified to JSON.
   * Then send JSON to backend API and handle the response.
   */
  handleLogin = async (event) => {
    event.preventDefault();
    const { login } = this.props.authContext;
    console.log("Button aktiviert");
    if (!this.state.username) {
      console.log("Kein Username");

      return;
    }
    if (!this.state.password) {
      console.log("Kein Passwort");
      return;
    }
    if (!this.state.value || this.state.value === "") {
      console.log("Keine Klinik");
      reportNoClinicSelected();
      return;
    }
    /* changing the date string iso date string*/
    const [day, month, year] = this.state.password.split(".");
    const isoDate: string = [year, month, day].join("-");
    const loginData: ILoginData = {
      clinic_id: this.state.value,
      username: this.state.username,
      password: isoDate,
      userType: "patient",
      clinicContact: this.state.clinic_contact,
    };
    await login(loginData, (authResponse: string) => {
      if (authResponse === "success") {
        this.setState({ unauthorized: false });
        if (
          this.props.location.state?.redirectTo &&
          this.props.location.state.redirectTo.length > 0
        ) {
          this.props.history.push({
            pathname: this.props.location.state.redirectTo,
          });
        } else {
          this.props.history.push({
            pathname: "/patients",
          });
        }
      } else if (authResponse === "credentials_wrong") {
        showAlertModal(
          "Ihre Anmeldedaten sind fehlerhaft oder Ihr Account wurde noch nicht freigeschaltet.",
        );
      } else if (authResponse === "no_permissions") {
        showAlertModal("Sie haben für diese Seite keine Berechtigung.");
      } else {
        showAlertModal(
          "Irgendetwas ist schief gelaufen. Bitte versuchen Sie es erneut.",
        );
      }
    });
  };

  /**Change hiddenPw state on true or false. */
  showPassword() {
    this.setState((prevState) => ({ hiddenPw: !prevState.hiddenPw }));
  }

  /**
   * React render method.
   */
  render() {
    return (
      <Container className="container d-flex align-items-center justify-content-center">
        <Card className="card login col-lg-6 col-md-10">
          {/* WarningBanner component */}
          <div className="warning-banner">
            Bitte stellen Sie sicher, dass sie keine Übersetzungsplugins für
            diese Seite nutzen
          </div>
          <Card.Header className="card-header d-flex justify-content-center">
            <img src={Logo} alt="Logo" width="230px" height="auto" />
          </Card.Header>
          <Card.Body className="p-5">
            <Card.Title className="text-dark mb-5">
              Anmeldung Patient*innen
            </Card.Title>
            <Form noValidate validated onSubmit={this.handleLogin}>
              <Form.Group controlId="formGroupClinic">
                <Form.Label className="">
                  Ambulanz{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip role="tooltip">
                        Ihre Ambulanz ist in der Liste nicht enthalten? Wenden
                        Sie sich bitte an sysdok@uniklinik-freiburg.de
                      </Tooltip>
                    }
                  >
                    <span className="mdi">
                      <RiInformationFill />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  aria-describedby="tooltipClinic"
                  className="form-control"
                  required
                  defaultValue=""
                  onChange={(event) => {
                    this.handleClinicId(event);
                  }}
                >
                  {this.state.clinics &&
                    this.state.clinics.length > 0 &&
                    [
                      {
                        name: "Bitte auswählen",
                        id: "",
                      },
                      ...this.state.clinics,
                    ].map((clinic, index) => {
                      return (
                        <option key={index} value={clinic.id}>
                          {clinic.name}
                        </option>
                      );
                    })}
                </Form.Select>{" "}
                <div
                  role="tooltip"
                  className="tooltip-keyboard"
                  id="tooltipClinic"
                >
                  Hilfe: Ist Ihre Ambulanz in der Liste nicht enthalten? Wenden
                  Sie sich bitte an sysdok@uniklinik-freiburg.de
                </div>
              </Form.Group>
              <Form.Group controlId="formGroupCode">
                <Form.Label className="login-label">
                  SysDok-ID{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip className="tooltip" role="tooltip">
                        Bitte verwenden Sie die Ihnen bei der Anmeldung
                        zugewiesene SysDok-ID.
                      </Tooltip>
                    }
                  >
                    <span className="mdi">
                      <RiInformationFill />
                    </span>
                  </OverlayTrigger>
                </Form.Label>

                <Form.Control
                  aria-describedby="tooltipSysDokID"
                  className="form-control"
                  required
                  type="username"
                  placeholder="SysDok-ID"
                  onChange={(text) => {
                    this.handleUsername(text);
                  }}
                />
                <Form.Control.Feedback
                  type="invalid"
                  role="tooltip"
                  id="tooltipSysDokID"
                >
                  Bitte geben Sie Ihre SysDok-ID ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupPassword">
                <Row className="login-form-control">
                  <Col sm="3" xs="5">
                    <Form.Label className="">Geburtsdatum</Form.Label>
                  </Col>
                  <Col>
                    <div className="">
                      {this.state.hiddenPw ? (
                        <Button
                          className="passwordShow"
                          variant=""
                          id="show"
                          onClick={this.showPassword}
                        >
                          <BiShow
                            className="hideShowPw"
                            id="show"
                            aria-label="Passwort einblenden"
                          />
                        </Button>
                      ) : (
                        <Button
                          className="passwordShow "
                          variant=""
                          id="hide"
                          onClick={this.showPassword}
                        >
                          <BiHide
                            className="hideShowPw"
                            id="hide"
                            aria-label="Passwort ausblenden"
                          />
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>

                <Form.Control
                  aria-describedby="tooltipBirthday"
                  className="form-control"
                  required
                  type={this.state.hiddenPw ? "password" : "text"}
                  placeholder="tt.mm.jjjj"
                  onChange={(text) => {
                    this.handlePassword(text);
                  }}
                ></Form.Control>
                <Form.Text role="tooltip" id="tooltipBirthday">
                  Beispiel: Geburtstag am 7. September 1984. Eingabe: 07.09.1984
                </Form.Text>
              </Form.Group>
              <OverlayTrigger
                overlay={
                  <Tooltip className="tooltip" role="tooltip">
                    Zum Einloggen geben Sie bitte Ihre SysDok-ID an und
                    zusätzlich Ihr Geburtsdatum. Sollten Sie Ihre SysDok-ID
                    vergessen haben, melden Sie sich bitte in Ihrem
                    Ambulanzsekretariat.
                  </Tooltip>
                }
              >
                <Button aria-describedby="tooltipLink" variant="link">
                  SysDok-ID vergessen?
                </Button>
              </OverlayTrigger>
              <div hidden id="tooltipLink">
                Zum Einloggen geben Sie bitte Ihre SysDok-ID an und zusätzlich
                Ihr Geburtsdatum. Sollten Sie Ihre SysDok-ID vergessen haben,
                melden Sie sich bitte in Ihrem Ambulanzsekretariat.
              </div>
              <div className="d-grid gap-2">
                <Button className="" variant="primary" type="submit">
                  Anmelden
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <Footer>{"patient"}</Footer>
      </Container>
    );
  }
}

export default withAuthContext(LoginPatient);
