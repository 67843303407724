import React, { FC, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { AuthContext } from "../AuthProvider";
import { AppContext } from "../AppProvider";
import { useHistory } from "react-router-dom";
import NavigationBar from "./navigation/NavigationBar";
import SidebarPatient from "./sidebars/SidebarPatient";
import TimeoutSession from "../helper/TimeoutSession";
import SidebarAdmin from "./sidebars/SidebarAdmin";
import SidebarClinician from "./sidebars/SidebarClinician";
import Footer from "./Footer";

interface PrivacyPolicyProps {
  title: string;
}
const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ title }) => {
  const { handleAccordion } = useContext(AppContext);
  const { state: authState } = useContext(AuthContext);

  const history = useHistory();

  const { isAuthenticated, userInfo } = authState;
  const isLoggedIn = !!isAuthenticated;

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return (
    <div>
      <div className={isLoggedIn ? "header-fixed" : ""} id="body">
        {isLoggedIn ? (
          <>
            <TimeoutSession />
            <div className="skip-link-div">
              <a className="skip-link" href="#main-content">
                Direkt zum Inhalt springen
              </a>
            </div>
            <NavigationBar />
          </>
        ) : null}
        <div className={isLoggedIn ? "page-wrapper-new" : ""}>
          <div className={isLoggedIn ? "wrapper" : ""}>
            {isLoggedIn ? (
              userInfo?.userType === "patient" ? (
                <div>
                  <div className="skip-sidebar-div">
                    <a className="skip-sidebar" href="#main-content">
                      Navigation überspringen
                    </a>
                  </div>
                  <SidebarPatient />
                </div>
              ) : userInfo?.userType === "clinician" ? (
                <div>
                  <div className="skip-sidebar-div">
                    <a className="skip-sidebar" href="#main-content">
                      Navigation überspringen
                    </a>
                  </div>
                  <SidebarClinician
                    handleAccordion={() =>
                      handleAccordion({ userType: "clinician" })
                    }
                  />
                </div>
              ) : (
                <div>
                  <div className="skip-sidebar-div">
                    <a className="skip-sidebar" href="#main-content">
                      Navigation überspringen
                    </a>
                  </div>
                  <SidebarAdmin
                    handleAccordion={() =>
                      handleAccordion({ userType: "admin" })
                    }
                  />
                </div>
              )
            ) : null}
            <div className="content-wrapper">
              <div
                id="main-content"
                className={
                  isLoggedIn
                    ? "content"
                    : "content-without-sidebar content-without-navbar"
                }
              >
                {" "}
                {!isLoggedIn ? (
                  <Button
                    variant="primary"
                    id="backToLogin"
                    onClick={() => history.goBack()}
                  >
                    Zurück
                  </Button>
                ) : null}
                <h3>Informationen zum Datenschutz</h3>
                <p>
                  Sehr geehrte Nutzer:innen der Plattform SysDok,
                  <br /> der Schutz Ihrer Daten ist uns wichtig. Deshalb
                  informieren wir Sie an dieser Stelle, zu welchem Zweck wir
                  Ihre Daten erheben, speichern oder weiterleiten. Sollten Sie
                  die Plattform im Rahmen einer Studie nutzen, können weitere
                  datenschutzrelevante Informationen den dazugehörigen
                  Teilnahmeinformationen bzw. Einwilligungserklärungen entnommen
                  werden. Diese beinhalten auch, welche Rechte Ihnen bezüglich
                  Ihrer personenbezogenen Daten zustehen.
                </p>
                <strong>1. Verantwortliche:r für die Datenverarbeitung</strong>
                <p>
                  Verantwortlicher für die Datenverarbeitung ist gem. Art. 4
                  Abs. 7 Datenschutzgrundverordnung (DSGVO) das <br />
                  Universitätsklinikum Freiburg
                  <br />
                  Breisacher Straße 153
                  <br />
                  D 79110 Freiburg
                  <br />
                  Telefon +49 761 270-0
                </p>
                <p>
                  Das Universitätsklinikum Freiburg ist eine rechtsfähige
                  Anstalt des öffentlichen Rechts der Albert-Ludwigs-Universität
                  Freiburg, vertreten durch:
                  <br />
                  Vorstand:
                  <br />
                  Leitender Ärtzlicher Direktor: Prof. Dr. Dr. h.c. Frederik
                  Wenz (Vorsitz)
                  <br />
                  Kaufmännische Direktorin: Anja Simon
                  <br />
                  Stellvertretender Leitender Ärztlicher Direktor: Prof. Dr. Dr.
                  Rainer Schmelzeisen
                  <br />
                  Dekan der Medizinischen Fakultät: Prof. Dr. Lutz Hein
                  <br />
                  Pflegedirektor: Helmut Schifferer
                  <br />
                  Aufsichtsrat:
                  <br />
                  Vorsitzender: Dr. Carsten Dose
                  <br />
                  Stellvertretender Vorsitzender: Rektorin Prof. Dr. Kerstin
                  Kriegstein
                </p>
                <p>
                  Bei Fragen rund um den Datenschutz wenden Sie sich bitte an
                  datenschutz(at)uniklinik-freiburg.de oder senden einen Brief
                  mit dem Zusatz &quot;Datenschutzbeauftragte&quot; an die o. g.
                  Adresse.
                </p>
                <p>
                  Weitere spezifische Informationen bezüglich der jeweiligen
                  Studie, an der Sie teilnehmen, finden Sie in den zugehörigen
                  Teilnahmeinformationen bzw. Einwilligungserklärungen.
                </p>
                <strong>
                  2. Datenkategorien, Zweck und Rechtsgrundlage der
                  Datenverarbeitung
                </strong>
                <p>
                  Im Rahmen der Verwendung von SysDok werden die folgenden
                  personenbezogenen Daten von Ihnen pseudonymisiert verarbeitet
                  (eine Zuordnung zu Ihnen als Person kann nur durch Ihre
                  Psychotherapieambulanz erfolgen):
                </p>
                <p>
                  • E-Mail-Adresse (freiwillige Angabe) <br />
                  • Eingaben in Befragungen (freiwillige Angabe) <br />•
                  Nutzungsdaten der Plattform in Hinblick auf das allgemeine
                  Nutzungsverhalten (z.B. Zeitpunkt des Logins)
                </p>
                <p>
                  Wir verarbeiten Daten unserer Nutzer:innen grundsätzlich nur,
                  soweit dies zur Bereitstellung einer funktionsfähigen
                  Plattform sowie unserer Inhalte und Leistungen zur Erfüllung
                  von wissenschaftlichen Förderungspflichten (Art. 9 Abs. 2 lit.
                  j DSGVO) erforderlich ist . Sollten Sie diese Plattform im
                  Rahmen einer Studie nutzen , können Sie weitere Details den
                  jeweils zugehörigen Teilnahmeinformationen bzw. der
                  Einwilligungserklärung entnehmen. Rechtsgrundlage für die
                  Verarbeitung ist in diesem Fall Ihre Einwilligung zur
                  Teilnahme an der jeweiligen Studie gem. Art. 6 Abs. 1 lit. a
                  EU-DSGVO.
                </p>
                <strong>3. Speicherung Ihrer Daten</strong>
                <p>
                  Ihre Daten werden so lange gespeichert, wie sie für die
                  ordnungsgemäße Funktionalität der Plattform benötigt werden.
                </p>
                <p>
                  Sofern einer Löschung keine sonstigen berechtigten Interessen
                  des für die Verarbeitung Verantwortlichen entgegenstehen,
                  erfolgt die Löschung bei Teilnahme an einer Studie oder an
                  einem Forschungsprojekt entsprechend der dortigen
                  Speicherdauer. Sollten Sie die Plattform im Rahmen einer
                  Studie nutzen, finden Sie weitere spezifische Informationen in
                  den zugehörigen Teilnahmeinformationen bzw. der
                  Einwilligungserklärung. Bitte Informieren Sie sich
                  diesbezüglich über die Löschfrist im Rahmen der entsprechenden
                  Studie oder des Forschungsprojekts.
                </p>
                <strong>4. Empfänger Ihrer Daten</strong>
                <p>
                  Im Rahmen der Nutzung der Online-Plattform werden Ihre Daten
                  von den jeweiligen Psychotherapieambulanzen, sowie der Klinik
                  für Psychosomatische Medizin und Psychotherapie des
                  Universitätsklinikums Freiburg und der Abteilung für Klinische
                  Psychologie und Psychotherapie der Universität Ulm verwendet.
                </p>
                <p>
                  Bei Teilnahme an einer Studie finden Sie weitere Informationen
                  in den entsprechenden Teilnahmeinformationen bzw.
                  Einwilligungserklärung der jeweiligen Studie.
                </p>
                <strong>5. Datenverarbeitung durch einen Dritten</strong>
                <p>
                  Für das Hosting der Online-Plattform SysDok nutzen das
                  Universitätsklinikum Freiburg und die Universität Ulm einen
                  Server der Firma
                </p>
                <p>
                  ADMIN INTELLIGENCE GmbH
                  <br />
                  Sedanstr. 124 <br />
                  89077 Ulm
                </p>
                <p>
                  Ihre eingegebenen Daten werden für uns bei der Firma ADMIN
                  INTELLIGENCE GmbH verarbeitet. Alle notwendigen technischen
                  und organisatorischen Sicherheitsmaßnahmen, um Ihre
                  personenbezogenen Daten vor Verlust und Missbrauch zu
                  schützen, werden von uns und in unserem Auftrag von der Firma
                  ADMIN INTELLIGENCE GmbH getroffen. Mit ADMIN INTELLIGENCE GmbH
                  besteht eine rechtlich geprüfte
                  Auftragsdatenverarbeitungsvereinbarung (AVV).
                </p>
                <strong>6. Ihre Rechte als Betroffener </strong>
                <p>
                  Zum Schutz Ihrer personenbezogenen Daten stehen Ihnen folgende
                  Rechte zu:
                </p>
                <p>
                  • Ihre Einwilligung widerrufen (Art. 7 Abs. 3 DSGVO)
                  <br />
                  • Auskunft über die Sie betreffenden personenbezogenen Daten
                  zu erhalten (Art. 15 DSGVO),
                  <br />
                  • unrichtige Daten berichtigen zu lassen (Art. 16 DSGVO),
                  <br />
                  • unter bestimmten Voraussetzungen die Löschung oder
                  Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                  zu verlangen (Art. 17, 18 DSGVO),
                  <br />
                  • Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen
                  (Art. 21 DSGVO),
                  <br />
                  • Ihre Daten zu erhalten und an andere von Ihnen bestimmte
                  Stellen übertragen (Art. 20 DSGVO).
                  <br />• eine Beschwerde einreichen (Art. 77 DSGVO)
                </p>
                <p>
                  Sie haben das Recht, sich an die zuständige Aufsichtsbehörde
                  für den Datenschutz zu wenden, wenn Sie der Ansicht sind, dass
                  die Verarbeitung Ihrer personenbezogenen Daten nicht
                  rechtmäßig erfolgt. Die für uns zuständige Aufsichtsbehörde
                  ist der Landesbeauftragte für Datenschutz und
                  Informationsfreiheit Baden-Württemberg.
                </p>
                <strong>
                  7. Widerruf Ihrer Einwilligung zur Datenverarbeitung
                </strong>
                <p>
                  Die Einwilligung zur Datenverarbeitung ist freiwillig. Sie
                  haben das Recht, Ihre Einwilligung jederzeit und ohne Angaben
                  von Gründen zu widerrufen. Durch den Widerruf der Einwilligung
                  wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum
                  Widerruf erfolgten Verarbeitung nicht berührt. Sollten Sie die
                  Plattform im Rahmen einer Studie nutzen, richten Sie Ihren
                  Widerruf als formlose Mitteilung an die in
                  Teilnahmeinformationen bzw. der Einwilligungserklärung Ihrer
                  jeweiligen Studie genannte Adresse.
                </p>
                <strong>SSL- bzw. TLS-Verschlüsselung</strong>
                <p>
                  Aus Sicherheitsgründen und zum Schutz der Übertragung
                  vertraulicher Inhalte, die Sie an uns als Seitenbetreiber
                  senden, nutzt unsere Website eine SSL-bzw.
                  TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
                  Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen
                  eine verschlüsselte Verbindung an der „https://“ Adresszeile
                  Ihres Browsers und am Schloss-Symbol in der Browserzeile.
                </p>
                <strong>Server-Log-Dateien</strong>
                <p>
                  In Server-Log-Dateien erhebt und speichert der Provider der
                  Website automatisch Informationen, die Ihr Browser automatisch
                  an uns übermittelt. Dies sind:
                </p>
                <p>
                  • Besuchte Seite auf unserer Domain <br />
                  • Browsertyp und Browserversion
                  <br />
                  • Verwendetes Betriebssystem
                  <br />
                  • Referrer URL
                  <br />
                  • Hostname des zugreifenden Rechners
                  <br />
                  • Datum und Uhrzeit der Serveranfrage
                  <br />• IP-Adresse
                </p>
                <p>
                  Es findet keine Zusammenführung dieser Daten mit anderen
                  Datenquellen statt. Grundlage der Datenverarbeitung bildet
                  Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
                  Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
                  gestattet.
                </p>
                <strong>Cookies</strong>
                <p>
                  Unsere Website verwendet Session-Cookies. Das sind kleine
                  Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert.
                  Cookies helfen uns dabei, unser Angebot nutzerfreundlicher,
                  effektiver und sicherer zu machen. Session-Cookies werden nach
                  Ende Ihrer Browser-Sitzung von selbst gelöscht. Das Setzen von
                  Cookies, die zur Ausübung elektronischer
                  Kommunikationsvorgänge oder der Bereitstellung bestimmter, von
                  Ihnen erwünschter Funktionen notwendig sind, erfolgt auf
                  Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser
                  Website haben wir ein berechtigtes Interesse an der
                  Speicherung von Cookies zur technisch fehlerfreien und
                  reibungslosen Bereitstellung unserer Dienste.
                </p>
                <br />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
