import axios from "axios";
import { showAlertModal } from "../helper/alertBoxes";
import { retryIfTokenInvalid } from "./sessionManagement";
import globalVar from "../helper/globalVar";
import { UserInfo } from "../AuthProvider";
import { ILoginData, SignInResultType } from "../types/authTypes";

declare let window;
axios.defaults.baseURL = window.APP_CONFIG.REACT_APP_BACKEND_URL;

const loginPageUrls = {
  patient: "/loginpatient",
  clinician: "/loginclinician",
  admin: "/loginadmin",
};

/**
 * Service class for communication between backend and frontend.
 */
class AuthService {
  /**Login method sends POST-Request to backend api.
   * @param loginData Login data
   * @param userType {string} patient || clinician || admin
   */
  async login(loginData: ILoginData): Promise<SignInResultType> {
    const userType = loginData.userType ?? "";
    if (!["patient", "clinician", "admin"].includes(userType)) {
      throw new Error("login() called with invalid userType");
    }
    const loginApiUrls = {
      admin: "/api/clinic-admins/login",
      clinician: "/api/clinicians/login",
      patient: "/api/patients/login",
    };
    const url = loginApiUrls[userType];
    const loginDataJson = JSON.stringify(loginData);
    sessionStorage.setItem("usr", userType);
    console.log("Nutzer: ", userType);
    console.log("JSON", loginData);

    let response;
    let signInResult: SignInResultType = "error";
    try {
      response = await axios.post(url, loginDataJson, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.status === 200) {
        signInResult = "success";
        globalVar.timeoutCountdown =
          (Date.parse(response.data.session_expires) - Date.now()) / 60 / 1000;
      }
      // TODO: remove eslint disables, see issue rehacat_frontend#335
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.response?.status === 401) {
        signInResult = "credentials_wrong";
      } else if (err?.response?.status === 403) {
        signInResult = "no_permissions";
      } else {
        signInResult = "error";
      }
    }

    console.log("AuthResponse: ", response);
    if (signInResult === "success") {
      sessionStorage.setItem("auth", "true");
    }

    return signInResult;
  }

  getUserInfo() {
    const json = sessionStorage.getItem("userInfo");
    if (json) {
      return JSON.parse(json);
    }
  }

  setUserInfo(data: UserInfo) {
    sessionStorage.setItem("userInfo", JSON.stringify(data));
  }

  /**
   * Returns true if the user is logged in, else false
   * @returns
   */
  isLoggedIn(): boolean {
    return sessionStorage.getItem("auth") === "true";
  }

  /**Method sends POST-request to backend api.
   * @param json {string} Stringified data
   * @param url {string} Backend api url
   */
  /**adding the retryIfTokenInvalid method from sessionManagement file to
   * refresh token before the post request*/
  async regist(json, url) {
    return await retryIfTokenInvalid(async () => {
      return await axios
        .post(url, json, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("AuthResponse: ", response);
          return response;
        });
    });
  }

  /**Logout Method sends DELETE-request to backend api. After the DELETE-Request the user would be redirect to the right login.
   */
  async logout() {
    const apiUrl = "/api/refresh-tokens";
    try {
      await axios.delete(apiUrl, {
        withCredentials: true,
      });
      const userRole = sessionStorage.getItem("usr");
      const loginPageUrl =
        loginPageUrls[userRole ?? "randomStringToSatisfyTypescript"];

      if (loginPageUrl) {
        window.location.replace(loginPageUrl);
      } else {
        window.location.replace("/");
        showAlertModal("Der Nutzer konnte nicht identifiziert werden.");
      }
      sessionStorage.removeItem("auth");
      sessionStorage.removeItem("usr");
    } catch (err) {
      console.log(
        "Error when deleting the refresh token: ",
        err,
        "This may be totally fine if the token already got deleted",
      );
      return;
    }
  }
}

export default new AuthService();
