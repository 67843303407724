import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { SurveyStatusAndStws, zipSurveyStatusAndStw } from "../../helper/utils";
import { Patient } from "../../services/getFunctions";
import { useHistory } from "react-router-dom";

interface ResultStateModalProps {
  currentPatient: Patient;
  modalResultOpen: boolean;
  closeResultModal: () => void;
  clinic_id: string;
  clinic_name: string;
  username: string;
}

const ResultStateModal = ({
  currentPatient,
  modalResultOpen,
  closeResultModal,
  clinic_id,
  clinic_name,
  username,
}: ResultStateModalProps) => {
  let surveyStatuses;
  let stws;
  const history = useHistory();
  const [surveyStatusAndStws, setSurveyStatusAndStws] = useState<
    SurveyStatusAndStws[]
  >([]);

  const openClinicianReport = (event, id, stwType, surveyStart) => {
    event.preventDefault();
    console.log("event id is: ", id);
    if (id !== "") {
      history.push({
        pathname: "/clinician/clinician-report",
        state: {
          clinic_id: clinic_id,
          clinic_name: clinic_name,
          username: username,
          survey_session_id: id,
          patient_code: currentPatient.code,
          birthday: currentPatient.date_of_birth,
          gender: currentPatient.gender,
          stw_type: stwType,
          started_time: surveyStart,
        },
      });
    }
  };

  const getSeverityStyle = (status): React.CSSProperties => {
    if (!status.survey_session_id || !status.survey_finished) {
      return {
        cursor: "default",
        textDecoration: "none",
        color: "black",
      };
    }

    let color;
    switch (status.condition_severity) {
      case "LOW":
        color = "green";
        break;
      case "MEDIUM":
        color = "orange";
        break;
      case "HIGH":
        color = "red";
        break;
      case "UNKNOWN":
        color = "grey";
        break;
      case "ERROR":
        return {
          cursor: "pointer",
          textDecoration: "underline",
          color: "black",
          fontWeight: "bold",
        };
      default:
        color = "black";
    }
    return {
      cursor: "pointer",
      textDecoration: "underline",
      color,
    };
  };

  useEffect(() => {
    if (modalResultOpen) {
      surveyStatuses = currentPatient.survey_statuses;
      stws = currentPatient.survey_time_windows;

      //stws = sortStwsByStartTime(stws);

      const zippedSurveyStatusAndStws = zipSurveyStatusAndStw(
        stws.stws,
        surveyStatuses,
      );
      setSurveyStatusAndStws(zippedSurveyStatusAndStws);
      console.log("SurveyStatusAndSTWS: ", zippedSurveyStatusAndStws);
    }
  }, [modalResultOpen]);

  if (!modalResultOpen) return null;

  return (
    <div>
      {" "}
      <Modal backdrop="static" show={modalResultOpen} onHide={closeResultModal}>
        <ModalHeader closeButton>
          <ModalTitle>
            Ergebnis von <em>{currentPatient.code}</em>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          {surveyStatusAndStws.map((status) => {
            const key = status.stw_start;
            return (
              <div key={key}>
                <div id={status.survey_session_id}>
                  {status.stw_type_deutsch}:{" "}
                  {/* TODO: remove eslint disables, see issue rehacat_frontend#335 */}
                  {/* eslint-disable-next-line */}
                  <span
                    style={getSeverityStyle(status)}
                    onClick={(event) => {
                      if (status.survey_session_id && status.survey_finished) {
                        openClinicianReport(
                          event,
                          status.survey_session_id,
                          status.stw_type,
                          status.survey_started_time,
                        );
                      } else {
                        event.preventDefault();
                        return false;
                      }
                    }}
                  >
                    {status.result_status}
                    {!status.report_viewed &&
                      status.survey_session_id &&
                      status.survey_finished && <span className="unread-tag" />}
                  </span>
                </div>
              </div>
            );
          })}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default ResultStateModal;
