import React from "react";
import { Row, Card, Button } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";

const InfoTabClinicAdmin = () => {
  return (
    <Row>
      <Card className="card-default card-custom">
        <Card.Body>
          <Card.Title>Handbuch zur Implementierung</Card.Title>
          <Card.Text>
            Hier finden Sie das Handbuch zur Implementierung.
          </Card.Text>

          <a
            href="https://sysdok.de/%5Efiler/canonical/1698650407/149/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">
              <FiDownload /> Handbuch herunterladen
            </Button>
          </a>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default InfoTabClinicAdmin;
