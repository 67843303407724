import React, { useEffect, useState } from "react";
import { Patient } from "../../services/getFunctions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import { BsPeople } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { tranlateGender } from "../../helper/utils";

interface PatientTableProps {
  patients: Patient[];
  userType: string;
  openEditPatientModal: (event) => void;
  openAssignClinicianModal: (event) => void;
  openSurveyStateModal: (event) => void;
  openResultModal: (event) => void;
  closeLoadingModal: () => void;
}

interface PatientTableEntry {
  edit: JSX.Element;
  code: string;
  clinician_username: string;
  date_of_birth: string;
  gender: string; //maybe reduce to the possible values
  admission: string;
  discharge: string;
  labelsForTable: JSX.Element;
  state: JSX.Element;
  result: JSX.Element;
  viewed: string;
  created: string;
}

const PatientTable = ({
  patients,
  userType,
  openEditPatientModal,
  openAssignClinicianModal,
  openSurveyStateModal,
  openResultModal,
}: PatientTableProps) => {
  const [patientTable, setPatientTable] = useState<PatientTableEntry[]>([]);

  const createPatientTable = (patients) => {
    let patientTable: PatientTableEntry[] = [];
    if (patients.length !== 0) {
      patientTable = patients.map((patient) => {
        const patientEntry = {
          edit: (
            <DropdownButton
              key={patient.code}
              variant="outline-secondary"
              id="dropdown-basic-button"
              title={
                <i className="mdi">
                  <MdEdit />
                </i>
              }
            >
              <Dropdown.Item id={patient.code} onClick={openEditPatientModal}>
                <FiEdit /> Patient*in bearbeiten
              </Dropdown.Item>

              <Dropdown.Item
                id={patient.code}
                onClick={openAssignClinicianModal}
              >
                <BsPeople /> Anderen Mitarbeitenden zuweisen
              </Dropdown.Item>
            </DropdownButton>
          ),
          code: patient.code,
          clinician_username: patient.clinician_username,
          date_of_birth: patient.date_of_birth,
          gender: tranlateGender(patient.gender),
          admission: patient.admission,
          discharge: patient.discharge,
          labelsForTable: patient.labels
            .filter((label) => label.key === "CLUSTER")
            .map((l) => l.value)
            .join(","),
          state: (
            <Button
              className="transparent-button"
              id={patient.id}
              onClick={openSurveyStateModal}
            >
              Anzeigen
            </Button>
          ),
          result: (
            <Button
              className="transparent-button"
              id={patient.id}
              onClick={openResultModal}
            >
              Anzeigen
            </Button>
          ),
          viewed: checkViewed(patient),
          created: patient.created,
        };

        return patientEntry;
      });
    }

    return patientTable;
  };

  const deleteDuplicates = (patientTable) => {
    const latestPatientEntries = new Map();

    patientTable.forEach((patientTableEntry) => {
      const existingPatient = latestPatientEntries.get(patientTable.code);
      if (
        !existingPatient ||
        existingPatient.created < patientTableEntry.created
      ) {
        latestPatientEntries.set(patientTableEntry.code, patientTableEntry);
      }
    });

    const uniquePatients = Array.from(latestPatientEntries.values());

    return uniquePatients;
  };

  const checkViewed = (patient) => {
    if (patient.survey_statuses.length === 0) {
      return "Nicht verfügbar";
    }

    const allViewed = patient.survey_statuses.every(
      (survey) => survey.report_viewed,
    );

    if (allViewed) {
      return "Alle Reports gelesen";
    } else {
      return "Ungelesener Report";
    }
  };

  useEffect(() => {
    let newPatientTable: PatientTableEntry[] = createPatientTable(patients);
    newPatientTable = deleteDuplicates(newPatientTable);

    setPatientTable(newPatientTable);
  }, [patients]);

  if (userType === "clinician") {
    return (
      <div>
        <BootstrapTable
          keyField="code"
          hover
          bordered
          filter={filterFactory()}
          columns={[
            { dataField: "edit", text: "" },
            {
              dataField: "code",
              text: "SysDok-ID",
              sort: true,
              filter: textFilter({ placeholder: "Suche..." }),
            },
            {
              dataField: "date_of_birth",
              text: "Geburtsdatum",
              sort: true,
              sortValue: (cell) => {
                const dateSplit = cell.split(".");
                const newDate = new Date(
                  dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0],
                );
                return (cell = String(newDate));
              },
              filter: dateFilter({ placeholder: "Date" }),
              // TODO: remove eslint disables, see issue rehacat_frontend#335
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatter: (cell: any) => {
                return moment(cell).format("YYYY-MM-DD");
              },
              filterValue(cell) {
                return moment(cell).format("YYYY-MM-DD");
              },
            },
            {
              dataField: "gender",
              text: "Geschlecht",
              sort: true,
              filter: selectFilter({
                options: {
                  Weiblich: "Weiblich",
                  Männlich: "Männlich",
                  Divers: "Divers",
                },
                placeholder: "Auswählen...",
              }),
            },
            {
              dataField: "admission",
              text: "Erstgespräch",
              sort: true,
              sortValue: (cell) => {
                const dateSplit = cell.split(".");
                const newDate = new Date(
                  dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0],
                );
                return (cell = String(newDate));
              },
              filter: dateFilter({ placeholder: "Date" }),
              // TODO: remove eslint disables, see issue rehacat_frontend#335
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatter: (cell: any) => {
                return moment(cell).format("YYYY-MM-DD");
              },
              filterValue(cell) {
                return moment(cell).format("YYYY-MM-DD");
              },
            },
            {
              dataField: "discharge",
              text: "Therapieende",
              sort: true,
              sortValue: (cell) => {
                const dateSplit = cell.split(".");
                const newDate = new Date(
                  dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0],
                );
                return (cell = String(newDate));
              },
              filter: dateFilter({ placeholder: "Date" }),
              // TODO: remove eslint disables, see issue rehacat_frontend#335
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatter: (cell: any) => {
                return moment(cell).format("YYYY-MM-DD");
              },
              filterValue(cell) {
                return moment(cell).format("YYYY-MM-DD");
              },
            },
            {
              dataField: "labelsForTable",
              text: "System-ID",
              filter: textFilter({ placeholder: "Suche..." }),
              formatter: (cell) => {
                const labels = cell.split(",");

                return labels.map((tag, index) =>
                  tag ? (
                    // TODO: remove eslint disables, see issue rehacat_frontend#335
                    // eslint-disable-next-line react/jsx-key
                    <div style={{ marginBottom: "15px" }}>
                      <div className="tag-item" key={index}>
                        <span className="text">{tag}</span>
                      </div>
                    </div>
                  ) : null,
                );
              },
            },
            {
              dataField: "state",
              text: "Befragungsstatus",
            },
            { dataField: "result", text: "Ergebnis" },
            {
              dataField: "viewed",
              text: "Reports gesehen",
              sort: true,
              filter: selectFilter({
                options: {
                  "Nicht verfügbar": "Nicht verfügbar",
                  "Ungelesener Report": "Ungelesener Report",
                  "Alle Reports gelesen": "Alle Reports gelesen",
                },
                placeholder: "Auswählen...",
              }),
            },
          ]}
          data={patientTable}
          headerClasses={"tablehead-fixed"}
        />
      </div>
    );
  } else if (userType === "admin") {
    return (
      <div>
        {" "}
        <BootstrapTable
          keyField="id"
          hover
          bordered
          filter={filterFactory()}
          columns={[
            { dataField: "edit", text: "" },
            {
              dataField: "code",
              text: "SysDok-ID",
              sort: true,
              filter: textFilter({ placeholder: "Suche..." }),
            },
            {
              dataField: "clinician_username",
              text: "Mitarbeitende",
              sort: true,
              filter: textFilter({ placeholder: "Suche..." }),
            },
            {
              dataField: "date_of_birth",
              text: "Geburtsdatum",
              sort: true,
              sortValue: (cell) => {
                const dateSplit = cell.split(".");
                const newDate = new Date(
                  dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0],
                );
                return (cell = String(newDate));
              },
              filter: dateFilter({ placeholder: "Date" }),
              // TODO: remove eslint disables, see issue rehacat_frontend#335
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatter: (cell: any) => {
                return moment(cell).format("YYYY-MM-DD");
              },
              filterValue(cell) {
                return moment(cell).format("YYYY-MM-DD");
              },
            },
            {
              dataField: "gender",
              text: "Geschlecht",
              sort: true,
              filter: selectFilter({
                options: {
                  Weiblich: "Weiblich",
                  Männlich: "Männlich",
                  Divers: "Divers",
                },
                placeholder: "Auswählen...",
              }),
            },
            {
              dataField: "admission",
              text: "Aufnahmedatum",
              sort: true,
              sortValue: (cell) => {
                const dateSplit = cell.split(".");
                const newDate = new Date(
                  dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0],
                );
                return (cell = String(newDate));
              },

              filter: dateFilter({ placeholder: "Date" }),
              // TODO: remove eslint disables, see issue rehacat_frontend#335
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatter: (cell: any) => {
                return moment(cell).format("YYYY-MM-DD");
              },
              filterValue(cell) {
                return moment(cell).format("YYYY-MM-DD");
              },
            },
            {
              dataField: "discharge",
              text: "Entlassdatum",
              sort: true,

              sortValue: (cell) => {
                const dateSplit = cell.split(".");
                const newDate = new Date(
                  dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0],
                );
                return (cell = String(newDate));
              },
              filter: dateFilter({ placeholder: "Date" }),
              // TODO: remove eslint disables, see issue rehacat_frontend#335
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatter: (cell: any) => {
                return moment(cell).format("YYYY-MM-DD");
              },
              filterValue(cell) {
                return moment(cell).format("YYYY-MM-DD");
              },
            },
            {
              dataField: "labelsForTable",
              text: "System-ID",
              filter: textFilter({ placeholder: "Suche..." }),
              formatter: (cell) => {
                const labels = cell.split(",");

                return labels.map((tag, index) =>
                  tag ? (
                    // TODO: remove eslint disables, see issue rehacat_frontend#335
                    // eslint-disable-next-line react/jsx-key
                    <div style={{ marginBottom: "15px" }}>
                      <div className="tag-item" key={index}>
                        <span className="text">{tag}</span>
                      </div>
                    </div>
                  ) : null,
                );
              },
            },
            { dataField: "state", text: "Befragungsstatus" },
          ]}
          data={patientTable}
          headerClasses={"tablehead-fixed"}
        />
      </div>
    );
  } else {
    return <p> Wrong user type!</p>;
  }
};

export default PatientTable;
