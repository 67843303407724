import React, { Component, createContext } from "react";
import authService from "./services/AuthService";
import { AppContext } from "./AppProvider";
import { UserType } from "./types/authTypes";

export interface LoginData {
  clinic_id: string;
  username: string;
  password: string;
  clinicname?: string;
  userType?: UserType;
}

// Define the shape of the authentication context
export interface UserInfo {
  clinicId: string;
  clinicName: string;
  userName: string;
  userType?: string;
}
export interface AuthContextState {
  isAuthenticated: boolean;
  userInfo?: UserInfo;
}
export interface AuthContextType {
  state: AuthContextState;
  setState?: (state: AuthContextState) => void;
  login: (data: LoginData, cb: (response: string) => void) => Promise<void>;
  logout: () => void;
}

export const initialAuthState: AuthContextState = {
  isAuthenticated: false,
  userInfo: undefined,
};

// Create the initial context with default values
export const AuthContext = createContext<AuthContextType>({
  state: initialAuthState,
  setState: () => {},
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: async (data: LoginData, cb: (response: string) => void) => {},
  logout: () => {},
});

// Create a higher-order component to wrap other components with the AuthContext
// TODO: remove eslint disables, see issue rehacat_frontend#335
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withAuthContext = (Component: React.ComponentType<any>) => {
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return class WithAuthContext extends React.Component<any> {
    render() {
      return (
        <AppContext.Consumer>
          {(appContext) => (
            <AuthContext.Consumer>
              {(authContext) => (
                <Component
                  {...this.props}
                  appContext={appContext}
                  authContext={authContext}
                />
              )}
            </AuthContext.Consumer>
          )}
        </AppContext.Consumer>
      );
    }
  };
};

// Define the authentication provider component

// TODO: remove eslint disables, see issue rehacat_frontend#335
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class AuthProvider extends Component<any, AuthContextState> {
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = {
      isAuthenticated: false,
      userInfo: undefined,
    };
  }

  login = async (loginData: LoginData, cb: (res: string) => void) => {
    try {
      const authResponse = await authService.login(loginData);
      if (authResponse === "success") {
        const userInfo = {
          clinicId: loginData.clinic_id,
          clinicName: loginData.clinicname || loginData.clinic_id,
          userName: loginData.username,
          userType: loginData.userType || "",
        };
        authService.setUserInfo(userInfo);
        this.setState({ isAuthenticated: true, userInfo });
        cb(authResponse);
      } else {
        cb(authResponse);
      }
    } catch (e) {
      console.log("Catch", e);
      return;
    }
  };

  // Function to simulate logout
  logout = () => {
    this.setState({ isAuthenticated: false });
  };

  //reload
  componentDidMount(): void {
    this.setState({
      isAuthenticated: authService.isLoggedIn(),
      userInfo: authService.getUserInfo(),
    });
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          state: this.state,
          login: this.login,
          logout: this.logout,
          setState: this.setState,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
