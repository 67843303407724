import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { PubSub } from "../../helper/pubSub";
import { Button } from "react-bootstrap";
import { AppContext, AppContextType } from "../../AppProvider";
import LogoUulm from "../../img/Logo_uulm.png";
import ClinicianLogo from "../../img/user/clinician.png";
import PatientLogo from "../../img/user/patient.png";
import "./Sidebar.css";

interface SidebarAdminProps {
  handleAccordion: (accordion: string) => void;
}

const SidebarAdmin: FC<SidebarAdminProps> = ({ handleAccordion }) => {
  const { state: appState } = useContext<AppContextType>(AppContext);
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subRef = useRef<any>(undefined);
  const location = useLocation();
  const informationPaths = [
    "/admin/information",
    "/impressum",
    "/privacypolicy",
    "/contact",
  ]; //all paths belonging to information section. For these paths the menu stays open
  let information = false;

  if (informationPaths.includes(location.pathname)) {
    information = true;
  }

  const [display, setDisplay] = useState({
    patients: true,
    employees: false,
    clinic: false,
    information,
  });

  const closeAllAccordions = () => {
    console.log("Called closeAllAccordions");
    setDisplay((prevState) => ({
      ...prevState,
      patients: false,
      employees: false,
      clinic: false,
      information: false,
    }));
    handleAccordion("closeAll");
  };

  const handlePatients = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patients: !display.patients,
      employees: false,
      clinic: false,
      information: false,
    }));
    handleAccordion("patients");
  };

  const handleEmployees = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patients: false,
      employees: !display.employees,
      clinic: false,
      information: false,
    }));
    handleAccordion("employees");
  };

  // const handleClinic = () => {
  //   setDisplay((prevState) => ({
  //     ...prevState,
  //     patients: false,
  //     employees: false,
  //     clinic: !display.clinic,
  //     information: false,
  //   }));
  //   handleAccordion("clinic");
  // };

  const handleInformation = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patients: false,
      employees: false,
      clinic: false,
      information: !display.information,
    }));
    handleAccordion("info");
  };

  useEffect(() => {
    const {
      accordionData: { accordionPatients, accordionEmployees, accordionClinic },
    } = appState;
    setDisplay((prevState) => ({
      ...prevState,
      patients: accordionPatients === "0",
      employees: accordionEmployees === "1",
      clinic: accordionClinic === "2",
    }));
  }, [appState]);

  useEffect(() => {
    //runs only once when component is initialized because of the empty array as second argument
    subRef.current = PubSub.getInstance().subscribeToTopic(
      "close-sidebar-submenus",
      () => {
        closeAllAccordions();
      },
    );
    return () => {
      if (typeof subRef.current?.unsubscribe === "function") {
        subRef.current?.unsubscribe();
      }
    };
  }, []);

  const renderPatientsItem = () => (
    <Button
      title="Patient*innen"
      aria-label="Patient*innen"
      variant=""
      className={
        display.patients ? "active sidenav-item-link" : "sidenav-item-link"
      }
      onClick={handlePatients}
    >
      <i className="mdi">
        <img
          className="rounded-circle user-role"
          src={PatientLogo}
          width="25px"
          alt="Patient*innen"
        />
      </i>
      <span className="nav-text">Patient*innen</span>{" "}
      <strong className="caret" />
    </Button>
  );

  const renderEmployeesItem = () => (
    <Button
      title="Mitarbeitende"
      aria-label="Mitarbeitende"
      variant=""
      className={
        display.employees ? "active sidenav-item-link" : "sidenav-item-link"
      }
      onClick={handleEmployees}
    >
      <i className="mdi">
        <img
          className="rounded-circle user-role"
          src={ClinicianLogo}
          width="25px"
          alt="Mitarbeitende"
        />
      </i>
      <span className="nav-text">Mitarbeitende</span>{" "}
      <strong className="caret" />
    </Button>
  );

  /**
   * not used anymore
   */
  // const renderClinicItem = () => (
  //   <li
  //     className={display.clinic ? "active" : ""}
  //     style={{ cursor: "pointer" }}
  //     onClick={handleClinic}
  //   >
  //     <a
  //       className="sidenav-item-link"
  //       data-toggle="collapse"
  //       aria-expanded="false"
  //     >
  //       <i className="mdi">
  //         {" "}
  //         {/* <BsFillPersonFill /> */}
  //         <img
  //           className="rounded-circle user-role"
  //           src="../img/user/hospital.png"
  //           width="25px"
  //           alt="K"
  //         />
  //       </i>
  //       <span className="nav-text">Klinik</span> <strong className="caret" />
  //     </a>
  //   </li>
  // );

  const renderInformationSubMenu = () => (
    <ul
      className={display.information ? "show" : "collapse"}
      id="documentation"
      data-parent="#sidebar-menu"
    >
      <div className="sub-menu p-0">
        <Link
          to={{
            pathname: "/admin/information",
          }}
        >
          <span className="nav-text">SysDok</span>
        </Link>
      </div>
    </ul>
  );

  const renderInformation = () => (
    <div>
      <Button
        title="Informationen"
        aria-label="Informationen"
        variant=""
        className={
          display.information ? "active sidenav-item-link" : "sidenav-item-link"
        }
        onClick={handleInformation}
        id="clinic-stuff-sidebar-information"
        data-toggle="collapse"
        data-target="#documentation"
        aria-expanded="false"
        aria-controls="documentation"
      >
        <i className="mdi">
          <IoMdInformationCircle />
        </i>
        <span className="nav-text">Informationen</span>{" "}
        <strong className="caret" />
      </Button>
      {renderInformationSubMenu()}
    </div>
  );

  return (
    <div>
      <div id="sidebar" className="sidebar sidebar-with-footer">
        <div data-simplebar data-height="100%">
          <ul className="nav sidebar-inner" id="sidebar-menu">
            {renderPatientsItem()}
            {renderEmployeesItem()}
            <hr className="separator mb-0" />
            {renderInformation()}
          </ul>
        </div>
        <div className="sidebar-footer sidebar-footer-content">
          <a
            id="sidebar-logo-two"
            href="https://www.uni-ulm.de/in/abt-klinische-psychologie-und-psychotherapie/"
            target="blank"
          >
            <img
              src={LogoUulm}
              width="160px"
              alt="Logo Universität Ulm: Öffnet neuen Tab: Universitätswebseite der Abteilung klinische Psychologie und Psychotherapie"
              title="Öffnet neuen Tab: Universisätswebseite der Abteilung klinische Psychologie und Psychotherapie"
            />
          </a>
          <a
            id="sidebar-logo"
            href="https://www.uniklinik-freiburg.de/de.html"
            target="blank"
          >
            <img
              src={"../../img/UKF_LOGO.png"}
              width="160px"
              alt="Logo Universitätsklinikum Freiburg: Öffnet neuen Tab: Universitätsklinikumwebseite"
              title="Öffnet neuen Tab: Universisätsklinikumwebseite"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
export default SidebarAdmin;
