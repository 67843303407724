import React, { FC, useContext, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TimeoutSession from "../../helper/TimeoutSession";
import Footer from "../Footer";
import NavigationBar from "../navigation/NavigationBar";
import SidebarClinician from "../sidebars/SidebarClinician";
import { RouteComponentProps } from "react-router-dom";
import InfoTabPatient from "../sharedInfoPageTabs/InfoTabPatient";
import InfoTabClinician from "../sharedInfoPageTabs/InfoTabClinician";
import ClinicianLogo from "../../img/user/clinician.png";
import PatientLogo from "../../img/user/patient.png";
import { AppContext } from "../../AppProvider";
import { AuthContext } from "../../AuthProvider";

interface InformationPageClinicianProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any;
  history: RouteComponentProps["history"];
}

const InformationPageClinician: FC<InformationPageClinicianProps> = ({
  title,
}) => {
  const { state: authState } = useContext(AuthContext);
  const { handleAccordion } = useContext(AppContext);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return (
    <div className="header-fixed" id="body">
      <TimeoutSession />
      <div className="skip-link-div">
        <a className="skip-link" href="#main-content">
          Direkt zum Inhalt springen
        </a>
      </div>
      <NavigationBar />
      <div className="page-wrapper-new">
        <div className="wrapper">
          <div className="skip-sidebar-div">
            <a className="skip-sidebar" href="#main-content">
              Navigation überspringen
            </a>
          </div>
          <SidebarClinician
            handleAccordion={(input) =>
              handleAccordion({
                input,
                userType: authState.userInfo?.userType,
              })
            }
          />
          <div className="content-wrapper">
            <div className="content" id="main-content">
              <h3>Informationen zu SysDok</h3>
              <Tabs defaultActiveKey="clinician" className="mb-3">
                <Tab
                  eventKey="clinician"
                  title={
                    <div>
                      <i className="mdi">
                        <img
                          className="rounded-circle "
                          src={ClinicianLogo}
                          width="25px"
                          alt="Clinician"
                        />
                      </i>
                      <span className=""> Für Mitarbeitende</span>
                    </div>
                  }
                >
                  <InfoTabClinician />
                </Tab>
                <Tab
                  eventKey="patient"
                  title={
                    <div>
                      <i className="mdi">
                        <img
                          className="rounded-circle "
                          src={PatientLogo}
                          width="25px"
                          alt="Patient"
                        />
                      </i>
                      <span className=""> Für Patient*innen</span>
                    </div>
                  }
                >
                  <InfoTabPatient />
                </Tab>
              </Tabs>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default InformationPageClinician;
