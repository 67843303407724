/* eslint-disable no-eval */
import React from "react";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  OverlayTrigger,
  ProgressBar,
  Spinner,
  Tooltip,
} from "react-bootstrap";
// import AuthService from "../../services/AuthService";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import TimeoutSession from "../../helper/TimeoutSession";
import { showAlertModal } from "../../helper/alertBoxes";
import temperatureScale from "../../img/temperature_scale.png";
import {
  getSurvey,
  ResponseOption,
  SurveyItem,
} from "../../services/getFunctions";
import { setSurvey } from "../../services/postFunctions";
import { SurveyDimension } from "../../types/surveyTypes";
import NavigationBar from "../navigation/NavigationBar";

interface Group {
  head: boolean;
  value: number;
  id: number;
  group: boolean;
  type: string;
  content: string;
  response_options: ResponseOption[];
}
interface ItemResponse {
  id: number;
  label: string;
  time_taken: number;
  value: string;
}

const RunSurvey: React.FC<{ title: string }> = ({ title }) => {
  const history = useHistory();

  const [date, setDate] = React.useState(new Date());
  const [items, setItems] = React.useState<SurveyItem[]>([]);
  const [iGroups, setIGroups] = React.useState<Group[]>([]);
  const [surveyDimension, setSurveyDimension] = React.useState<
    SurveyDimension | undefined
  >(undefined);
  const [waitModalOpen, setWaitModalOpen] = React.useState(false);
  const [itemResponses, setItemResponses] = React.useState<ItemResponse[]>([]);
  const [progressBarStatus, setProgressBarStatus] = React.useState(0);
  const [content, setContent] = React.useState<string | undefined>(undefined);
  const [highlightItemIndex, setHighlightItemIndex] = React.useState(-1);
  const [sendingSurvey, setSendingSurvey] = React.useState(false);
  const progressInterval = React.useRef<NodeJS.Timeout | null>(null);
  const [endOfSurvey, setEndOfSurvey] = React.useState(false);

  /**Method to set waitModalOpen on true and start the progress bar status. */
  const openWaitModal = (callback?: () => void) => {
    setWaitModalOpen(true);
    let countdown = 0;
    progressInterval.current = setInterval(() => {
      if (countdown < 98) {
        countdown++;
        setProgressBarStatus(countdown);
      } else {
        if (callback) {
          callback();
        }
      }
    }, 80);
  };

  /**
   * Closes the wait modal and clears the progress interval.
   *
   * This function sets the wait modal's state to closed by calling `setWaitModalOpen(false)`.
   * If a progress interval is currently running, it clears the interval using `clearInterval`.
   */

  const closeModal = () => {
    setWaitModalOpen(false);
    if (progressInterval.current) {
      clearInterval(progressInterval.current);
    }
  };

  /*
   *  Ends the survey by updating the progress bar status, closing the modal, and navigating to the patients page.
   *  Sets the progress bar status to 100%.
   *  Closes the wait modal by calling `closeModal`.
   *  Redirects the user to the '/patients' route using `history.push`.
   */

  const endSurvey = () => {
    setProgressBarStatus(100);
    closeModal();
    history.push({
      pathname: "/patients",
    });
  };

  /**
   * This function calls `getSurvey` to get the survey data.
   * Depending on the response's status code:
   * - If the status code is 204, it sets the end of the survey state.
   * - If the status code is 500, it shows an alert modal with an error message and navigates to the '/patients' route.
   * - Otherwise, it processes the survey data by updating the items and survey dimension.
   * If an error occurs during the fetch, it shows an alert modal with an error message.
   */
  const tryGetSurvey = async () => {
    try {
      const res = await getSurvey();
      if (res.statusCode === 204) {
        setEndOfSurvey(true);
      } else if (res.statusCode === 500) {
        showAlertModal("Entschuldigung. Da ist etwas schiefgelaufen.");
        history.push({
          pathname: "/patients",
        });
      } else {
        const newItems = [...res.survey.items];
        setItems(newItems);
        setSurveyDimension(res.survey.dimension);
      }
    } catch (e) {
      showAlertModal("Entschuldigung. Da ist etwas schiefgelaufen.");
    }
  };

  /**
   * Sends survey responses and handles the survey update process.
   *
   *  Opens the wait modal by calling `openWaitModal`.
   *  Sets the sending survey state to true.
   *  Sends the survey responses by calling `setSurvey` with the provided items.
   *  Attempts to retrieve the updated survey data by calling `tryGetSurvey`.
   *  Sets the sending survey state to false.
   *  Closes the wait modal by calling `closeModal`.
   *
   * If an error occurs at any step, it ensures the sending survey state is set to false and the wait modal is closed.
   */

  const sendSurveyResponses = async (items: ItemResponse[]) => {
    try {
      openWaitModal();
      setSendingSurvey(true);
      await setSurvey(items);
      await tryGetSurvey();
      setSendingSurvey(false);
      closeModal();
    } catch (e) {
      setSendingSurvey(false);
      closeModal();
    }
  };

  /**Set initial state for optional items, email items and info items.
   * If item type is INFO_AUTOSUBMIT send response immediatly.
   */
  const initialEmptyResponses = (items) => {
    const responses: ItemResponse[] = [];

    for (let i = 0; i < items.length; i++) {
      if (
        items[i].type === "INFO" ||
        items[i].type === "INFO_AUTOSUBMIT" ||
        items[i].type === "QUESTION_TEXT_OPTIONAL" ||
        items[i].type === "QUESTION_TEXT_AREA_OPTIONAL" ||
        items[i].type === "EMAIL_NOTIFICATION_PRE" ||
        items[i].type === "EMAIL_NOTIFICATION_INTERMEDIATE" ||
        items[i].type === "QUESTION_HORIZONTAL_RADIO" ||
        items[i].type === "QUESTION_VERTICAL_RADIO" ||
        items[i].type === "QUESTION_HORIZONTAL_RADIO_ENFORCED" ||
        items[i].type === "EMAIL_NOTIFICATION_POST" ||
        items[i].type === "EMAIL_NOTIFICATION_FOLLOW_UP"
      ) {
        responses[i] = {
          id: items[i].id,
          label: "",
          time_taken: 0,
          value: "",
        };
      } else {
        /* empty */
      }
    }
    setItemResponses(responses);
  };

  /**Extract scripts in html-response from item "INFO" and "INFO_AUTOSUBMIT".
   * Run clean javascript-code with window.eval().
   */
  function extractScript(items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].type === "INFO" || items[i].type === "INFO_AUTOSUBMIT") {
        if (items[i].content != null) {
          const content = items[i].content;
          if (/<script\s*.*>[\s\S]*<\/script>/gi.exec(content) != null) {
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const extract = /<script\s*.*>[\s\S]*<\/script>/gi.exec(
              content,
            )![0];
            setContent(extract);
          }
        }
      }
      if (content && content !== "") {
        if (content !== undefined) {
          const cleanScript = content.replace(/<\/?script\s*.*>/gm, "");
          window.eval(cleanScript);
        }
      }
    }
  }

  /**Check if Items with type "QUESTION_HORIZONTAL_RADIO" have the same response_options.
   * Save them in state list itemGroups with parameter group: true||false.
   * The first item in group needs the parameter head: true.
   */
  function checkGroupOptions(items) {
    if (items.length === 1 && items[0].type === "QUESTION_HORIZONTAL_RADIO") {
      const groups: Group[] = [...iGroups];
      groups.push(items[0]);
      groups[0].id = items[0].id;
      groups[0].group = false;
      setIGroups(groups);
    } else {
      //for loop to set value = 0 || 1 || 2 || 3
      for (let i = 0; i < items.length; i++) {
        const groups: Group[] = [...iGroups];
        if (items[i].type !== "QUESTION_HORIZONTAL_RADIO") {
          groups[i] = { ...items[i] };
          groups[i].id = items[i].id;
          groups[i].value = 0;
        } else if (
          items[i].type === "QUESTION_HORIZONTAL_RADIO" &&
          (!items[i - 1] ||
            items[i - 1].type !== "QUESTION_HORIZONTAL_RADIO") &&
          (!items[i + 1] || items[i + 1].type !== "QUESTION_HORIZONTAL_RADIO")
        ) {
          groups[i].id = items[i].id;
          groups[i].value = 1;
          groups[i].group = false;
        } else if (
          items[i].type === "QUESTION_HORIZONTAL_RADIO" &&
          items[i + 1] &&
          items[i + 1].type === "QUESTION_HORIZONTAL_RADIO"
        ) {
          groups[i].id = items[i].id;
          groups[i].value = 2;
        } else if (
          items[i].type === "QUESTION_HORIZONTAL_RADIO" &&
          items[i - 1] &&
          items[i - 1].type === "QUESTION_HORIZONTAL_RADIO" &&
          (!items[i + 1] || items[i + 1].type !== "QUESTION_HORIZONTAL_RADIO")
        ) {
          groups[i].id = items[i].id;
          groups[i].value = 3;
        } else {
          //console.log('Da fehlt wohl noch etwas! Item: ', items[i]);
        }
        setIGroups(groups);
      }
    }
    //for loop to loop over items-list with inner for-loop to check response_options
    for (let j = 0; j < items.length; j++) {
      //console.log("Item: ", j, "Gruppe: ", iGroups);
      let group = 0;
      const groups = [...iGroups];
      if (groups[j]) {
        if (groups[j].value === 2) {
          for (let l = 0; l < items[j].response_options.length; l++) {
            if (
              items[j + 1] &&
              items[j].response_options.length ===
                items[j + 1].response_options.length &&
              items[j].response_options[l].label ===
                items[j + 1].response_options[l].label
            ) {
              group++;
            }
          }
          if (group === items[j].response_options.length) {
            groups[j] = {
              ...iGroups[j],
              group: true,
            };
            groups[j + 1] = {
              ...iGroups[j + 1],
              group: true,
            };
            setIGroups(groups);
          } else if (iGroups[j].group) {
            groups[j] = {
              ...iGroups[j],
              value: 3,
            };
            setIGroups(groups);
          } else if (!iGroups[j].group) {
            groups[j] = {
              ...iGroups[j],
              group: false,
            };
            setIGroups(groups);
          }
        } else if (iGroups[j].value === 3 && !iGroups[j].group) {
          groups[j] = {
            ...iGroups[j],
            group: false,
          };
          setIGroups(groups);
        }
      }
    }

    for (let k = 0; k < iGroups.length; k++) {
      if (iGroups[k]) {
        if (
          iGroups[k].value === 2 &&
          iGroups[k].group &&
          (!iGroups[k - 1] ||
            iGroups[k - 1].value !== 2 ||
            !iGroups[k - 1].group)
        ) {
          const groups = [...iGroups];
          groups[k] = {
            ...iGroups[k],
            head: true,
          };
          setIGroups(groups);
        }
      }
    }
  }

  /**Handle responses from radio buttons and set response state with the right item id
   * @param event {ChangeEvent} onChange delivers the event with the selected radio button
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  const handleRadioBtn = (event, item, index) => {
    const responses = [...itemResponses];
    responses[index] = {
      id: item.id,
      label: event.target.dataset.name,
      time_taken: event.timeStamp,
      value: event.target.value,
    };
    setItemResponses(responses);
  };

  /**Handle responses from question select and set response state with the right item id
   * @param event {ChangeEvent} onChange delivers the event with the selected option
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  const handleQuestionSelect = (event, item, index) => {
    const responses = [...itemResponses];
    if (event.target.value !== "Auswählen...") {
      responses[index] = {
        id: item.id,
        label: event.target[event.target.selectedIndex].dataset.name,
        time_taken: event.timeStamp,
        value: event.target.value,
      };
    } else if (event.target.value === "Auswählen...") {
      responses[index] = {
        id: 0,
        label: "",
        time_taken: 0,
        value: "",
      };
    } else return;
    setItemResponses(responses);
  };

  /**Handle responses from text input and set response state with the right item id
   * @param event {ChangeEvent} onChange delivers the event with the changed text input
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  const handleTextInput = (event, item, index) => {
    const responses = [...itemResponses];
    if (event.target.value !== "" || event.target.type === "email") {
      responses[index] = {
        id: item.id,
        label: "",
        time_taken: event.timeStamp,
        value: event.target.value,
      };
    } else {
      responses[index] = {
        id: 0,
        label: "",
        time_taken: 0,
        value: "",
      };
    }

    setItemResponses(responses);
  };

  /**Handle responses from date input and set response state with the right item id
   * @param date {date} date from datepicker or date input
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  const handleDate = (date, item, index) => {
    setDate(date);
    const responses = [...itemResponses];
    responses[index] = {
      id: item.id,
      label: "",
      time_taken: 0,
      value: date.toISOString().split("T")[0],
    };
    setItemResponses(responses);
  };

  /**Handle responses from integer input and set response state with the right item id.
   * Checks whether the value is an integer. If the value isNaN after parseInt() the state would not be updated.
   * @param event {ChangeEvent} onChange delivers the event with the changed text input
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  const handleInteger = (event, item, index) => {
    const numberRegex = /^\d+$/;
    const responses = [...itemResponses];
    const strVal = event.target.value;
    const val = parseInt(strVal);
    const highestIntAllowedByBackend = 2147483647; //(2^31-1)

    if (
      !numberRegex.test(event.key) &&
      ![
        "Delete",
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Tab",
      ].includes(event.key) &&
      event.type === "keydown"
    ) {
      event.nativeEvent.preventDefault();
    }

    if (numberRegex.test(strVal)) {
      responses[index] = {
        id: item.id,
        label: "",
        time_taken: event.timeStamp,
        value: String(val),
      };
    } else if (event.target.value === "") {
      responses[index] = {
        id: 0,
        label: "",
        time_taken: 0,
        value: "",
      };
    }

    if (val > highestIntAllowedByBackend) {
      event.target.value = event.target.value.slice(0, -1);
      showAlertModal(
        "Die von Ihnen eingegebene Zahl ist zu groß. Die höchste erlaubte Zahl ist " +
          highestIntAllowedByBackend,
      );
    }

    setItemResponses(responses);
  };

  /**
   * When called searches for a sibling of the event target that is an input element of type=email and if found adds a @ symbol to the end of it's value
   * @param {*} event
   * @param {*} item
   * @param {*} index
   */
  const handleAtSymbol = (event) => {
    const parent = event.target.parentElement;

    const formElement = parent.querySelector("input[type=email]");
    try {
      formElement.value += "@";
    } catch (e) {
      console.error("Error when trying to add @: ", e);
    }
  };

  /**Handle responses from positive double input and set response state with the right item id.
   * The value can be typed in with a "." or ",".
   * Checks whether the value is a positive double. If the value isNaN after parseFloat() or the value is negative the state would not be updated.
   * @param event {ChangeEvent} onChange delivers the event with the changed text input
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  const handlePosDouble = (event, item, index) => {
    const responses = [...itemResponses];
    const val = parseFloat(event.target.value.replace(/,/, "."));
    if (!isNaN(val) && val > 0) {
      responses[index] = {
        id: item.id,
        label: "",
        time_taken: event.timeStamp,
        value: String(val),
      };
      setItemResponses(responses);
    } else if (event.target.value === "") {
      responses[index] = {
        id: 0,
        label: "",
        time_taken: 0,
        value: "",
      };
    } else {
      showAlertModal("Sie dürfen nur positive Kommazahlen eingeben.");
    }
  };
  /**
   * Method for handle the survey item responses.
   * The time taken is calculated and divided among the items.
   * Then it is checked whether an entry has been created in the list for each item. If not, an alert is displayed to the user.
   * The input data are stringified to JSON.
   * Then send JSON to backend API and handle the response.
   */
  const handleSurveyResponses = async (event) => {
    event.preventDefault();
    setHighlightItemIndex(-1);
    if (Array.isArray(items) && items[0]?.type !== "INFO_AUTOSUBMIT") {
      const count = items.length;
      const timeTaken = (event.timeStamp / 1000 / count).toFixed(3);
      let valid = true;
      const responses = [...itemResponses];
      for (let i = 0; i < count; i++) {
        if (
          items[i].type === "EMAIL_NOTIFICATION_PRE" ||
          items[i].type === "EMAIL_NOTIFICATION_INTERMEDIATE" ||
          items[i].type === "EMAIL_NOTIFICATION_POST" ||
          items[i].type === "EMAIL_NOTIFICATION_FOLLOW_UP"
        ) {
          const email = responses[i].value;
          if (
            email === "" ||
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
          ) {
            valid = true;
          } else {
            showAlertModal(
              "Die E-Mail ist nicht valide. Bitte geben Sie eine valide E-Mail ein.",
            );
            valid = false;
          }
        }
        if (!responses[i]) {
          showAlertModal(
            "Sie haben noch nicht alle Fragen beantwortet. Folgende Frage wurde noch nicht beantwortet: " +
              (i + 1),
          );
          setHighlightItemIndex(i);
          return;
        } else {
          responses[i] = {
            ...itemResponses[i],
            time_taken: parseFloat(timeTaken),
          };
        }
      }
      // console.log('Antworten mit der neuen Zeit: ', responses);
      if (valid) {
        await sendSurveyResponses(responses);
      }
    } else if (items[0]?.type === "INFO_AUTOSUBMIT") {
      await sendSurveyResponses(itemResponses);
    } else {
      setEndOfSurvey(true);
    }
  };

  React.useLayoutEffect(() => {
    if (title) {
      document.title = title;
    }
    return () => {
      if (!endOfSurvey) {
        showAlertModal(
          "Sie verlassen die Befragung und kehren zur Übersicht zurück. Pausierte Befragungen können Sie jederzeit fortsetzen.",
        );
      }
    };
  }, [title, history, endOfSurvey]);

  React.useEffect(() => {
    (async function () {
      await tryGetSurvey();
    })();
  }, []);

  React.useEffect(() => {
    if (Array.isArray(items)) {
      initialEmptyResponses(items);
      checkGroupOptions(items);
      extractScript(items);
    }
  }, [items]);

  React.useEffect(() => {
    if (Array.isArray(items) && Array.isArray(itemResponses)) {
      const autoSubmitItems = items.filter((i) => i.type === "INFO_AUTOSUBMIT");
      if (Array.isArray(autoSubmitItems) && autoSubmitItems.length > 0) {
        const ids = autoSubmitItems.map((i) => i.id);
        const itemsToSend = itemResponses.filter((s) => ids.includes(s.id));
        if (Array.isArray(itemsToSend) && itemsToSend.length > 0) {
          (async function () {
            await sendSurveyResponses(itemsToSend);
          })();
        }
      }
    }
  }, [itemResponses, items]);

  React.useEffect(() => {
    if (endOfSurvey) {
      showAlertModal(
        "Sie verlassen die Befragung und kehren zur Übersicht zurück. Pausierte Befragungen können Sie jederzeit fortsetzen.",
      );
      endSurvey();
    }
  }, [endOfSurvey]);

  return (
    <div className="header-fixed" id="body">
      <TimeoutSession />
      <div className="skip-link-div">
        <a className="skip-link" href="#main-content">
          Direkt zum Inhalt springen
        </a>
      </div>
      <NavigationBar />
      <div className="page-wrapper-new">
        <div className="wrapper">
          <div className="content-wrapper">
            <div
              id="main-content"
              className="content content-without-sidebar survey-form"
            >
              {surveyDimension?.title ? (
                <h4>{surveyDimension?.title}</h4>
              ) : null}
              {surveyDimension?.paragraph ? (
                <div
                  className="survey-description-text"
                  dangerouslySetInnerHTML={{
                    __html: surveyDimension?.paragraph,
                  }}
                ></div>
              ) : null}
              <Form noValidate onSubmit={handleSurveyResponses}>
                {items &&
                  items.length > 0 &&
                  items.map((item, index) => {
                    return item.type === "INFO" ||
                      item.type === "INFO_AUTOSUBMIT" ? (
                      <div
                        className="survey-info-element"
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}
                      ></div>
                    ) : (
                      <Form.Group key={index} className="survey-form-group">
                        {item.type !== "QUESTION_HORIZONTAL_RADIO" ? (
                          <Form.Label
                            className={`survey-label-element${
                              index === highlightItemIndex
                                ? " question-highlighted"
                                : ""
                            }`}
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: item.content,
                            }}
                          ></Form.Label>
                        ) : null}

                        {(() => {
                          switch (item.type) {
                            case "QUESTION_HORIZONTAL_RADIO":
                              return (
                                <div className="radio-horizontal">
                                  {iGroups &&
                                    iGroups.length > 0 &&
                                    iGroups.map((itemgroup, i) => {
                                      return i === index && itemgroup.group ? (
                                        <div
                                          key={itemgroup.id}
                                          className="radio-grid-table-element "
                                        >
                                          {itemgroup.head ? (
                                            <div
                                              key={i}
                                              className="radio-grid-table-head radio-grid-table without-horizontal-scroll"
                                            >
                                              <div
                                                className="radio-label"
                                                key="-1"
                                              ></div>
                                              {item.response_options &&
                                                item.response_options.length >
                                                  0 &&
                                                item.response_options.map(
                                                  (option, idx) => {
                                                    return (
                                                      // TODO: remove eslint disables, see issue rehacat_frontend#335
                                                      // eslint-disable-next-line react/jsx-key
                                                      <div className="radio-col">
                                                        <label
                                                          className="radio-custom"
                                                          key={idx}
                                                        >
                                                          {option.label}
                                                        </label>
                                                      </div>
                                                    );
                                                  },
                                                )}
                                            </div>
                                          ) : null}
                                          {/* Question Label */}
                                          <div
                                            className="radio-label"
                                            dangerouslySetInnerHTML={{
                                              __html: item.content,
                                            }}
                                          ></div>
                                          <div
                                            className={`without-horizontal-scroll radio-grid-table grid-table${
                                              index === highlightItemIndex
                                                ? " question-highlighted"
                                                : ""
                                            }`} /*{
                                                  i % 2 === 0
                                                    ? "align-horizontal-scroll radio-grid-table grid-table"
                                                    : "align-horizontal-scroll radio-grid-table"
                                                }*/
                                          >
                                            <div
                                              className="radio-label"
                                              dangerouslySetInnerHTML={{
                                                __html: item.content,
                                              }}
                                              key={i}
                                            ></div>
                                            {item.response_options &&
                                              item.response_options.length >
                                                0 &&
                                              item.response_options.map(
                                                (option, idx) => {
                                                  return (
                                                    // TODO: remove eslint disables, see issue rehacat_frontend#297
                                                    // eslint-disable-next-line react/jsx-key
                                                    <div className="radio-col">
                                                      {/* TODO: remove eslint disables, see issue rehacat_frontend#297 */}
                                                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                      <label className="radio-custom">
                                                        <Form.Check
                                                          /*className="btn-lg"*/
                                                          type="radio"
                                                          aria-label="radio-btn"
                                                          inline
                                                          name={String(item.id)}
                                                          data-name={
                                                            option.label
                                                          }
                                                          key={idx}
                                                          id={option.id}
                                                          value={option.value}
                                                          defaultChecked={false}
                                                          checked={
                                                            itemResponses[
                                                              index
                                                            ] &&
                                                            itemResponses[index]
                                                              .value ===
                                                              option.value
                                                          }
                                                          onChange={(event) => {
                                                            handleRadioBtn(
                                                              event,
                                                              item,
                                                              index,
                                                            );
                                                          }}
                                                        />
                                                      </label>
                                                    </div>
                                                  );
                                                },
                                              )}
                                          </div>
                                        </div>
                                      ) : i === index && itemgroup.group ? (
                                        <div
                                          key={i}
                                          className="radio-normal-element"
                                        >
                                          <Form.Label
                                            key={index}
                                            dangerouslySetInnerHTML={{
                                              __html: item.content,
                                            }}
                                          ></Form.Label>
                                          <div className="align-horizontal-scroll">
                                            {item.response_options &&
                                              item.response_options.length >
                                                0 &&
                                              item.response_options.map(
                                                (option, idx) => {
                                                  return (
                                                    // TODO: remove eslint disables, see issue rehacat_frontend#297
                                                    // eslint-disable-next-line react/jsx-key
                                                    <div className="radio-col">
                                                      {/* TODO: remove eslint disables, see issue rehacat_frontend#335 */}
                                                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                      <label className="radio-custom">
                                                        <Form.Check
                                                          type="radio"
                                                          /*className="btn-lg"*/
                                                          inline
                                                          name={String(item.id)}
                                                          data-name={
                                                            option.label
                                                          }
                                                          key={idx}
                                                          id={option.id}
                                                          label={option.label}
                                                          value={option.value}
                                                          defaultChecked={false}
                                                          checked={
                                                            itemResponses[
                                                              index
                                                            ] &&
                                                            itemResponses[index]
                                                              .value ===
                                                              option.value
                                                          }
                                                          onChange={(event) => {
                                                            handleRadioBtn(
                                                              event,
                                                              item,
                                                              index,
                                                            );
                                                          }}
                                                        />
                                                      </label>
                                                    </div>
                                                  );
                                                },
                                              )}
                                          </div>{" "}
                                        </div>
                                      ) : null;
                                    })}{" "}
                                </div>
                              );

                            case "QUESTION_HORIZONTAL_RADIO_ENFORCED":
                              return (
                                <div
                                  className={`align-horizontal-scroll radio-horizontal-enforced${
                                    index === highlightItemIndex
                                      ? " question-highlighted"
                                      : ""
                                  }`}
                                >
                                  {/* Question Label */}

                                  {item.response_options &&
                                    item.response_options.length > 0 &&
                                    item.response_options.map((option, idx) => {
                                      return (
                                        // TODO: remove eslint disables, see issue rehacat_frontend#297
                                        // eslint-disable-next-line react/jsx-key
                                        <div className="radio-col">
                                          {/* TODO: remove eslint disables, see issue rehacat_frontend#297 */}
                                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                          <label className="radio-custom">
                                            <Form.Check
                                              type="radio"
                                              /*className="btn-lg horizontal-radio-enforced"*/
                                              inline
                                              name={String(item.id)}
                                              data-name={option.label}
                                              key={idx}
                                              id={option.id}
                                              label={option.label}
                                              value={option.value}
                                              defaultChecked={false}
                                              checked={
                                                itemResponses[index] &&
                                                itemResponses[index].value ===
                                                  option.value
                                              }
                                              onChange={(event) => {
                                                handleRadioBtn(
                                                  event,
                                                  item,
                                                  index,
                                                );
                                              }}
                                            />
                                          </label>
                                        </div>
                                      );
                                    })}
                                </div>
                              );

                            case "QUESTION_VERTICAL_RADIO":
                              return (
                                <div
                                  className={`radio-vertical${
                                    index === highlightItemIndex
                                      ? " question-highlighted"
                                      : ""
                                  }`}
                                >
                                  {item.response_options &&
                                    item.response_options.length > 0 &&
                                    item.response_options.map((option, idx) => {
                                      return (
                                        // TODO: remove eslint disables, see issue rehacat_frontend#297
                                        // eslint-disable-next-line react/jsx-key
                                        <div className="radio-col" key={idx}>
                                          {/* TODO: remove eslint disables, see issue rehacat_frontend#335 */}
                                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                          <label className="radio-custom">
                                            <Form.Check
                                              type="radio"
                                              /*className="btn-lg"*/
                                              inline
                                              name={String(item.id)}
                                              data-name={option.label}
                                              key={idx}
                                              id={option.id}
                                              label={option.label}
                                              defaultChecked={false}
                                              checked={
                                                itemResponses[index] &&
                                                itemResponses[index].value ===
                                                  option.value
                                              }
                                              value={option.value}
                                              onChange={(event) => {
                                                handleRadioBtn(
                                                  event,
                                                  item,
                                                  index,
                                                );
                                              }}
                                            />
                                          </label>
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            case "QUESTION_SELECT":
                              return (
                                <div
                                  className={`${
                                    index === highlightItemIndex
                                      ? " question-highlighted"
                                      : ""
                                  }`}
                                >
                                  <Form.Select
                                    onChange={(event) => {
                                      handleQuestionSelect(event, item, index);
                                    }}
                                  >
                                    <option>Auswählen...</option>
                                    {item.response_options &&
                                      item.response_options.length > 0 &&
                                      item.response_options.map(
                                        (option, idx) => {
                                          return (
                                            <option
                                              key={idx}
                                              value={option.value}
                                              data-name={option.label}
                                            >
                                              {option.label}
                                            </option>
                                          );
                                        },
                                      )}
                                  </Form.Select>
                                </div>
                              );
                            case "QUESTION_RANGE_INDICATOR":
                              return (
                                <div className="temperature-input-container">
                                  <div>
                                    <input
                                      className={`temperature-form-element`}
                                      type="number"
                                      min="0"
                                      max="100"
                                      onChange={(event) => {
                                        handleInteger(event, item, index);
                                      }}
                                      onKeyDown={(event) => {
                                        handleInteger(event, item, index);
                                      }}
                                    />
                                  </div>
                                  <img
                                    src={temperatureScale}
                                    alt="Temperature Scale"
                                    width="100px"
                                    height="auto"
                                  />
                                </div>
                              );
                            case "QUESTION_TEXT":
                            case "QUESTION_TEXT_OPTIONAL":
                              return (
                                <Form.Control
                                  type="text"
                                  className={`${
                                    index === highlightItemIndex
                                      ? " question-highlighted"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    handleTextInput(event, item, index);
                                  }}
                                />
                              );
                            case "QUESTION_DATE":
                              return (
                                <DatePicker
                                  className={`form-control${
                                    index === highlightItemIndex
                                      ? " question-highlighted"
                                      : ""
                                  }`}
                                  type="date"
                                  dateFormat="yyyy-MM-dd"
                                  selected={date}
                                  value={date}
                                  onChange={(date) => {
                                    handleDate(date, item, index);
                                  }}
                                />
                              );
                            case "QUESTION_INTEGER":
                              return (
                                <Form.Control
                                  className={`survey-form-element${
                                    index === highlightItemIndex
                                      ? " question-highlighted"
                                      : ""
                                  }`}
                                  type="number"
                                  min="0"
                                  max="2147483647"
                                  pattern="[0-9]*"
                                  onChange={(event) => {
                                    handleInteger(event, item, index);
                                  }}
                                  onKeyDown={(event) => {
                                    handleInteger(event, item, index);
                                  }}
                                />
                              );
                            case "QUESTION_DOUBLE_POSITIVE":
                              return (
                                <Form.Control
                                  type="text"
                                  className={`${
                                    index === highlightItemIndex
                                      ? " question-highlighted"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    handlePosDouble(event, item, index);
                                  }}
                                />
                              );
                            case "EMAIL_NOTIFICATION_PRE":
                            case "EMAIL_NOTIFICATION_INTERMEDIATE":
                            case "EMAIL_NOTIFICATION_POST":
                            case "EMAIL_NOTIFICATION_FOLLOW_UP":
                              return (
                                <>
                                  <Form.Control
                                    type="email"
                                    placeholder="max@mustermann.de"
                                    className={`${
                                      index === highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                    onChange={(event) => {
                                      handleTextInput(event, item, index);
                                    }}
                                  />
                                  <Button
                                    onClick={(event) => {
                                      handleAtSymbol(event);
                                    }}
                                  >
                                    @ einfügen
                                  </Button>
                                </>
                              );
                            case "QUESTION_TEXT_AREA":
                            case "QUESTION_TEXT_AREA_OPTIONAL":
                              return (
                                <Form.Control
                                  as="textarea"
                                  className={`${
                                    index === highlightItemIndex
                                      ? " question-highlighted"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    handleTextInput(event, item, index);
                                  }}
                                />
                              );
                            default:
                              return <div></div>;
                          }
                        })()}
                      </Form.Group>
                    );
                  })}
                <div className="survey-footer-text">
                  {surveyDimension?.footer}
                </div>
                <OverlayTrigger
                  overlay={
                    <Tooltip className="tooltip">
                      Sie können nicht zur vorherigen Frage zurückkehren.
                    </Tooltip>
                  }
                >
                  <Button
                    aria-describedby="tooltipBack"
                    variant="outline"
                    className="custom-btn"
                  >
                    Zurück
                  </Button>
                </OverlayTrigger>
                <div hidden id="tooltipBack">
                  Sie können nicht zur vorherigen Frage zurückkehren.
                </div>
                <Button
                  disabled={
                    // items.length === 0 ||
                    sendingSurvey ? true : false
                  }
                  type="submit"
                  className="custom-btn"
                  variant="primary"
                >
                  {" "}
                  {sendingSurvey ? (
                    <Spinner
                      animation="border"
                      role="status"
                      // className="loadingSpinner"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Weiter"
                  )}
                </Button>
              </Form>
              <Modal backdrop="static" show={waitModalOpen} onHide={closeModal}>
                <ModalBody>
                  Ihre Daten werden übermittelt. Bitte haben Sie einen Moment
                  Geduld.
                  <ProgressBar animated now={progressBarStatus} />
                </ModalBody>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RunSurvey;
