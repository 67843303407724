import React, { Component, ReactElement, createContext } from "react";
import { Survey, SurveyTimeWindow } from "./types/surveyTypes";
import { ReportEntry } from "./types/reportTypes";
import { CntStws, Stws } from "./types/commonTypes";

export interface PatientData {
  content: string;
  stws: Stws[];
  items: Survey; //for legacy reasons this does currently not contain the items but the whole survey! Needs a refactor
  reports: ReportEntry[];
  currentSTW: SurveyTimeWindow;
  today: string;
  cntStws: CntStws;
  modalOpen: boolean;
  remainingTime: number;
  value: string;
}
export interface ReportsData {
  startedTime?: string;
  surveySessionId?: number;
  stwType?: string;
}

export interface AccordionData {
  accordionCurrent: string;
  accordionInformation: string;
  accordionSurvey: string;
  accordionReport: string;
  accordionMyPatients: string;
  accordionMyClinics: string;
  accordionPatients: string;
  accordionEmployees: string;
  accordionClinic: string;
}

export interface AppContextState {
  accordionData: AccordionData;
  reportsData: ReportsData;
  patientData?: PatientData;
  clinicianData?: PatientData;
  adminData?: PatientData;
}

export interface AppContextType {
  state: AppContextState;
  setState: (state: AppContextState) => void;
  resetAccordionState: () => void;
  handleAccordion: (params: { input?: string; userType?: string }) => void;
}

export const initialState: AppContextState = {
  accordionData: {
    accordionCurrent: "-1",
    accordionInformation: "-1",
    accordionSurvey: "-1",
    accordionReport: "-1",
    accordionMyPatients: "-1",
    accordionMyClinics: "-1",
    accordionPatients: "-1",
    accordionEmployees: "-1",
    accordionClinic: "-1",
  },
  reportsData: {},
};

// Create the initial context with default values
export const AppContext = createContext<AppContextType>({
  state: initialState,
  setState: () => {},
  resetAccordionState: () => {},
  handleAccordion: () => {},
});

export const withAppContext = (
  Component: React.ComponentType<
    NonNullable<unknown> & { appContext: AppContextType }
  >,
) => {
  return class WithAppContext extends React.Component<NonNullable<unknown>> {
    render() {
      return (
        <AppContext.Consumer>
          {(appContext) => (
            <Component {...this.props} appContext={appContext} />
          )}
        </AppContext.Consumer>
      );
    }
  };
};

// Define the authentication provider component
class AppProvider extends Component<
  NonNullable<{ history: History; children: ReactElement }>,
  AppContextState
> {
  constructor(
    props: NonNullable<{ history: History; children: ReactElement }>,
  ) {
    super(props);
    this.state = { ...initialState };
    this.resetAccordionState = this.resetAccordionState.bind(this);
    this.handleAccordion = this.handleAccordion.bind(this);
  }

  resetAccordionState = () => {
    this.setState({
      accordionData: {
        ...initialState.accordionData,
      },
    });
  };

  handleAccordion = (params: { input?: string; userType?: string }) => {
    const { input, userType } = params ?? {};
    const pushHistoryState = (path: string) => {
      if (this.props?.history) {
        this.props.history.pushState(null, "", `/${path}`);
      }
    };
    const newState: AccordionData = {
      ...initialState.accordionData,
    };
    switch (userType) {
      case "patient":
        switch (input) {
          case "current":
            newState.accordionCurrent =
              this.state.accordionData.accordionCurrent === "-1" ? "0" : "-1";
            break;
          case "info":
            newState.accordionInformation =
              this.state.accordionData.accordionInformation === "-1"
                ? "1"
                : "-1";
            break;
          case "survey":
            newState.accordionSurvey =
              this.state.accordionData.accordionSurvey === "-1" ? "2" : "-1";
            break;
          case "report":
            newState.accordionReport =
              this.state.accordionData.accordionReport === "-1" ? "3" : "-1";
            break;
          case "clinics":
            newState.accordionMyClinics =
              this.state.accordionData.accordionMyClinics === "-1" ? "4" : "-1";
            break;
          default:
            break;
        }
        this.setState({
          accordionData: {
            ...newState,
          },
        });
        if (input !== "general") {
          pushHistoryState("patient");
        }

        break;
      case "clinician":
        switch (input) {
          case "myPatients":
            newState.accordionPatients = "0";
            break;
          case "clinics":
            newState.accordionClinic = "1";
            break;
          default:
            break;
        }
        this.setState({
          accordionData: {
            ...newState,
          },
        });
        if (input !== "info") {
          pushHistoryState("clinician");
        }

        break;
      case "admin":
        switch (input) {
          case "patients":
            newState.accordionPatients = "0";
            break;
          case "employees":
            newState.accordionEmployees = "1";
            break;
          case "clinic":
            newState.accordionClinic = "2";
            break;
          default:
            break;
        }
        this.setState({
          accordionData: {
            ...newState,
          },
        });
        if (input !== "info") {
          pushHistoryState("admin");
        }
        break;
      default:
        break;
    }
  };

  //reload
  componentDidMount(): void {}

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          setState: this.setState,
          resetAccordionState: this.resetAccordionState,
          handleAccordion: this.handleAccordion,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppProvider;
