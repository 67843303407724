import {
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import React from "react";
import { Accordion } from "react-bootstrap";
import NavigationBar from "../navigation/NavigationBar";
import {
  clinicianGetPatients,
  Patient,
  SurveyTimeWindow,
  ReportEntry,
  Diagnose,
  getDiagnoses,
} from "../../services/getFunctions";
import SidebarClinician from "../sidebars/SidebarClinician";
import ErrorBoundary from "../../ErrorBoundary";
import Footer from "../Footer";
import TimeoutSession from "../../helper/TimeoutSession";
import {
  showAlertModal,
  reportGetDiagnosesListError,
  reportEditPatientError,
  reportRegistPatientError,
} from "../../helper/alertBoxes";
import {
  clinicianAssignClinician,
  editPatient,
} from "../../services/postFunctions";
import { RouteComponentProps } from "react-router-dom";
import AuthService from "../../services/AuthService";
import DatePicker from "react-datepicker";
import { GenderList } from "../componentsClinicAdmin/HomeClinicAdmin";
import SessionEndReason from "../sharedComponents/SessionEndReason";
import SessionCounts from "../sharedComponents/SessionCounts";
import { Labels, PatientLabels } from "../sharedComponents/PatientLabels";
import SurveyStatusModal from "../sharedComponents/SurveyStatusModal";
import ResultStateModal from "../sharedComponents/ResultStateModal";
import { Typeahead } from "react-bootstrap-typeahead";
import LoadingModal from "../sharedComponents/LoadingModal";
import PatientTable from "../sharedComponents/PatientTable";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AuthContextType, withAuthContext } from "../../AuthProvider";

interface SeveritiesParallel {
  patient_code: string;
  stw_type: string;
  survey_session_id: number;
  result: string;
}

/**
 * @patients: the raw data fetched from backend.
 * @patientsDisplay: adapted from patients, added with JSX.Element to display patients in table.
 */
interface HomeClinicianState {
  username: string;
  clinic_id: string;
  clinic_name: string;
  user_type: string;
  patients: Patient[];
  currentPatient: Patient;
  reports: ReportEntry[];
  patient_id: string;
  severitiesParallels: SeveritiesParallel[];
  stws: SurveyTimeWindow[];
  code: string;
  severity: string[];
  severities: string[];
  accordionMyPatients: string;
  accordionMyClinics: string;

  today: string;
  survey_session_id: string;
  modalLoadingOpen: boolean;
  modalSurveyStateOpen: boolean;
  modalResultOpen: boolean;
  patient_code: string;
  isLoading: boolean;
  modalAssignClinicianOpen: boolean;
  progressBarStatus: number;
  clinician_from_username: string;
  clinician_from_id: string;
  clinician_to: string;
  value: string;
  clinician: string;
  diagnoses: Diagnose[];
  diagnosesPatient: Diagnose[];
  modalDiagnosisOpen: boolean;
  modalEditPatientOpen: boolean;
  gender: string;
  editBirthday: Date;
  editAdmission: Date;
  editDischarge: Date;
  clinician_id: string;
  clinician_username: string;
  modalEditClinicianOpen: boolean;
  editPatientButton: string;
  admission: Date;
  discharge: Date;
  birthday: Date;
  modalOpen: boolean;
  genderList: GenderList[];
  labels: Labels;
}
/*type LocationProps = {
  state: HomeClinicianState
};
 */
interface HomeClinicianProps {
  title: string;

  history: RouteComponentProps["history"];
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  location: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  authContext: AuthContextType;
}

class HomeClinician extends React.Component<
  HomeClinicianProps,
  HomeClinicianState
> {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "clinician",
      patients: [],
      currentPatient: {
        viewed: "",
        duplicate: false,
        date_of_birth: "",
        admission: "",
        discharge: "",
        code: "",
        clinician_username: "",
        created: "",
        clinician_id: "",
        diagnoses: { diagnosis: [] },
        gender: "", //maybe reduce to the possible values
        id: "",
        survey_statuses: [],
        survey_time_windows: [],
        labels: [],
      },
      clinic_id: "",
      clinic_name: "",
      username: "",
      reports: [],
      severitiesParallels: [],
      stws: [],
      patient_code: "",
      severity: [],
      patient_id: "",
      severities: [],
      code: "",
      clinician_from_id: "",
      accordionMyPatients: "-1",
      accordionMyClinics: "-1",

      today: new Date().toISOString().split("T")[0],
      survey_session_id: "",
      modalLoadingOpen: true,
      modalSurveyStateOpen: false,
      modalResultOpen: false,
      isLoading: true,
      modalAssignClinicianOpen: false,
      progressBarStatus: 0,

      clinician_to: "",
      value: "",
      clinician: "",
      clinician_from_username: "",
      diagnoses: [],
      diagnosesPatient: [],
      modalDiagnosisOpen: false,
      modalEditPatientOpen: false,
      gender: "",
      editBirthday: new Date(),
      editAdmission: new Date(),
      editDischarge: new Date(),
      clinician_id: "",
      clinician_username: "",
      modalEditClinicianOpen: false,
      editPatientButton: "",
      admission: new Date(),
      discharge: new Date(),
      birthday: new Date("01/01/1990"),
      modalOpen: false,
      genderList: [
        { value: "FEMALE", name: "Weiblich" },
        { value: "MALE", name: "Männlich" },
        { value: "DIVERSE", name: "Divers" },
      ],
      labels: [],
    };
    this.handleDiagnosis = this.handleDiagnosis.bind(this);
  }

  /**Method for loading the patient list from backend API. */
  componentDidMount() {
    document.title = this.props.title;
    const { state: authState } = this.props.authContext;
    this.openLoadingModal();
    if (this.props.location.state === undefined) {
      console.log("Props Location Fail");
      ErrorBoundary.getDerivedStateFromError();
    } else {
      this.setState({
        clinic_id: authState.userInfo?.clinicId ?? "",
        clinic_name: authState.userInfo?.clinicName ?? "",
        username: authState.userInfo?.userName ?? "",
      });
      if (this.props.location.state.accordionMyClinics) {
        this.setState({
          accordionMyClinics: this.props.location.state.accordionMyClinics,
          accordionMyPatients: this.props.location.state.accordionMyPatients,
        });
      }
    }

    try {
      clinicianGetPatients()
        .then((patientList) => {
          console.log("Patientenliste: ", patientList);
          this.setState({ patients: patientList.list_patient_dto }, () => {
            // Once state is set, close the loading modal.
            this.closeLoadingModal();
          });
          console.log("Patient-Length: ", this.state.patients.length);
          console.log("Patients:" + this.state.patients);
          //console.log("Patient-Length: ", this.state.patients.length);
        })
        .catch((error) => {
          console.error("Patientenliste Fail: ", error);
        });
    } catch (error) {
      console.warn("Could not get patients: ", error);
    }
    try {
      getDiagnoses()
        .then((diagnoses) => {
          console.log("Diagnosen-Liste: ", diagnoses);
          this.setState({ diagnoses: diagnoses.diagnosis });
        })
        .catch((error) => {
          reportGetDiagnosesListError(error);
        });
    } catch (error) {
      /*empty*/
    }
  }

  /**Duplicates to be refactored - startpoint*/
  /**When updating, check the same piece in HomeClinicAdmin */
  openDiagnosisModal = () => this.setState({ modalDiagnosisOpen: true });
  closeDiagnosisModal = () => this.setState({ modalDiagnosisOpen: false });
  closeEditPatientModal = () =>
    this.setState({ modalEditPatientOpen: false, code: "", gender: "" });
  openModal = () => this.setState({ modalOpen: true });
  closeModal = () =>
    this.setState({
      modalOpen: false,
      code: "",
      value: "",
      gender: "",
      diagnosesPatient: [],
      clinician: "",
      birthday: new Date("01/01/1990"),
      admission: new Date(),
      discharge: new Date(),
    });

  /**Method for handle patients diagnoses.
   * Each diagnoses that is selected is written to the diagnosesPatient list.
   * If no diagnosis is selected, an alert is displayed.
   */
  handleEditDiagnosesList = (event) => {
    event.preventDefault();
    console.log("Event: ", event);
    const diagnoses: Diagnose[] = [];
    for (let i = 0; i < event.target.length; i++) {
      console.log("Event an der Stelle ", i, event.target[i]);
      if (
        event.target[i].type === "checkbox" &&
        event.target[i].checked === true
      ) {
        console.log("True: ", event.target[i]);
        diagnoses.push({
          description: event.target[i].dataset.name,
          id: event.target[i].value,
        });
      }
    }
    console.log("Liste: ", diagnoses);
    if (diagnoses.length === 0) {
      showAlertModal("Sie müssen mindestens eine Diagnose wählen.");
    } else {
      this.setState({ diagnosesPatient: diagnoses });
      this.closeDiagnosisModal();
    }
  };

  /**Set modalEditPatientOpen on true, if the modal is opened.
   * Set the actual states of the selected patient.
   */
  openEditPatientModal = (event) => {
    event.preventDefault();
    const code = event.currentTarget.id;
    let patient;
    for (let i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].code === code) {
        patient = this.state.patients[i];
      }
    }
    const newBirthday = new Date(patient.date_of_birth);
    const newAdmission = new Date(patient.admission);
    const newDischarge = new Date(patient.discharge);

    let gender = patient.gender;
    if (patient.gender === "Weiblich") {
      gender = "FEMALE";
    } else if (patient.gender === "Männlich") {
      gender = "MALE";
    } else if (patient.gender === "Divers") {
      gender = "DIVERSE";
    }

    this.setState({
      modalEditPatientOpen: true,
      editBirthday: newBirthday,
      editAdmission: newAdmission,
      editDischarge: newDischarge,
      clinician_id: patient.clinician_id,
      clinician_username: patient.clinician_username,
      diagnosesPatient: patient.diagnoses,
      patient_id: patient.id,
      gender: gender,
      code: patient.code,
      labels: patient.labels,
    });

    console.info("Diagnose(n): ", patient.diagnoses);
  };

  closeEditClinicianModal = () =>
    this.setState({
      modalEditClinicianOpen: false,
      clinician_id: "",
      clinician_username: "",
    });

  /**Method to update the edited bithdate state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditBirthdate(date) {
    this.setState({ editBirthday: date });
    console.info("Birthday: ", this.state.editBirthday);
  }

  /**Method for handle  patient edit.
   */
  handleEditPatient = (event) => {
    event.preventDefault();
    if (this.state.editDischarge <= this.state.editAdmission) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      let date_of_birth = this.state.editBirthday.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let [day, month, year] = date_of_birth.split(".");
      date_of_birth = [year, month, day].join("-");
      let admission = this.state.editAdmission.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      [day, month, year] = admission.split(".");
      admission = [year, month, day].join("-");
      let discharge = this.state.editDischarge.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      [day, month, year] = discharge.split(".");
      discharge = [year, month, day].join("-");
      const patientData = {
        date_of_birth: date_of_birth,
        admission: admission,
        discharge: discharge,
        clinician_id: this.state.clinician_id,
        clinician_username: this.state.clinician_username,
        diagnoses: this.state.diagnosesPatient,
        id: this.state.patient_id,
        gender: this.state.gender,
        code: this.state.code,
        created: "1990-01-01T00:00:00", //the backend wants this but the value will not be changed
        labels: this.state.labels,
      };

      console.log("JSON: ", patientData);

      try {
        editPatient(patientData)
          .then((response) => {
            console.log("JSON: ", patientData);
            console.log("Patient bearbeitet:", response.status);
            this.closeEditPatientModal();
            this.componentDidMount();
          })
          .catch((error) => {
            reportEditPatientError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**Method to update the gender state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the selected gender
   */
  handleGender(text) {
    if (text.target.value === "Auswählen...") {
      this.setState({ gender: "" });
    } else {
      console.log("Gender: ", text.target.value);
      this.setState({ gender: text.target.value });
    }
  }

  /**Method to update the diagnosis state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the slected diagnosis
   */
  handleDiagnosis(items: Diagnose[]) {
    if (items && Array.isArray(items) && items.length) {
      this.setState({
        diagnosesPatient: items.filter(
          (item, i, arr) => arr.findIndex((t) => t.id === item.id) === i,
        ),
      });
    } else {
      this.setState({ diagnosesPatient: [] });
    }
  }

  /**Method to update the edited admission date state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditAdmissionDate(date) {
    showAlertModal(
      "Achtung: Durch das Ändern von Aufnahme- oder Entlassdatum können bisherige Befragungen ungültig werden.",
    );
    this.setState({ editAdmission: date });
  }

  /**Method to update the edited discharge date state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditDischargeDate(date) {
    if (date <= this.state.editAdmission) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      showAlertModal(
        "Achtung: Durch das Ändern von Aufnahme- oder Entlassdatum können bisherige Befragungen ungültig werden.",
      );
      this.setState({ editDischarge: date });
    }
  }

  /**
   * Method for handle the patient regist.
   * The input data are stringified to JSON.
   * Then send JSON to backend API.
   */
  handleSubmit = (event) => {
    event.preventDefault();
    console.log("Button: ", this.state.editPatientButton);
    console.log(
      "Mitarbeitername: ",
      this.state.clinician,
      "Code: ",
      this.state.code,
      "Geschlecht: ",
      this.state.gender,
      "Diagnose: ",
      this.state.diagnosesPatient,
    );

    let admission = this.state.admission.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    let [day, month, year] = admission.split(".");
    admission = [year, month, day].join("-");
    let discharge = this.state.discharge.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    [day, month, year] = discharge.split(".");
    discharge = [year, month, day].join("-");
    let date_of_birth = this.state.birthday.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    [day, month, year] = date_of_birth.split(".");
    date_of_birth = [year, month, day].join("-");

    console.log(
      "Aufnahmedatum: ",
      this.state.admission.toISOString().split("T")[0],
      ", Entlassdatum: ",
      this.state.discharge.toISOString().split("T")[0],
    );
    if (this.state.code === "") {
      showAlertModal("Sie müssen eine SysDok-ID angeben.");
    } else if (this.state.gender === "") {
      showAlertModal("Sie müssen ein Geschlecht auswählen.");
    } else if (this.state.clinician === "") {
      showAlertModal("Sie müssen eine*n Mitarbeitende*n auswählen.");
    } else if (this.state.diagnosesPatient?.length === 0) {
      showAlertModal("Sie müssen eine Diagnose auswählen.");
    } else if (
      this.state.discharge.toISOString().split("T")[0] <=
      this.state.admission.toISOString().split("T")[0]
    ) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      const json = JSON.stringify({
        admission: admission,
        clinician_username: this.state.clinician,
        code: this.state.code,
        day_of_birth: date_of_birth,
        // NOTE: This only works as long as the registration modal uses a single select menu!
        diagnosis_id: this.state.diagnosesPatient[0].id,
        discharge: discharge,
        gender: this.state.gender,
      });

      console.log("JSON: ", json);

      try {
        AuthService.regist(json, "/api/auth/patients/registration")
          .then((response) => {
            console.log("JSON: ", json);
            console.log("Response:", response.status);
            // if (event.nativeEvent.submitter.name === "btn1") {
            if (this.state.editPatientButton === "btn1") {
              this.closeModal();
            } else {
              this.openModal();
            }

            this.setState({
              code: "",
              value: "",
              gender: "",
              diagnosesPatient: [],
              clinician: "",
              birthday: new Date("01/01/1990"),
              admission: new Date(),
              discharge: new Date(),
            });

            this.componentDidMount();
          })
          .catch((error) => {
            reportRegistPatientError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**Handle add labels */
  setLabels = (labels) => {
    this.setState({ labels: labels });
  };
  /**Duplicates to be refactored - endpoint*/

  /**Set modalLoadingOpen on true, if the modal is opened. */
  openLoadingModal = () => {
    this.setState({ modalLoadingOpen: true });
  };

  /**Set modalLoadingOpen on false, if the modal is closed. */
  closeLoadingModal = () => {
    console.log("LoadingModal schließen");
    this.setState({ modalLoadingOpen: false });
  };

  /**Set modalAssignClinicianOpen on true, if the modal is opened.
   * Set the current patient states.
   */
  openAssignClinicianModal = (event) => {
    event.preventDefault();
    console.log("Patient mit Index: ", event.target.id);
    const code = event.currentTarget.id;
    let patient;
    // var index = event.target.id;
    for (let i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].code === code) {
        patient = this.state.patients[i];
      }
    }
    console.log("Patient: ", patient);
    // var patient = this.state.patients[index];
    this.setState({
      modalAssignClinicianOpen: true,
      patient_id: patient.id,
      code: patient.code,
      clinician_from_id: patient.clinician_id,
      clinician_from_username: patient.clinician_username,
    });
  };

  /**
   * open the modal and load the data, change the current patient to clicked patient
   * and load the reports for the current patient.
   */
  openSurveyStateModal = (event) => {
    event.preventDefault();
    const id = event.target.id;
    console.log("event id: " + id);
    console.log("Patients for finding: " + this.state.patients);
    const patient = this.state.patients.find((p) => p.id === id);

    if (patient) {
      if (this.state.currentPatient.id !== id) {
        this.setState({
          currentPatient: patient,
        });
      }
    } else {
      console.error("Patient with id ${id} not found.");
    }

    this.setState({
      modalSurveyStateOpen: true,
      modalResultOpen: false,
    });
  };

  openResultModal = (event) => {
    event.preventDefault();
    const id = event.target.id;
    const patient = this.state.patients.find((p) => p.id === id);

    this.setState({
      modalResultOpen: true,
      modalSurveyStateOpen: false,
    });

    if (patient) {
      if (this.state.currentPatient.id !== id) {
        this.setState({
          currentPatient: patient,
          patient_code: patient.code,
        });
      }
    } else {
      console.error("Patient with id ${id} not found.");
    }
  };

  closeSurveyStateModal = () =>
    this.setState({
      modalSurveyStateOpen: false,
    });
  closeResultModal = () =>
    this.setState({
      modalResultOpen: false,
    });

  closeAssignClinicianModal = () =>
    this.setState({ modalAssignClinicianOpen: false, code: "" });

  /**Method to update the clinician state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the selected clinician
   */
  handleClinician(text) {
    this.setState({ clinician: text.target.value });
  }

  /**Method for handle clinician assign.
   * The input data are stringified to JSON.
   * Then send JSON to backend api.
   */
  handleClinicianAssign = (event) => {
    event.preventDefault();

    const assignmentData = {
      patient_id: this.state.patient_id,
      clinician_from: this.state.clinician_from_id,
      clinician_to: this.state.clinician,
    };

    try {
      clinicianAssignClinician(assignmentData)
        .then(() => {
          showAlertModal(
            "Der*die Patient*in wurde erfolgreich einem neuen Mitarbeitenden zugewiesen.",
          );
          this.closeAssignClinicianModal();
          this.componentDidMount();
        })
        .catch((error) => {
          showAlertModal(
            "Irgendetwas ist schief gelaufen. Der*die Patient*in wurde keinem neuen Mitarbeitenden zugewiesen.",
          );
          console.error("Assign Clinician Fail: ", error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  handleAccordion = (input) => {
    if (input === "myPatients") {
      this.state.accordionMyPatients === "-1"
        ? this.setState({ accordionMyPatients: "0" })
        : this.setState({ accordionMyPatients: "-1" });
      this.setState({ accordionMyClinics: "-1" });
    } else if (input === "clinics") {
      this.state.accordionMyClinics === "-1"
        ? this.setState({ accordionMyClinics: "1" })
        : this.setState({ accordionMyClinics: "-1" });
      this.setState({ accordionMyPatients: "-1" });
    } else if (input === "info") {
      this.setState({ accordionMyPatients: "-1", accordionMyClinics: "-1" });
    } else if (input === "closeAll") {
      this.setState({
        accordionMyPatients: "-1",
        accordionMyClinics: "-1",
      });
    }
  };

  /**React render method. */
  render() {
    const { state: authState } = this.props.authContext;
    return (
      <ErrorBoundary>
        <div className="header-fixed" id="body">
          <TimeoutSession />
          <div className="skip-link-div">
            <a className="skip-link" href="#main-content">
              Direkt zum Inhalt springen
            </a>
          </div>
          <NavigationBar />
          <div className="page-wrapper-new">
            <div className="wrapper">
              <div className="skip-sidebar-div">
                <a className="skip-sidebar" href="#main-content">
                  Navigation überspringen
                </a>
              </div>
              <SidebarClinician handleAccordion={this.handleAccordion} />
              <div className="content-wrapper">
                <div className="content" id="main-content">
                  <Accordion activeKey={this.state.accordionMyPatients}>
                    <Accordion.Item eventKey="0" className="card-default">
                      <Accordion.Header
                        onClick={() => this.handleAccordion("myPatients")}
                      >
                        Meine Patient*innen
                      </Accordion.Header>
                      <Accordion.Body>
                        <PatientTable
                          patients={this.state.patients}
                          userType={this.state.user_type}
                          openEditPatientModal={this.openEditPatientModal}
                          openAssignClinicianModal={
                            this.openAssignClinicianModal
                          }
                          openResultModal={this.openResultModal}
                          openSurveyStateModal={this.openSurveyStateModal}
                          closeLoadingModal={this.closeLoadingModal}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion
                    defaultActiveKey="0"
                    activeKey={this.state.accordionMyClinics}
                    onClick={() => this.handleAccordion("clinics")}
                  >
                    <Accordion.Item eventKey="1" className="card-default">
                      <Accordion.Header>Klinik</Accordion.Header>
                      <Accordion.Body>
                        Ihre Klinik ist die{" "}
                        <em>{authState.userInfo?.clinicName}</em>.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  {this.state.modalSurveyStateOpen && (
                    <div>
                      <SurveyStatusModal
                        currentPatient={this.state.currentPatient}
                        modalSurveyStateOpen={this.state.modalSurveyStateOpen}
                        closeSurveyStateModal={this.closeSurveyStateModal}
                      />
                    </div>
                  )}

                  {this.state.modalResultOpen && (
                    <div>
                      <ResultStateModal
                        currentPatient={this.state.currentPatient}
                        modalResultOpen={this.state.modalResultOpen}
                        closeResultModal={this.closeResultModal}
                        clinic_id={this.state.value}
                        clinic_name={this.state.clinic_name}
                        username={this.state.username}
                      />
                    </div>
                  )}

                  <Modal
                    backdrop="static"
                    show={this.state.modalEditPatientOpen}
                    onHide={this.closeEditPatientModal}
                  >
                    <ModalHeader closeButton>
                      <ModalTitle>
                        Patient*in <em>{this.state.code}</em> bearbeiten
                      </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                      <Form noValidate onSubmit={this.handleEditPatient}>
                        <Row className="mb-3">
                          <Col>
                            <Form.Group controlId="formGridBirthday">
                              <Form.Label>Geburtsdatum</Form.Label>
                              <DatePicker
                                className="form-control"
                                type="date"
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.editBirthday}
                                value={this.state.editBirthday}
                                onChange={(date) => {
                                  this.handleEditBirthdate(date);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Geschlecht</Form.Label>
                              <Form.Select
                                className="form-control"
                                required
                                value={this.state.gender}
                                onChange={(event) => {
                                  this.handleGender(event);
                                }}
                              >
                                {this.state.gender === "MALE" ? (
                                  <option>Männlich</option>
                                ) : this.state.gender === "FEMALE" ? (
                                  <option>Weiblich</option>
                                ) : (
                                  <option>Divers</option>
                                )}

                                {this.state.genderList &&
                                  this.state.genderList.length > 0 &&
                                  this.state.genderList.map((gender) => {
                                    return gender.value !==
                                      this.state.gender ? (
                                      <option
                                        key={gender.value}
                                        value={gender.value}
                                      >
                                        {gender.name}
                                      </option>
                                    ) : null;
                                  })}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Diagnose(n)</Form.Label>
                              <Typeahead
                                id="basic-typeahead-example"
                                selected={
                                  this.state.diagnosesPatient
                                  //{Array.isArray(this.state.diagnosis)
                                  //  ? this.state.diagnosis[0]
                                  //  : null
                                }
                                multiple={true}
                                size="sm"
                                align={"left"}
                                labelKey="description"
                                options={this.state.diagnoses as Diagnose[]}
                                placeholder="Auswählen..."
                                clearButton={false}
                                onChange={(event) => {
                                  this.handleDiagnosis(event as Diagnose[]);
                                }}
                                maxResults={1000}
                                renderMenuItemChildren={(option) => (
                                  <div>
                                    <span>
                                      {(option as Diagnose).id} -{" "}
                                      {(option as Diagnose).description}
                                    </span>
                                  </div>
                                )}
                                filterBy={(option, searchText) => {
                                  return (
                                    (option as Diagnose).id
                                      .toLowerCase()
                                      .includes(
                                        searchText.text.toLowerCase(),
                                      ) ||
                                    (option as Diagnose).description
                                      .toLowerCase()
                                      .includes(searchText.text.toLowerCase())
                                  );
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <Form.Group>
                              <Form.Label>Aufnahmedatum ändern</Form.Label>
                              <DatePicker
                                className="form-control"
                                type="date"
                                selected={this.state.editAdmission}
                                onChange={(date) => {
                                  this.handleEditAdmissionDate(date);
                                }}
                                dateFormat="dd/MM/yyyy"
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Entlassdatum ändern</Form.Label>
                              <DatePicker
                                className="form-control"
                                type="date"
                                selected={this.state.editDischarge}
                                minDate={this.state.editAdmission}
                                onChange={(date) => {
                                  this.handleEditDischargeDate(date);
                                }}
                                dateFormat="dd/MM/yyyy"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <PatientLabels
                          labels={this.state.labels}
                          setLabels={this.setLabels}
                        />
                        <hr />
                        <Row className="mb-3">
                          <h5>Angaben zu Therapieende:</h5>
                        </Row>
                        <SessionCounts
                          labels={this.state.labels}
                          setLabels={this.setLabels}
                        />
                        <SessionEndReason
                          labels={this.state.labels}
                          setLabels={this.setLabels}
                        />
                        <Button type="submit">Speichern</Button>
                      </Form>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        variant="danger"
                        onClick={this.closeEditPatientModal}
                      >
                        Abbrechen
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    backdrop="static"
                    size="lg"
                    show={this.state.modalDiagnosisOpen}
                    onHide={this.closeDiagnosisModal}
                  >
                    <ModalHeader>Wählen Sie die Diagnose(n) aus:</ModalHeader>
                    <ModalBody>
                      <Form noValidate onSubmit={this.handleEditDiagnosesList}>
                        {this.state.diagnoses &&
                          this.state.diagnoses.length > 0 &&
                          this.state.diagnoses.map((diagnosis) => {
                            return this.state.diagnosesPatient.some(
                              (diagPatient) => {
                                return (
                                  diagnosis.description ===
                                  diagPatient.description
                                );
                              },
                            ) ? (
                              <Form.Check
                                key={diagnosis.id}
                                type="checkbox"
                                id={diagnosis.id}
                                data-name={diagnosis.description}
                                label={diagnosis.description}
                                value={diagnosis.id}
                                defaultChecked
                              />
                            ) : (
                              <Form.Check
                                key={diagnosis.id}
                                type="checkbox"
                                id={diagnosis.id}
                                data-name={diagnosis.description}
                                label={diagnosis.description}
                                value={diagnosis.id}
                              />
                            );
                          })}
                        <Button type="submit">Speichern</Button>
                      </Form>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        variant="danger"
                        onClick={this.closeDiagnosisModal}
                      >
                        Abbrechen
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    backdrop="static"
                    show={this.state.modalAssignClinicianOpen}
                    onHide={this.closeAssignClinicianModal}
                  >
                    <ModalHeader closeButton>
                      <ModalTitle>Anderen Mitarbeitenden zuweisen</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                      <Form noValidate onSubmit={this.handleClinicianAssign}>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="5">
                            Patient*in
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              className="form-control-plain"
                              plaintext
                              readOnly
                              defaultValue={this.state.code}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="5">
                            Mitarbeitende ändern:
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              className="form-control"
                              required
                              type="username"
                              placeholder="Name der Mitarbeitenden"
                              // value={this.state.value}
                              onChange={(text) => {
                                this.handleClinician(text);
                              }}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Button type="submit">Mitarbeitende ändern</Button>
                      </Form>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        variant="danger"
                        onClick={this.closeAssignClinicianModal}
                      >
                        Abbrechen
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <div>
                    <LoadingModal
                      modalLoadingOpen={this.state.modalLoadingOpen}
                      closeLoadingModal={this.closeLoadingModal}
                    />
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
export default withAuthContext(HomeClinician);
