import React, { useEffect, useState } from "react";
import { Clinician, Patient } from "../../services/getFunctions";
import { DropdownButton, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { BiLock, BiBlock } from "react-icons/bi";
import { BsPeople, BsCheckCircle } from "react-icons/bs";
import { CgUserList } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import { MdEdit } from "react-icons/md";

interface ClinicianTableProps {
  clinicians: Clinician[];
  patients: Patient[];
  openEditClinicianModal: (event: React.MouseEvent<HTMLElement>) => void;
  openShowPatientListModal: (event: React.MouseEvent<HTMLElement>) => void;
  openTransferPatientsModal: (event: React.MouseEvent<HTMLElement>) => void;
  disableEnableClinician: (event: React.MouseEvent<HTMLElement>) => void;
  resetPassword: (event: React.MouseEvent<HTMLElement>) => void;
}

const ClinicianTable = ({
  clinicians,
  patients,
  openEditClinicianModal,
  openShowPatientListModal,
  openTransferPatientsModal,
  disableEnableClinician,
  resetPassword,
}: ClinicianTableProps) => {
  const [clinicianTable, setClinicianTable] = useState<Clinician[]>([]);

  useEffect(() => {
    const newClinicianTable = clinicians.map((clinician, index) => ({
      ...clinician,
      password: "******",
      patientCount: patients.filter(
        (patient) => patient.clinician_username === clinician.username,
      ).length,
      edit: (
        <DropdownButton
          key={index}
          variant="outline-secondary"
          id="dropdown-basic-button"
          title={
            <i className="mdi">
              <MdEdit />
            </i>
          }
        >
          <Dropdown.Item id={String(index)} onClick={openEditClinicianModal}>
            <FiEdit /> Mitarbeitenden-ID ändern
          </Dropdown.Item>
          <Dropdown.Item id={String(index)} onClick={resetPassword}>
            <BiLock /> Passwort zurücksetzen
          </Dropdown.Item>
          <Dropdown.Item id={String(index)} onClick={openShowPatientListModal}>
            <CgUserList /> Patient*innenliste anzeigen
          </Dropdown.Item>
          <Dropdown.Item id={String(index)} onClick={openTransferPatientsModal}>
            <BsPeople /> Patient*innen transferieren
          </Dropdown.Item>

          {clinician.enabled ? (
            <Dropdown.Item id={String(index)} onClick={disableEnableClinician}>
              <BiBlock /> Mitarbeitende deaktivieren
            </Dropdown.Item>
          ) : (
            <Dropdown.Item id={String(index)} onClick={disableEnableClinician}>
              <BsCheckCircle /> Mitarbeitende aktivieren
            </Dropdown.Item>
          )}
        </DropdownButton>
      ),
    }));
    setClinicianTable(newClinicianTable);
  }, [clinicians, patients]);

  return (
    <div>
      <BootstrapTable
        keyField="username"
        hover
        bordered
        columns={[
          { dataField: "edit", text: "" },
          {
            dataField: "username",
            text: "Mitarbeitenden-ID",
            sort: true,
          },
          { dataField: "password", text: "Passwort" },
          {
            dataField: "patientCount",
            text: "Zugewiesene Patient*innen",
            sort: true,
          },
        ]}
        data={clinicianTable}
        headerClasses={"tablehead-fixed"}
        rowStyle={(row) => {
          const style = {
            color: "" as string,
          };
          if (row.enabled === false) {
            style.color = "grey";
          }
          return style;
        }}
      />
    </div>
  );
};

export default ClinicianTable;
