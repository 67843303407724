import React from "react";
import NavigationBar from "./navigation/NavigationBar";
import SidebarPatient from "./sidebars/SidebarPatient";
import SidebarAdmin from "./sidebars/SidebarAdmin";
import SidebarClinician from "./sidebars/SidebarClinician";
import Footer from "./Footer";
import { Button } from "react-bootstrap";
import TimeoutSession from "../helper/TimeoutSession";
import {
  AuthContextType,
  AuthContextState,
  withAuthContext,
  initialAuthState,
} from "../AuthProvider";

/**This component contains the content of the impressum */

interface ImpressumProps {
  title: string;
  authContext: AuthContextType;
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
}

interface ImpressumState {
  authState?: AuthContextState;
  accordionCurrent: string;
  accordionInformation: string;
  accordionSurvey: string;
  accordionReport: string;
  accordionMyPatients: string;
  accordionMyClinics: string;
  accordionPatients: string;
  accordionEmployees: string;
  accordionClinic: string;
}

class Impressum extends React.Component<ImpressumProps, ImpressumState> {
  /**Constructor of the Impressum component */
  constructor(props: ImpressumProps) {
    super(props);
    this.state = {
      authState: initialAuthState,
      accordionCurrent: "-1",
      accordionInformation: "-1",
      accordionSurvey: "-1",
      accordionReport: "-1",
      accordionMyPatients: "-1",
      accordionMyClinics: "-1",
      accordionPatients: "-1",
      accordionEmployees: "-1",
      accordionClinic: "-1",
    };
    this.handleAccordion = this.handleAccordion.bind(this);
  }

  /**Checks wheter the user is logged in or not.
   * If the user is logged in, the states would be set.
   */
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = this.props.title;
    const { state: authState } = this.props.authContext;
    this.setState({ authState });
  }

  /**When clicking on sidebar, check which user_type the user has and which menu item is selected.
   * Open on dashboard the right accordion.
   */
  handleAccordion = (input) => {
    const { userInfo } = this.state.authState as AuthContextState;
    switch (userInfo?.userType) {
      case "patient":
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accCurrent = "-1";
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accInfo = "-1";
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accSurvey = "-1";
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accReports = "-1";
        switch (input) {
          case "current":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accCurrent = "0";
            break;
          case "info":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accInfo = "1";
            break;
          case "survey":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accSurvey = "2";
            break;
          case "report":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accReports = "3";
            break;
          default:
            break;
        }
        if (input !== "general") {
          this.props.history.push({
            pathname: "/patients",
          });
        }

        break;
      case "clinician":
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accPatients = "-1";
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accClinics = "-1";
        switch (input) {
          case "myPatients":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accReports = "3";
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accPatients = "0";
            break;
          case "clinics":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accClinics = "1";
            break;
          default:
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/clinician",
          });
        }

        break;
      case "admin":
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accPatient = "-1";
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accEmployees = "-1";
        // TODO: remove eslint disables, see issue rehacat_frontend#335
        // eslint-disable-next-line no-var
        var accClinic = "-1";
        switch (input) {
          case "patients":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accPatient = "0";
            break;
          case "employees":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accEmployees = "1";
            break;
          case "clinic":
            // TODO: remove eslint disables, see issue rehacat_frontend#335
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            accClinic = "2";
            break;
          default:
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/admin",
          });
        }
        break;
      default:
        break;
    }
  };

  /**React render method */
  render() {
    const { userInfo, isAuthenticated } = this.state
      .authState as AuthContextState;
    return (
      <div>
        <div className={isAuthenticated ? "header-fixed" : ""} id="body">
          {isAuthenticated ? (
            <>
              <TimeoutSession />
              <div className="skip-link-div">
                <a className="skip-link" href="#main-content">
                  Direkt zum Inhalt springen
                </a>
              </div>
              <NavigationBar />
            </>
          ) : null}
          <div className={isAuthenticated ? "page-wrapper-new" : ""}>
            <div className={isAuthenticated ? "wrapper" : ""}>
              {isAuthenticated ? (
                userInfo?.userType === "patient" ? (
                  <div>
                    <div className="skip-sidebar-div">
                      <a className="skip-sidebar" href="#main-content">
                        Navigation überspringen
                      </a>
                    </div>
                    <SidebarPatient />
                  </div>
                ) : userInfo?.userType === "clinician" ? (
                  <div>
                    <div className="skip-sidebar-div">
                      <a className="skip-sidebar" href="#main-content">
                        Navigation überspringen
                      </a>
                    </div>
                    <SidebarClinician handleAccordion={this.handleAccordion} />
                  </div>
                ) : (
                  <div>
                    <div className="skip-sidebar-div">
                      <a className="skip-sidebar" href="#main-content">
                        Navigation überspringen
                      </a>
                    </div>
                    <SidebarAdmin handleAccordion={this.handleAccordion} />
                  </div>
                )
              ) : null}
              <div className="content-wrapper">
                <div
                  id="main-content"
                  className={
                    isAuthenticated
                      ? "content"
                      : "content-without-sidebar content-without-navbar"
                  }
                >
                  {!isAuthenticated ? (
                    <Button
                      variant="primary"
                      id="backToLogin"
                      onClick={() => this.props.history.goBack()}
                    >
                      Zurück
                    </Button>
                  ) : null}
                  <h3>Impressum</h3>
                  <br />

                  <h5>Angaben gemäß § 5 TMG</h5>
                  <p>
                    <em>
                      Universitätsklinikum Freiburg
                      <br />
                      Breisacher Straße 153
                      <br />
                      D 79110 Freiburg
                      <br />
                    </em>
                  </p>
                  <br />

                  <h5>
                    Das Universitätsklinikum Freiburg ist eine rechtsfähige
                    Anstalt des öffentlichen Rechts der
                    Albert-Ludwigs-Universität Freiburg.
                  </h5>
                  <h5>Vertreten durch:</h5>
                  <h5>Vorstand:</h5>
                  <p>
                    <em>
                      Leitender Ärtzlicher Direktor: Prof. Dr. Dr. h.c. Frederik
                      Wenz (Vorsitz)
                      <br />
                      Kaufmännische Direktorin: Anja Simon
                      <br />
                      Stellvertretender Leitender Ärztlicher Direktor: Prof. Dr.
                      Dr. Rainer Schmelzeisen
                      <br />
                      Dekan der Medizinischen Fakultät: Prof. Dr. Lutz Hein
                      <br />
                      Pflegedirektor: Helmut Schifferer
                      <br />
                    </em>
                  </p>

                  <h5>Aufsichtsrat:</h5>
                  <p>
                    <em>
                      Vorsitzender: Dr. Carsten Dose
                      <br />
                      Stellvertretender Vorsitzender: Rektorin Prof. Dr. Kerstin
                      Kriegstein
                      <br />
                    </em>
                  </p>

                  <h5>Kontakt</h5>
                  <p>
                    <em>
                      Telefon: +49 761 270-0
                      <br />
                      E-mail: info@uniklinik-freiburg.de
                      <br />
                    </em>
                  </p>

                  <h5>Umsatzsteuer</h5>
                  <p>
                    <em>
                      Umsatzsteuer-Identifikationsnummer gemäß §27 a
                      Umsatzsteuergesetz:
                      <br />
                      DE 811506626
                      <br />
                    </em>
                  </p>

                  <h5>Aufsichtsbehörde</h5>
                  <p>
                    <em>
                      Ministerium für Wissenschaft, Forschung und Kunst
                      Baden-Württemberg <br />
                      Königstraße 46 <br />
                      70173 Stuttgart
                    </em>
                  </p>

                  <h5>Inhalt:</h5>
                  <p>
                    <em>
                      Die inhaltliche Verantwortung und die Verantwortung im
                      Sinne des Presserechts liegen bei der jeweiligen
                      informationseinstellenden Einrichtung:
                      <br />
                      Klinik für Psychosomatische Medizin und Psychotherapie
                      <br />
                      Sektion Systemische Gesundheitsforschung
                      <br />
                      Hauptstraße 8
                      <br />
                      79104 Freiburg
                      <br />
                      Telefon: +49 761 270-68805
                      <br />
                    </em>
                  </p>

                  <h5>Angaben zur Berufshaftpflichtversicherung</h5>
                  <p>
                    <em>
                      Name und Sitz des Versicherers:Name und Sitz des
                      Versicherers: HDI Global SE, Riethorst 4, 30659 Hannover
                      <br />
                      Räumliche Geltung: Deutschland
                      <br />
                    </em>
                  </p>

                  <h5>Haftung für Inhalte</h5>
                  <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                    Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                    verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                    gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen.
                  </p>
                  <p>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                    Informationen nach den allgemeinen Gesetzen bleiben hiervon
                    unberührt. Eine diesbezügliche Haftung ist jedoch erst ab
                    dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                    möglich. Bei Bekanntwerden von entsprechenden
                    Rechtsverletzungen werden wir diese Inhalte umgehend
                    entfernen.
                  </p>

                  <h5>Haftung für Links</h5>
                  <p>
                    Unser Angebot enthält Links zu externen Websites Dritter,
                    auf deren Inhalte wir keinen Einfluss haben. Deshalb können
                    wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                    Für die Inhalte der verlinkten Seiten ist stets der
                    jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
                    auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                    waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
                    permanente inhaltliche Kontrolle der verlinkten Seiten ist
                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                    nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                    werden wir derartige Links umgehend entfernen.
                  </p>

                  <h5>Urheberrecht</h5>
                  <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke
                    auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
                    der Verwertung außerhalb der Grenzen des Urheberrechtes
                    bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                    für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                    erstellt wurden, werden die Urheberrechte Dritter beachtet.
                    Insbesondere werden Inhalte Dritter als solche
                    gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um
                    einen entsprechenden Hinweis. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend
                    entfernen.
                  </p>

                  <h5>Gesamtrealisation</h5>
                  <p>
                    <em>
                      Klinik für Psychosomatische Medizin und Psychotherapie
                      <br />
                      Sektion Systemische Gesundheitsforschung
                      <br />
                      Prof. Dr. Stefan Schmidt
                      <br />
                      Hauptstraße 8
                      <br />
                      79104 Freiburg
                      <br />
                      Telefon: +49 761 270-68805
                      <br />
                      <a href="mailto:sysdok@uniklinik-freiburg.de">
                        sysdok@uniklinik-freiburg.de
                      </a>
                    </em>
                  </p>

                  <p>
                    <em>
                      Universität Ulm
                      <br />
                      Abteilung für Klinische Psychologie und Psychotherapie
                      <br />
                      Prof. Dr. Harald Baumeister
                      <br />
                      Lise-Meitner-Str. 16
                      <br />
                      89081 Ulm
                      <br />
                      Prof. Dr. Harald Baumeister
                      <br />
                      <a href="mailto:rehacat@uni-ulm.de">rehacat@uni-ulm.de</a>
                    </em>
                  </p>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withAuthContext(Impressum);
