import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";

/**Helper class to switch between different logins.*/
class Start extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.title = this.props.title;
  }

  /**Open patient login page. */
  handlePatient = (event) => {
    event.preventDefault();

    this.props.history.push("/loginpatient");
  };

  /**Open clinician login page. */
  handleClinician = (event) => {
    event.preventDefault();
    this.props.history.push("/loginclinician");
  };

  /**Open admin login page. */
  handleAdmin = (event) => {
    event.preventDefault();
    this.props.history.push("/loginadmin");
  };

  /**Open admin registration page. */
  handleRegist = (event) => {
    event.preventDefault();
    this.props.history.push("/regist");
  };

  /**React render method. */
  render() {
    return (
      <Form>
        <Form.Group>
          <Button variant="primary" type="submit" onClick={this.handlePatient}>
            Patient
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={this.handleClinician}
          >
            Clinician
          </Button>
          <Button variant="primary" type="submit" onClick={this.handleAdmin}>
            Clinic-Admin
          </Button>
          <Button variant="primary" type="submit" onClick={this.handleRegist}>
            Admin registrieren
          </Button>
        </Form.Group>
      </Form>
    );
  }
}

export default Start;
