import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Labels } from "./PatientLabels";

interface SessionCountsProps {
  labels: Labels;
  setLabels: (labels: Labels) => void;
}

const SessionCounts = ({ labels, setLabels }: SessionCountsProps) => {
  const handleAddSessionCount = (text) => {
    const sessionCount = { key: "THERAPY_END_SESSION_COUNT", value: text };
    const updatedLabels = labels.map((label) =>
      label.key === "THERAPY_END_SESSION_COUNT" ? sessionCount : label,
    );

    // Check if 'THERAPY_END_SESSION_COUNT' exists in labels
    const countExists = labels.some(
      (label) => label.key === "THERAPY_END_SESSION_COUNT",
    );

    if (countExists) {
      setLabels(updatedLabels); // This is the missing piece
    } else {
      const newLabels = [...labels, sessionCount];
      setLabels(newLabels);
    }
  };

  return (
    <Row className="mb-3">
      <Col>
        <Form.Group controlId="formGridCode">
          <Form.Label>Sitzungszahl bei Therapieende</Form.Label>
          <Form.Control
            className="form-control"
            type="text"
            placeholder="Sitzungszahl"
            onChange={(e) => {
              handleAddSessionCount(e.target.value);
            }}
            pattern="[0-9]+"
          ></Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default SessionCounts;
