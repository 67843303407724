import React from "react";
import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("Error: ", error, "Info: ", info);
    this.setState({
      error: error,
      errorInfo: info,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Irgendetwas ist schief gelaufen.</h2>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
