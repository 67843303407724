import { SurveyState, SurveyTimeWindow } from "../services/getFunctions";

export interface SurveyStatusAndStws {
  stw_type: string;
  stw_start: string;
  stw_end: string;
  survey_session_id: string;
  survey_started_time: string;
  survey_update_time: string;
  survey_finished: boolean;
  report_viewed: boolean;
  condition_severity: string;
  survey_status: string;
  result_status: string;
  stw_type_deutsch: string;
}

// Zip survey states and survey time windows according to the start and end time
export const zipSurveyStatusAndStw = (
  stws: SurveyTimeWindow[],
  surveyStatuses: SurveyState[],
) => {
  return stws.map((stw, index) => {
    const matchedSurveyStatus = surveyStatuses.find((surveyStatus) =>
      isWithinStw(stw.start, stw.end, surveyStatus.started_time),
    );

    const resultStatus = calculateResultStatus(matchedSurveyStatus);
    const surveyStatus = calculateSurveyStatus(matchedSurveyStatus, stw);
    const stwTypeDeutsch = translateStwType(stw.type, index);

    const surveyStatusAndStw = {
      stw_type: stw.type,
      stw_start: stw.start,
      stw_end: stw.end,
      survey_session_id: matchedSurveyStatus
        ? matchedSurveyStatus.survey_session_id
        : "",
      survey_started_time: matchedSurveyStatus
        ? matchedSurveyStatus.started_time
        : "",
      survey_update_time: matchedSurveyStatus
        ? matchedSurveyStatus.update_time
        : "",
      survey_finished: matchedSurveyStatus
        ? matchedSurveyStatus.finished
        : false,
      report_viewed: matchedSurveyStatus
        ? matchedSurveyStatus.report_viewed
        : false,
      condition_severity: matchedSurveyStatus
        ? matchedSurveyStatus.condition_severity
        : "",
      survey_status: surveyStatus,
      result_status: resultStatus,
      stw_type_deutsch: stwTypeDeutsch,
    };

    return surveyStatusAndStw;
  });
};

// Check is a report is done during a stw
const isWithinStw = (stwStart, stwEnd, surveyStatusStart) => {
  const survey_start = new Date(surveyStatusStart.split("T")[0]);
  const stw_start = new Date(stwStart);
  const stw_end = new Date(stwEnd);
  const decision = survey_start >= stw_start && survey_start <= stw_end;
  return decision;
};

const calculateResultStatus = (surveyStatus) => {
  if (!surveyStatus) {
    return "Nicht Verfügbar";
  } else if (surveyStatus.condition_severity === "UNKNOWN") {
    return "Unbekannt";
  } else if (surveyStatus.condition_severity === "LOW") {
    return "Unauffällig";
  } else if (surveyStatus.condition_severity === "MEDIUM") {
    return "Auffällig";
  } else if (surveyStatus.condition_severity === "HIGH") {
    return "Kritisch";
  } else {
    return "Fehler";
  }
};

const calculateSurveyStatus = (surveyState, stw) => {
  const now = new Date().toISOString().split("T")[0];

  if (surveyState) {
    if (surveyState.finished) {
      return "Erledigt";
    } else if (surveyState.started_time >= stw.start && now <= stw.end) {
      return "Ausstehend";
    } else {
      console.log("survey start: ", surveyState.start);
      console.log("stw start: ", stw.start);
      console.log("now: ", now);
      return "Fehler";
    }
  } else {
    return "Nicht Verfügbar";
  }
};

// This method assumes that the first STW is PRE then it starts with INTERMEDIATE sessions
// If the start INTERMEDIATE session does not begin with index 1, this method needs to be changed
const translateStwType = (stw_type, index) => {
  if (stw_type === "PRE") {
    return "Therapiebeginn";
  }
  if (stw_type === "INTERMEDIATE") {
    return "Verlauf " + index;
  }
  if (stw_type === "POST") {
    return "Therapieende";
  }
  if (stw_type === "FOLLOW_UP_1") {
    return "Katamnese 1";
  }
  if (stw_type === "FOLLOW_UP_2") {
    return "Katamnese 2";
  }
  if (stw_type === "FOLLOW_UP_3") {
    return "Katamnese 3";
  } else {
    return "Unkown Stw type";
  }
};

export const tranlateGender = (gender) => {
  if (gender === "FEMALE") {
    return "Weiblich";
  } else if (gender === "MALE") {
    return "Männlich";
  } else if (gender === "DIVERSE") {
    return "Divers";
  } else {
    return "Fehler";
  }
};
