import React, { createRef } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Row,
  Spinner,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import DatePicker from "react-datepicker";
import {
  adminGetPatients,
  adminGetClinicians,
  getDiagnoses,
  activateClinician,
  deactivateClinician,
  adminResetClinicianPassword,
  Diagnose,
  Clinician,
  ReportEntry,
  Patient,
  SurveyTimeWindow,
} from "../../services/getFunctions";
import AuthService from "../../services/AuthService";
import NavigationBar from "../navigation/NavigationBar";
import SidebarAdmin from "../sidebars/SidebarAdmin";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import ErrorBoundary from "../../ErrorBoundary";
import Footer from "../Footer";
import { GoChecklist } from "react-icons/go";
import TimeoutSession from "../../helper/TimeoutSession";
import {
  reportGetPatientListError,
  reportGetClinicianListError,
  reportGetDiagnosesListError,
  reportNewPasswordError,
  reportDisableEnableClinicianError,
  reportEditPatientError,
  reportEditClinicianError,
  reportClinicianAssignError,
  reportRegistPatientError,
  reportRegistClinicianError,
  reportCsvValidationError,
  showPasswordModal,
  showNewClinicianPasswordModal,
  showAlertModal,
} from "../../helper/alertBoxes";
import {
  editPatient,
  editClinician,
  adminAssignClinician,
  bulkImport,
  bulkValidate,
} from "../../services/postFunctions";
import { Labels, PatientLabels } from "../sharedComponents/PatientLabels";

import SurveyStatusModal from "../sharedComponents/SurveyStatusModal";
import { Typeahead } from "react-bootstrap-typeahead";
import LoadingModal from "../sharedComponents/LoadingModal";
import PatientTable from "../sharedComponents/PatientTable";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ClinicianTable from "../sharedComponents/ClinicianTable";
/**Clinic admins home component contains the dashboard methods and the regist methods for patients and clinicians. */

interface Item {
  value: string;
  valid: boolean;
  key: string;
  message: string;
}

interface BulkDataPatient {
  items: Item[];
}

interface BulkData {
  patients: BulkDataPatient[];
  valid: boolean;
}
export interface GenderList {
  value: string;
  name: string;
}
interface ReportStatus {
  patientCode: string;
  reports: Reports[];
}
interface Reports {
  type: string;
  status: string;
  start_date: Date;
  end_date: Date;
}
interface HomeClinicAdminState {
  admission: Date;
  bulkData: BulkData;
  clinic_name: string;
  clinic_id: string;
  content: string;
  clinician_id: string;
  importing: boolean;
  modalOpen: boolean;
  modalBulkOpen: boolean;
  modalClinicianOpen: boolean;
  modalAssignClinicianOpen: boolean;
  modalEditPatientOpen: boolean;
  modalEditClinicianOpen: boolean;
  modalShowPatientListOpen: boolean;
  modalLoadingOpen: boolean;
  modalTransferPatientsOpen: boolean;
  modalDiagnosisOpen: boolean;
  modalSurveyStateOpen: boolean;
  modalValidOpen: boolean;
  editAdmission: Date;
  editDischarge: Date;
  editBirthday: Date;
  editPatientButton: string;
  clinician: string;
  code: string;
  birthday: Date;
  diagnosesPatient: Diagnose[];
  discharge: Date;
  gender: string;
  genderList: GenderList[];
  clinicians: Clinician[];
  diagnoses: Diagnose[];
  csv: string;
  patients: Patient[];
  usernameClinician: string;
  username: string;
  patientList: string[];
  surveyStatus: ReportStatus[];
  reports: ReportEntry[];
  stws: SurveyTimeWindow[];
  value: string;
  patient_id: string;
  clinician_from_id: string;
  clinician_username: string;
  clinician_from_username: string;
  clinician_enabled: boolean;
  addDiagnosisField: number;
  accordionPatients: string;
  accordionEmployees: string;
  accordionClinic: string;
  actualPatient: string;
  isLoading: boolean;
  countData: number;
  bulkValidation: boolean;
  updatePatients: boolean;
  bulkImportButton: string;
  today: string;
  user_type: string;
  validation: boolean;
  labels: Labels;
  currentPatient: Patient;
}
/*
type LocationProps = {
  state: HomeClinicAdminState
};
 */
export interface HomeClinicAdminProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any;
}

export class HomeClinicAdmin extends React.Component<
  HomeClinicAdminProps,
  HomeClinicAdminState
> {
  fileInput = createRef<HTMLInputElement>();

  constructor(props: HomeClinicAdminProps) {
    super(props);
    this.state = {
      user_type: "admin",
      content: "",
      importing: false,
      modalOpen: false,
      modalBulkOpen: false,
      modalClinicianOpen: false,
      modalAssignClinicianOpen: false,
      modalEditPatientOpen: false,
      modalEditClinicianOpen: false,
      modalShowPatientListOpen: false,
      modalTransferPatientsOpen: false,
      modalDiagnosisOpen: false,
      modalSurveyStateOpen: false,
      modalValidOpen: false,
      admission: new Date(),
      editAdmission: new Date(),
      editDischarge: new Date(),
      editBirthday: new Date(),
      clinician: "",
      clinician_id: "",
      code: "",
      birthday: new Date("01/01/1990"),
      diagnosesPatient: [],
      discharge: new Date(),
      gender: "",
      genderList: [
        { value: "FEMALE", name: "Weiblich" },
        { value: "MALE", name: "Männlich" },
        { value: "DIVERSE", name: "Divers" },
      ],
      clinicians: [],
      diagnoses: [],
      csv: "",
      patients: [],
      usernameClinician: "",
      username: "",
      clinic_id: "",
      clinic_name: "",
      patientList: [],
      surveyStatus: [],
      reports: [],
      stws: [],
      patient_id: "",
      clinician_from_id: "",
      clinician_username: "",
      clinician_enabled: true,
      addDiagnosisField: 0,
      accordionPatients: "-1",
      accordionEmployees: "-1",
      accordionClinic: "-1",
      actualPatient: "",
      modalLoadingOpen: true,
      isLoading: true,
      countData: 0,
      bulkValidation: false,
      bulkData: {
        patients: [],
        valid: false,
      },
      editPatientButton: "",
      updatePatients: false,
      bulkImportButton: "",
      today: new Date().toISOString().split("T")[0],
      currentPatient: {
        viewed: "",
        duplicate: false,
        date_of_birth: "",
        admission: "",
        discharge: "",
        code: "",
        clinician_username: "",
        created: "",
        clinician_id: "",
        diagnoses: { diagnosis: [] },
        gender: "", //maybe reduce to the possible values
        id: "",
        survey_statuses: [],
        survey_time_windows: [],
        labels: [],
      },
      validation: false,
      value: "",
      clinician_from_username: "",
      labels: [],
    };
    this.handleCode = this.handleCode.bind(this);
    this.handleBirthdate = this.handleBirthdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGender = this.handleGender.bind(this);
    this.handleClinician = this.handleClinician.bind(this);
    this.handleDiagnosis = this.handleDiagnosis.bind(this);
    this.handleAdmissionDate = this.handleAdmissionDate.bind(this);
    this.handleBulkImport = this.handleBulkImport.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.fileInput = React.createRef();
  }

  /**Method for loading the clinician, patient and diagnoses lists from backend API. */
  componentDidMount() {
    //const location = useLocation() as unknown as LocationProps;
    document.title = this.props.title;
    console.log("Gender Liste: ", this.state.genderList);
    if (this.props.location.state === undefined) {
      console.warn("Props Location Fail");
      ErrorBoundary.getDerivedStateFromError();
    } else {
      this.setState({
        clinic_id: this.props.location.state.clinic_id,
        clinic_name: this.props.location.state.clinic_name,
        username: this.props.location.state.username,
      });
      if (this.props.location.state.accordionPatients) {
        this.setState({
          accordionPatients: this.props.location.state.accordionPatients,
          accordionEmployees: this.props.location.state.accordionEmployees,
          accordionClinic: this.props.location.state.accordionClinic,
        });
      }
    }

    try {
      adminGetPatients()
        .then((patientList) => {
          console.info("Patientenliste: ", patientList);
          this.setState({ patients: patientList.list_patient_dto }, () => {
            this.closeLoadingModal();
          });
          console.log("patients mount: " + this.state.patients);
          this.setState((prev) => {
            return { countData: prev.countData + 1 };
          });
        })
        .catch((error) => {
          reportGetPatientListError(error);
        });
    } catch (error) {
      /*empty*/
    }

    try {
      adminGetClinicians()
        .then((clinicianList) => {
          console.info("Mitarbeiter-Liste: ", clinicianList.clinicians);
          console.info("Liste: ", clinicianList);
          this.setState({ clinicians: clinicianList.clinicians });
          this.setState((prev) => {
            return { countData: prev.countData + 1 };
          });
        })
        .catch((error) => {
          reportGetClinicianListError(error);
        });
    } catch (error) {
      /*empty*/
    }

    try {
      getDiagnoses()
        .then((diagnoses) => {
          console.log("Diagnosen-Liste: ", diagnoses);
          this.setState({ diagnoses: diagnoses.diagnosis });
        })
        .catch((error) => {
          reportGetDiagnosesListError(error);
        });
    } catch (error) {
      /*empty*/
    }
  }

  /**Set modalOpen on true, if the modal is opened. */
  openModal = () =>
    this.setState({
      modalOpen: true,
    });
  openBulkModal = () => this.setState({ modalBulkOpen: true });
  openValidModal = () =>
    this.setState({ modalValidOpen: true, validation: false });
  openClinicianModal = () => this.setState({ modalClinicianOpen: true });
  openDiagnosisModal = () => this.setState({ modalDiagnosisOpen: true });
  //openLoadingModal = () => this.setState({ modalLoadingOpen: true });

  /**Set modalAssignClinicianOpen on true, if the modal is opened.
   * Set patient_id and clinician_from.
   */
  openAssignClinicianModal = (event) => {
    event.preventDefault();
    const code = event.currentTarget.id;
    let patient;
    for (let i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].code === code) {
        patient = this.state.patients[i];
      }
    }
    this.setState({
      modalAssignClinicianOpen: true,
      patient_id: patient.id,
      code: patient.code,
      clinician_from_id: patient.clinician_id,
      clinician_from_username: patient.clinician_username,
    });
  };

  /**Set modalEditPatientOpen on true, if the modal is opened.
   * Set the actual states of the selected patient.
   */
  openEditPatientModal = (event) => {
    event.preventDefault();
    const code = event.currentTarget.id;
    let patient;

    for (let i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].code === code) {
        patient = this.state.patients[i];
      }
    }
    const newBirthday = new Date(patient.date_of_birth);
    const newAdmission = new Date(patient.admission);
    const newDischarge = new Date(patient.discharge);

    let gender = patient.gender;
    if (patient.gender === "Weiblich") {
      gender = "FEMALE";
    } else if (patient.gender === "Männlich") {
      gender = "MALE";
    } else if (patient.gender === "Divers") {
      gender = "DIVERSE";
    }

    this.setState({
      modalEditPatientOpen: true,
      editBirthday: newBirthday,
      editAdmission: newAdmission,
      editDischarge: newDischarge,
      clinician_id: patient.clinician_id,
      clinician_username: patient.clinician_username,
      diagnosesPatient: patient.diagnoses,
      patient_id: patient.id,
      gender: gender,
      code: patient.code,
      labels: patient.labels,
    });

    console.info("Diagnose(n): ", patient.diagnoses);
  };

  /**Set modalEditClinicianOpen on true, if the modal is opened.
   * Set the actual states of the selected clinician.
   */
  openEditClinicianModal = (event) => {
    event.preventDefault();
    const index = event.currentTarget.id;
    const clinician = this.state.clinicians[index];

    this.setState({
      modalEditClinicianOpen: true,
      clinician_enabled: clinician.enabled,
      clinician_id: clinician.id,
      clinician_username: clinician.username,
    });
  };

  /**Set modalShowPatientListOpen on true, if the modal is opened.
   * Create the patient list for the current clinician.
   */
  openShowPatientListModal = (event) => {
    event.preventDefault();
    const index = event.target.id;
    const clinician_username = this.state.clinicians[index].username;
    const patientList: string[] = [];
    for (let i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].clinician_username === clinician_username) {
        patientList.push(this.state.patients[i].code);
      }
    }
    this.setState({
      modalShowPatientListOpen: true,
      clinician_username: clinician_username,
      patientList: patientList,
    });
  };

  /**Set modalTransferPatientOpen on true, if the modal is opened.
   *
   */
  openTransferPatientsModal = (event) => {
    event.preventDefault();
    const index = event.target.id;
    const clinician = this.state.clinicians[index];
    const patientList: string[] = [];
    for (let i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].clinician_username === clinician.username) {
        patientList.push(
          this.state.patients[i].code,
          this.state.patients[i].id,
        );
      }
    }

    this.setState({
      modalTransferPatientsOpen: true,
      clinician_from_id: clinician.id,
      clinician_from_username: clinician.username,
      patientList: patientList,
    });
  };
  openSurveyStateModal = (event) => {
    event.preventDefault();
    const id = event.target.id;
    console.log("Patient: " + this.state.patients);
    console.log("event ID: " + id);
    const patient = this.state.patients.find((p) => p.id === id);

    if (patient) {
      if (this.state.currentPatient.id !== id) {
        this.setState({
          currentPatient: patient,
        });
      }
    } else {
      console.error("Patient with id ${id} not found.");
    }

    this.setState({
      modalSurveyStateOpen: true,
    });
  };

  /**Set modalOpen on false, if the modal is closed. */
  closeModal = () =>
    this.setState({
      modalOpen: false,
      code: "",
      value: "",
      gender: "",
      diagnosesPatient: [],
      clinician: "",
      birthday: new Date("01/01/1990"),
      admission: new Date(),
      discharge: new Date(),
    });
  closeBulkModal = () =>
    this.setState({
      modalBulkOpen: false,
      bulkValidation: false,
      validation: false,
    });
  closeValidModal = () => this.setState({ modalValidOpen: false });
  closeClinicianModal = () => this.setState({ modalClinicianOpen: false });
  closeAssignClinicianModal = () =>
    this.setState({ modalAssignClinicianOpen: false, code: "" });
  closeEditPatientModal = () =>
    this.setState({ modalEditPatientOpen: false, code: "", gender: "" });
  closeDiagnosisModal = () => this.setState({ modalDiagnosisOpen: false });
  closeEditClinicianModal = () =>
    this.setState({
      modalEditClinicianOpen: false,
      clinician_id: "",
      clinician_username: "",
    });

  closeShowPatientListModal = () =>
    this.setState({ modalShowPatientListOpen: false });
  closeTransferPatientsModal = () =>
    this.setState({ modalTransferPatientsOpen: false });

  closeLoadingModal = () => this.setState({ modalLoadingOpen: false });

  closeSurveyStateModal = () =>
    this.setState({ modalSurveyStateOpen: false, surveyStatus: [] });

  // not sure if we need th function in future
  /*updatePatientOverview = (event) => {
    event.preventDefault();
    this.setState({ updatePatients: true, isLoading: true });
    this.componentDidMount();
  };

   */

  /**Method to update the edited bithdate state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditBirthdate(date) {
    this.setState({ editBirthday: date });
    console.info("Birthday: ", this.state.editBirthday);
  }

  /**Method to update the edited admission date state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditAdmissionDate(date) {
    showAlertModal(
      "Achtung: Durch das Ändern von Aufnahme- oder Entlassdatum können bisherige Befragungen ungültig werden.",
    );
    this.setState({ editAdmission: date });
  }

  /**Method to update the edited discharge date state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditDischargeDate(date) {
    if (date <= this.state.editAdmission) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      showAlertModal(
        "Achtung: Durch das Ändern von Aufnahme- oder Entlassdatum können bisherige Befragungen ungültig werden.",
      );
      this.setState({ editDischarge: date });
    }
  }

  handleClinicianUsername(text) {
    console.info("Username: ", text.target.value);
    this.setState({ clinician_username: text.target.value });
  }

  /**Method to update the birthdate state, when it is changed.
   * @param date {date} date from datepicker or date input
   */
  handleBirthdate(date) {
    this.setState({ birthday: date });
  }

  /**Method to update the code state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleCode(text) {
    console.log("Code", this.state.code, " ausgefüllt");
    this.setState({ code: text.target.value });
  }

  /**Method to update the gender state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the selected gender
   */
  handleGender(text) {
    if (text.target.value === "Auswählen...") {
      this.setState({ gender: "" });
    } else {
      console.log("Gender: ", text.target.value);
      this.setState({ gender: text.target.value });
    }
  }

  /**Method to update the clinician state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the selected clinician
   */
  handleClinician(text) {
    if (text.target.value === "Auswählen...") {
      this.setState({ clinician: "" });
    } else {
      this.setState({ clinician: text.target.value });
    }
  }

  /**Method to update the diagnosis state, when it is changed.
   * @param items {ChangeEvent} onChange delivers the event with the slected diagnosis
   */

  handleDiagnosis(items: Diagnose[]) {
    if (items && Array.isArray(items) && items.length) {
      this.setState({
        diagnosesPatient: items.filter(
          (item, i, arr) => arr.findIndex((t) => t.id === item.id) === i,
        ),
      });
    } else {
      this.setState({ diagnosesPatient: [] });
    }
  }

  /**Method to update the admission date state, when it is changed.
   * @param date {date} date from datepicker or date input
   */
  handleAdmissionDate(date) {
    this.setState({ admission: date });
  }

  /**Method to update the discharge date state, when it is changed.
   * @param date {date} date from datepicker or date input
   */
  handleDischargeDate(date) {
    if (date <= this.state.admission) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      this.setState({ discharge: date });
    }
  }

  /**Method to update the clinician username state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed clinician username
   */
  handleUsername(text) {
    this.setState({ usernameClinician: text.target.value });
  }

  handleAccordion = (input) => {
    if (input === "patients") {
      this.state.accordionPatients === "-1"
        ? this.setState({ accordionPatients: "0" })
        : this.setState({ accordionPatients: "-1" });
      this.setState({ accordionEmployees: "-1", accordionClinic: "-1" });
    } else if (input === "employees") {
      this.state.accordionEmployees === "-1"
        ? this.setState({ accordionEmployees: "1" })
        : this.setState({ accordionEmployees: "-1" });
      this.setState({ accordionPatients: "-1", accordionClinic: "-1" });
    } else if (input === "clinic") {
      this.state.accordionClinic === "-1"
        ? this.setState({ accordionClinic: "2" })
        : this.setState({ accordionClinic: "-1" });
      this.setState({ accordionPatients: "-1", accordionEmployees: "-1" });
    }
  };

  /**Method to transfer all patients from one clinician to another clinician. */
  transferPatients = (event) => {
    event.preventDefault();
    if (this.state.clinician !== "") {
      this.state.patientList.forEach((patient_id_dry) => {
        const assignmentData = {
          patient_id: patient_id_dry,
          clinician_from: this.state.clinician_from_id,
          clinician_to: this.state.clinician,
        };
        try {
          adminAssignClinician(assignmentData)
            .then((response) => {
              console.log("JSON: ", assignmentData);
              console.log("Response:", response.status);
              showAlertModal(
                "Die Patient*innen wurden erfolgreich an den*die Mitarbeitende*n " +
                  this.state.clinician +
                  " übertragen.",
              );
              this.closeAssignClinicianModal();
              this.componentDidMount();
            })
            .catch((error) => {
              console.log("assignmentData: ", assignmentData);
              console.log("error call: ", error);
              // TODO: Somehow an error is getting caught, everytime the API is called,
              // frontend reports 200 but this catch is being called everytime.
              // This needs to be looked at and fixed, but for now it is quickfixed with
              // only calling the console.log and not raising an exception.
              // Possible solution: API for a patient is getting called twice, creating
              // the situation where every patient gets transfered, but also every patient
              // causing one 404 HTTP error for patient not found.
              // fix -> make API only being called once.
              // reportClinicianAssignError(error);
            });
        } catch (e) {
          console.error("Catch", e);
          return;
        }
      });
    } else {
      showAlertModal(
        "Sie müssen noch eine*n Mitarbeitende*n auswählen, an welchen die Patient*innen übertragen werden sollen.",
      );
    }
  };

  /**Method to reset clinicians password. */
  resetPassword = (event) => {
    event.preventDefault();
    const index = event.target.id;
    const clinician_id = this.state.clinicians[index].id;
    console.log("Mitarbeiter ID: ", clinician_id);

    try {
      adminResetClinicianPassword(clinician_id)
        .then((password) => {
          showPasswordModal(password);
        })
        .catch((error) => {
          reportNewPasswordError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Method to enable or disable a clinician.
   */
  disableEnableClinician = (event) => {
    event.preventDefault();
    console.log("Disable Event: ", event);
    const index = event.target.id;
    const clinician_id = this.state.clinicians[index].id;
    const clinician_username = this.state.clinicians[index].username;
    console.log("Mitarbeiter: ", clinician_id);
    console.log("/api/auth/clinicans/" + clinician_id + "/deactivation");
    let str;

    let activationDeactivationFunction;
    if (event.target.name === "enable") {
      activationDeactivationFunction = activateClinician;
      str = "aktiviert";
    } else {
      activationDeactivationFunction = deactivateClinician;
      str = "deaktiviert";
    }

    try {
      activationDeactivationFunction(clinician_id)
        .then((response) => {
          console.log("Response:", response.status);
          showAlertModal(
            "Der*Die Mitarbeitende " +
              clinician_username +
              " wurde erfolgreich " +
              str +
              ".",
          );
          this.componentDidMount();
        })
        .catch((error) => {
          reportDisableEnableClinicianError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Method for handle edit clinician.
   * The input data are stringified to JSON.
   * Then send JSON to backend api.
   */
  handleEditClinician = (event) => {
    event.preventDefault();

    const clinicianData = {
      enabled: this.state.clinician_enabled,
      id: this.state.clinician_id,
      username: this.state.clinician_username,
    };

    try {
      editClinician(clinicianData)
        .then((response) => {
          console.log("Response:", response.status);
          showAlertModal("Der Benutzername wurde erfolgreich geändert.");
          this.closeEditClinicianModal();
          this.componentDidMount();
        })
        .catch((error) => {
          reportEditClinicianError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Method for handle clinician assign.
   * The input data are stringified to JSON.
   * Then send JSON to backend api.
   */
  handleClinicianAssign = (event) => {
    event.preventDefault();

    const assignmentData = {
      patient_id: this.state.patient_id,
      clinician_from: this.state.clinician_from_id,
      clinician_to: this.state.clinician,
    };

    try {
      adminAssignClinician(assignmentData)
        .then((response) => {
          console.log("JSON: ", assignmentData);
          console.log("Response:", response.status);
          showAlertModal(
            "Dem Patienten wurde erfolgreich ein neuer Mitarbeiter zugewiesen.",
          );
          this.closeAssignClinicianModal();
          this.componentDidMount();
        })
        .catch((error) => {
          reportClinicianAssignError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Method for handle patients diagnoses.
   * Each diagnoses that is selected is written to the diagnosesPatient list.
   * If no diagnosis is selected, an alert is displayed.
   */
  handleEditDiagnosesList = (event) => {
    event.preventDefault();
    console.log("Event: ", event);
    const diagnoses: Diagnose[] = [];
    for (let i = 0; i < event.target.length; i++) {
      console.log("Event an der Stelle ", i, event.target[i]);
      if (
        event.target[i].type === "checkbox" &&
        event.target[i].checked === true
      ) {
        console.log("True: ", event.target[i]);
        diagnoses.push({
          description: event.target[i].dataset.name,
          id: event.target[i].value,
        });
      }
    }
    console.log("Liste: ", diagnoses);
    if (diagnoses.length === 0) {
      showAlertModal("Sie müssen mindestens eine Diagnose wählen.");
    } else {
      this.setState({ diagnosesPatient: diagnoses });
      this.closeDiagnosisModal();
    }
  };

  /**Method for handle  patient edit.
   */
  handleEditPatient = (event) => {
    event.preventDefault();
    if (this.state.editDischarge <= this.state.editAdmission) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      let date_of_birth = this.state.editBirthday.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      let [day, month, year] = date_of_birth.split(".");
      date_of_birth = [year, month, day].join("-");
      let admission = this.state.editAdmission.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      [day, month, year] = admission.split(".");
      admission = [year, month, day].join("-");
      let discharge = this.state.editDischarge.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      [day, month, year] = discharge.split(".");
      discharge = [year, month, day].join("-");
      const patientData = {
        date_of_birth: date_of_birth,
        admission: admission,
        discharge: discharge,
        clinician_id: this.state.clinician_id,
        clinician_username: this.state.clinician_username,
        diagnoses: this.state.diagnosesPatient,
        id: this.state.patient_id,
        gender: this.state.gender,
        code: this.state.code,
        created: "1990-01-01T00:00:00", //the backend wants this but the value will not be changed
        labels: this.state.labels,
      };

      console.log("JSON: ", patientData);

      try {
        editPatient(patientData)
          .then((response) => {
            console.log("JSON: ", patientData);
            console.log("Patient bearbeitet:", response.status);
            this.closeEditPatientModal();
            this.componentDidMount();
          })
          .catch((error) => {
            reportEditPatientError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**
   * Method for handle the patient regist.
   * The input data are stringified to JSON.
   * Then send JSON to backend API.
   */
  handleSubmit = (event) => {
    event.preventDefault();
    console.log("Button: ", this.state.editPatientButton);
    console.log(
      "Mitarbeitername: ",
      this.state.clinician,
      "Code: ",
      this.state.code,
      "Geschlecht: ",
      this.state.gender,
      "Diagnose: ",
      this.state.diagnosesPatient,
    );

    let admission = this.state.admission.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    let [day, month, year] = admission.split(".");
    admission = [year, month, day].join("-");
    let discharge = this.state.discharge.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    [day, month, year] = discharge.split(".");
    discharge = [year, month, day].join("-");
    let date_of_birth = this.state.birthday.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    [day, month, year] = date_of_birth.split(".");
    date_of_birth = [year, month, day].join("-");

    console.log(
      "Aufnahmedatum: ",
      this.state.admission.toISOString().split("T")[0],
      ", Entlassdatum: ",
      this.state.discharge.toISOString().split("T")[0],
    );
    if (this.state.code === "") {
      showAlertModal("Sie müssen eine SysDok-ID angeben.");
    } else if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\s]/.test(this.state.code)) {
      showAlertModal(
        "Die SysDok-ID darf keine Leer- oder Sonderzeichen enthalten.",
      );
    } else if (this.state.gender === "") {
      showAlertModal("Sie müssen ein Geschlecht auswählen.");
    } else if (this.state.clinician === "") {
      showAlertModal("Sie müssen eine*n Mitarbeitende*n auswählen.");
    } else if (this.state.diagnosesPatient?.length === 0) {
      showAlertModal("Sie müssen eine Diagnose auswählen.");
    } else if (
      this.state.discharge.toISOString().split("T")[0] <=
      this.state.admission.toISOString().split("T")[0]
    ) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      const json = JSON.stringify({
        admission: admission,
        clinician_username: this.state.clinician,
        code: this.state.code,
        day_of_birth: date_of_birth,
        // NOTE: This only works as long as the registration modal uses a single select menu!
        diagnosis_id: this.state.diagnosesPatient[0].id,
        discharge: discharge,
        gender: this.state.gender,
        labels: this.state.labels,
      });

      console.log("JSON: ", json);

      try {
        AuthService.regist(json, "/api/auth/patients/registration")
          .then((response) => {
            console.log("JSON: ", json);
            console.log("Response:", response.status);
            // if (event.nativeEvent.submitter.name === "btn1") {
            if (this.state.editPatientButton === "btn1") {
              this.closeModal();
            } else {
              this.openModal();
            }

            this.setState({
              code: "",
              value: "",
              gender: "",
              diagnosesPatient: [],
              clinician: "",
              birthday: new Date("01/01/1990"),
              admission: new Date(),
              discharge: new Date(),
              labels: [],
            });

            this.componentDidMount();
          })
          .catch((error) => {
            reportRegistPatientError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**Method for handle the validation of patient bulk import.
   */
  validateBulkImport = (event) => {
    event.preventDefault();
    let file;
    if (this.fileInput?.current?.files) {
      file = this.fileInput.current.files[0];
      console.log("Datei: ", file);
      console.log("Event: ", event);
      console.log("Button Name: ", event.nativeEvent.submitter.name);
    }
    if (file === undefined) {
      showAlertModal("Sie müssen zuerst eine Datei zur Überprüfung auswählen.");
    } else {
      this.setState({ validation: true });
      try {
        bulkValidate(file)
          .then((response) => {
            console.log("Validierung: ", response.data);

            this.setState({ bulkData: response.data });
            if (response.data.valid === false) {
              console.log("Not valid");
              // alert("Die Datei enthält Fehler.");
            } else {
              this.setState({ bulkValidation: true });
            }
            this.openValidModal();
          })
          .catch((error) => {
            this.setState({ validation: false });
            reportCsvValidationError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**
   * Method for handle the patient bulk import.
   */
  handleBulkImport = (event) => {
    event.preventDefault();
    let file;
    if (this.fileInput?.current?.files) {
      file = this.fileInput.current.files[0];
      console.log("CSV: ", file);
    }
    if (this.state.bulkImportButton === "validation") {
      this.validateBulkImport(event);
    } else {
      this.setState({ validation: false, importing: true });
      try {
        bulkImport(file)
          .then((response) => {
            console.log("Response: ", response);
            showAlertModal("Die Patient*innen wurden erfolgreich importiert.");
            this.setState({ bulkValidation: false, importing: false });
            this.closeBulkModal();
            this.componentDidMount();
          })
          .catch((error) => {
            this.setState({ importing: false });
            console.error(error);
            showAlertModal(
              "Irgendetwas ist schief gelaufen. Die Patient*innen wurden nicht importiert.",
            );
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**Method for handle the clinician regist
   */
  handleClinicianRegist = (event) => {
    event.preventDefault();
    if (this.state.usernameClinician === "") {
      showAlertModal("Sie müssen einen Benutzernamen eingeben. ");
    } else if (
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\s]/.test(this.state.usernameClinician)
    ) {
      showAlertModal(
        "Das Mitarbeitenden-ID darf keine Leer- oder Sonderzeichen enthalten.",
      );
    } else {
      const json = JSON.stringify({
        username: this.state.usernameClinician,
      });
      console.log("JSON", json);

      try {
        AuthService.regist(json, "/api/auth/clinicians/registration")
          .then((response) => {
            console.log("Response: ", response);
            showNewClinicianPasswordModal(response.data);
            this.closeClinicianModal();
            this.componentDidMount();
          })
          .catch((error) => {
            reportRegistClinicianError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**Handle add labels */
  setLabels = (labels) => {
    this.setState({ labels: labels });
  };

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <div className="skip-link-div">
          <a className="skip-link" href="#main-content">
            Direkt zum Inhalt springen
          </a>
        </div>
        <NavigationBar
          clinicId={this.state.clinic_id}
          clinicName={this.state.clinic_name}
          username={this.state.username}
          userType={this.state.user_type}
        />
        <div className="page-wrapper-new">
          <div className="wrapper">
            <div className="skip-sidebar-div">
              <a className="skip-sidebar" href="#main-content">
                Navigation überspringen
              </a>
            </div>
            <SidebarAdmin handleAccordion={this.handleAccordion} />
            <div className="content-wrapper">
              <div className="content" id="main-content">
                <Accordion
                  className="card-default"
                  activeKey={this.state.accordionPatients}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      onClick={() => this.handleAccordion("patients")}
                    >
                      Übersicht Patient*innen
                    </Accordion.Header>
                    <Accordion.Body>
                      <Button className="custom-btn" onClick={this.openModal}>
                        Patient*in anlegen
                      </Button>
                      <Button
                        className="custom-btn"
                        onClick={this.openBulkModal}
                      >
                        Sammelimport
                      </Button>

                      <PatientTable
                        patients={this.state.patients}
                        userType={this.state.user_type}
                        openEditPatientModal={this.openEditPatientModal}
                        openAssignClinicianModal={this.openAssignClinicianModal}
                        openResultModal={() => {}}
                        openSurveyStateModal={this.openSurveyStateModal}
                        closeLoadingModal={this.closeLoadingModal}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  className="card-default"
                  activeKey={this.state.accordionEmployees}
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header
                      onClick={() => this.handleAccordion("employees")}
                    >
                      Übersicht Mitarbeitende
                    </Accordion.Header>
                    <Accordion.Body>
                      <Button onClick={this.openClinicianModal}>
                        Mitarbeitende anlegen
                      </Button>

                      <ClinicianTable
                        clinicians={this.state.clinicians}
                        patients={this.state.patients}
                        openEditClinicianModal={this.openEditClinicianModal}
                        openShowPatientListModal={this.openShowPatientListModal}
                        openTransferPatientsModal={
                          this.openTransferPatientsModal
                        }
                        disableEnableClinician={this.disableEnableClinician}
                        resetPassword={this.resetPassword}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                {this.state.modalSurveyStateOpen && (
                  <div>
                    <SurveyStatusModal
                      currentPatient={this.state.currentPatient}
                      modalSurveyStateOpen={this.state.modalSurveyStateOpen}
                      closeSurveyStateModal={this.closeSurveyStateModal}
                    />
                  </div>
                )}

                <Modal
                  backdrop="static"
                  show={this.state.modalEditClinicianOpen}
                  onHide={this.closeEditClinicianModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Mitarbeitenden-ID ändern</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleEditClinician}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5">
                          Alte Mitarbeitenden-ID:
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            className="form-control-plain"
                            plaintext
                            readOnly
                            defaultValue={this.state.clinician_username}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5">
                          Neue Mitarbeitenden-ID:{" "}
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            className="form-control"
                            required
                            type="username"
                            onChange={(text) => {
                              this.handleClinicianUsername(text);
                            }}
                          />
                        </Col>
                      </Form.Group>
                      <Button type="submit">Speichern</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeEditClinicianModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalEditPatientOpen}
                  onHide={this.closeEditPatientModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>
                      Patient*in <em>{this.state.code}</em> bearbeiten
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleEditPatient}>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="formGridBirthday">
                            <Form.Label>Geburtsdatum</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.editBirthday}
                              value={this.state.editBirthday}
                              onChange={(date) => {
                                this.handleEditBirthdate(date);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Geschlecht</Form.Label>
                            <Form.Select
                              className="form-control"
                              required
                              value={this.state.gender}
                              onChange={(event) => {
                                this.handleGender(event);
                              }}
                            >
                              {/*  {this.state.gender === "MALE" ? (
                                <option>Männlich</option>
                              ) : this.state.gender === "FEMALE" ? (
                                <option>Weiblich</option>
                              ) : (
                                <option>Divers</option>
                              )}
                              */}
                              {this.state.genderList &&
                                this.state.genderList.length > 0 &&
                                this.state.genderList.map((gender) => {
                                  return (
                                    <option
                                      key={gender.value}
                                      value={gender.value}
                                    >
                                      {gender.name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Diagnose(n)</Form.Label>
                            <Typeahead
                              id="basic-typeahead-example"
                              selected={this.state.diagnosesPatient}
                              multiple={true}
                              size="sm"
                              align={"left"}
                              labelKey="description"
                              options={this.state.diagnoses as Diagnose[]}
                              placeholder="Auswählen..."
                              clearButton={false}
                              onChange={(event) => {
                                // NOTE: this sets the state wit the selected diagnoses
                                this.handleDiagnosis(event as Diagnose[]);
                              }}
                              maxResults={1000}
                              renderMenuItemChildren={(option) => (
                                <div>
                                  <span>
                                    {(option as Diagnose).id} -{" "}
                                    {(option as Diagnose).description}
                                  </span>
                                </div>
                              )}
                              filterBy={(option, searchText) => {
                                return (
                                  (option as Diagnose).id
                                    .toLowerCase()
                                    .includes(searchText.text.toLowerCase()) ||
                                  (option as Diagnose).description
                                    .toLowerCase()
                                    .includes(searchText.text.toLowerCase())
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <Form.Group>
                            <Form.Label>Aufnahmedatum ändern</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              selected={this.state.editAdmission}
                              onChange={(date) => {
                                this.handleEditAdmissionDate(date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Entlassdatum ändern</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              selected={this.state.editDischarge}
                              minDate={this.state.editAdmission}
                              onChange={(date) => {
                                this.handleEditDischargeDate(date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <PatientLabels
                        labels={this.state.labels}
                        setLabels={this.setLabels}
                      />
                      <Button type="submit">Speichern</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeEditPatientModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>
                {
                  // Modification modal
                }
                <Modal
                  backdrop="static"
                  size="lg"
                  show={this.state.modalDiagnosisOpen}
                  onHide={this.closeDiagnosisModal}
                >
                  <ModalHeader>Wählen Sie die Diagnose(n) aus:</ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleEditDiagnosesList}>
                      {this.state.diagnoses &&
                        this.state.diagnoses.length > 0 &&
                        this.state.diagnoses.map((diagnosis) => {
                          return this.state.diagnosesPatient.some(
                            (diagPatient) => {
                              return (
                                diagnosis.description ===
                                diagPatient.description
                              );
                            },
                          ) ? (
                            <Form.Check
                              key={diagnosis.id}
                              type="checkbox"
                              id={diagnosis.id}
                              data-name={diagnosis.description}
                              label={diagnosis.description}
                              value={diagnosis.id}
                              defaultChecked
                            />
                          ) : (
                            <Form.Check
                              key={diagnosis.id}
                              type="checkbox"
                              id={diagnosis.id}
                              data-name={diagnosis.description}
                              label={diagnosis.description}
                              value={diagnosis.id}
                            />
                          );
                        })}
                      <Button type="submit">Speichern</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="danger" onClick={this.closeDiagnosisModal}>
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalShowPatientListOpen}
                  onHide={this.closeShowPatientListModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>
                      Patient*innenliste von{" "}
                      <em>{this.state.clinician_username}</em>
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate>
                      {this.state.patientList &&
                        this.state.patientList.length > 0 &&
                        this.state.patientList.map((patient, index) => {
                          return <div key={index}>{patient}</div>;
                        })}
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeShowPatientListModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalTransferPatientsOpen}
                  onHide={this.closeTransferPatientsModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Patient*innen transferieren</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.transferPatients}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                          Mitarbeitende aktuell
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            className="form-control-plain"
                            plaintext
                            readOnly
                            defaultValue={this.state.clinician_from_username}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                          Transferieren an
                        </Form.Label>
                        <Col sm="8">
                          <Form.Select
                            className="form-control"
                            required
                            value={this.state.clinician}
                            onChange={(event) => {
                              this.handleClinician(event);
                            }}
                          >
                            <option>Auswählen...</option>
                            {this.state.clinicians &&
                              this.state.clinicians.length > 0 &&
                              this.state.clinicians.map((clinician) => {
                                return clinician.username !==
                                  this.state.clinician_from_username ? (
                                  <option
                                    disabled={!clinician.enabled}
                                    key={clinician.id}
                                    value={clinician.username}
                                  >
                                    {clinician.username}
                                  </option>
                                ) : null;
                              })}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Button type="submit">Transferieren</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeTransferPatientsModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalAssignClinicianOpen}
                  onHide={this.closeAssignClinicianModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Neue*n Mitarbeitende*n zuweisen</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleClinicianAssign}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5">
                          Patient*in
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            className="form-control-plain"
                            plaintext
                            readOnly
                            defaultValue={this.state.code}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5">
                          Mitarbeitende ändern:
                        </Form.Label>
                        <Col sm="7">
                          <Form.Select
                            className="form-control"
                            required
                            value={this.state.clinician}
                            onChange={(event) => {
                              this.handleClinician(event);
                            }}
                          >
                            <option>
                              {this.state.clinician_from_username}
                            </option>
                            {this.state.clinicians &&
                              this.state.clinicians.length > 0 &&
                              this.state.clinicians.map((clinician) => {
                                return clinician.username !==
                                  this.state.clinician_from_username ? (
                                  <option
                                    disabled={!clinician.enabled}
                                    key={clinician.id}
                                    value={clinician.username}
                                  >
                                    {clinician.username}
                                  </option>
                                ) : null;
                              })}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Button type="submit">Mitarbeitende ändern</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeAssignClinicianModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalOpen}
                  onHide={this.closeModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Neue Patient*innen anlegen</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      className="mb-3"
                      noValidate
                      onSubmit={this.handleSubmit}
                    >
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="formGridCode">
                            <Form.Label>SysDok-ID</Form.Label>
                            <Form.Control
                              className="form-control"
                              required
                              type="code"
                              placeholder="SysDok-ID"
                              value={this.state.code}
                              onChange={(text) => {
                                this.handleCode(text);
                              }}
                              pattern="[A-Za-z0-9]+"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="formGridBirthday">
                            <Form.Label>Geburtsdatum</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.birthday}
                              value={this.state.birthday}
                              onChange={(date) => {
                                this.handleBirthdate(date);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Geschlecht</Form.Label>
                            <Form.Select
                              className="form-control"
                              required
                              value={this.state.gender}
                              onChange={(event) => {
                                this.handleGender(event);
                              }}
                            >
                              <option>Auswählen...</option>
                              {this.state.genderList &&
                                this.state.genderList.length > 0 &&
                                this.state.genderList.map((gender) => {
                                  return (
                                    <option
                                      key={gender.value}
                                      value={gender.value}
                                    >
                                      {gender.name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group>
                            <Form.Label>Mitarbeitende zuweisen</Form.Label>
                            <Form.Select
                              className="form-control"
                              required
                              value={this.state.clinician}
                              onChange={() => {
                                this.handleClinician(event);
                              }}
                            >
                              <option>Auswählen...</option>
                              {this.state.clinicians &&
                                this.state.clinicians.length > 0 &&
                                this.state.clinicians.map((clinician) => {
                                  return (
                                    <option
                                      disabled={!clinician.enabled}
                                      key={clinician.id}
                                      value={clinician.username}
                                    >
                                      {clinician.username}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Row className="mb-3">
                          <Col>
                            <Form.Group>
                              <Form.Label>Diagnose</Form.Label>
                              <Typeahead
                                id="basic-typeahead-example"
                                selected={
                                  this.state.diagnosesPatient
                                  // Array.isArray(this.state.patient_diagnoses)
                                  //  ? this.state.diagnoses.filter(i => this.state.patient_diagnoses.includes(i.id))
                                  //  : undefined
                                }
                                multiple={false}
                                labelKey="description"
                                align={"left"}
                                options={this.state.diagnoses as Diagnose[]}
                                placeholder="Auswählen..."
                                clearButton={false}
                                onChange={(event) => {
                                  this.handleDiagnosis(event as Diagnose[]);
                                }}
                                maxResults={1000}
                                renderMenuItemChildren={(option) => (
                                  <div>
                                    <span>
                                      {(option as Diagnose).id} -{" "}
                                      {(option as Diagnose).description}
                                    </span>
                                  </div>
                                )}
                                filterBy={(option, searchText) => {
                                  return (
                                    (option as Diagnose).id
                                      .toLowerCase()
                                      .includes(
                                        searchText.text.toLowerCase(),
                                      ) ||
                                    (option as Diagnose).description
                                      .toLowerCase()
                                      .includes(searchText.text.toLowerCase())
                                  );
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <Form.Group>
                            <Form.Label>Aufnahmedatum festlegen</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              selected={this.state.admission}
                              onChange={(date) => {
                                this.handleAdmissionDate(date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Entlassdatum festlegen</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              selected={this.state.discharge}
                              minDate={this.state.admission}
                              onChange={(date) => {
                                this.handleDischargeDate(date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <PatientLabels
                        labels={this.state.labels}
                        setLabels={this.setLabels}
                      />
                      <Button
                        onClick={() =>
                          this.setState({ editPatientButton: "btn1" })
                        }
                        className="custom-btn"
                        variant="primary"
                        type="submit"
                        name="btn1"
                      >
                        Patient*in anlegen
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({ editPatientButton: "btn2" })
                        }
                        className="custom-btn"
                        variant="primary"
                        type="submit"
                        name="btn2"
                      >
                        Patient*in anlegen + weitere Patient*innen anlegen
                      </Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="danger" onClick={this.closeModal}>
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalBulkOpen}
                  onHide={this.closeBulkModal}
                  onClose={this.closeBulkModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Sammelimport Patient*innen</ModalTitle>{" "}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      noValidate
                      onSubmit={
                        !this.state.bulkValidation
                          ? this.validateBulkImport
                          : this.handleBulkImport
                      }
                    >
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Datei hochladen</Form.Label>
                        <Form.Control
                          type="file"
                          required
                          ref={this.fileInput}
                          onClick={() =>
                            this.setState({ bulkValidation: false })
                          }
                        ></Form.Control>
                      </Form.Group>
                      {!this.state.bulkValidation ? (
                        <Button type="submit" disabled={this.state.validation}>
                          {" "}
                          {this.state.validation ? (
                            <div>
                              <Spinner animation="border" /> Liste wird geprüft{" "}
                            </div>
                          ) : (
                            <div>
                              <GoChecklist />
                              Liste überprüfen{" "}
                            </div>
                          )}
                        </Button>
                      ) : (
                        <div>
                          <Button
                            className="custom-btn"
                            onClick={() =>
                              this.setState({ bulkImportButton: "validation" })
                            }
                            type="submit"
                            disabled={this.state.validation}
                          >
                            {this.state.validation ? (
                              <div>
                                <Spinner animation="border" /> Liste wird
                                geprüft{" "}
                              </div>
                            ) : (
                              <div>
                                <GoChecklist />
                                Liste überprüfen{" "}
                              </div>
                            )}
                          </Button>
                          <Button
                            className="custom-btn"
                            disabled={this.state.importing}
                            onClick={() =>
                              this.setState({ bulkImportButton: "upload" })
                            }
                            type="submit"
                          >
                            {this.state.importing ? (
                              <div>
                                <Spinner animation="border" />
                                Liste wird importiert
                              </div>
                            ) : (
                              <div>
                                <FiUpload /> Liste importieren
                              </div>
                            )}
                          </Button>
                        </div>
                      )}
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="danger" onClick={this.closeBulkModal}>
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalValidOpen}
                  onHide={this.closeValidModal}
                  size="xl"
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Daten überprüfen</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    {this.state.bulkData.valid
                      ? "Es wurden keine Fehler gefunden. Sie können die Patienten importieren."
                      : "In der Übersicht werden die fehlerhaften Einträge rot markiert. Diese Einträge müssen Sie in Ihrer Datei anpassen, bevor Sie diese nochmals hochladen. Ein Datum muss im Format TT.MM.JJJJ vorliegen."}

                    <Table hover>
                      <thead>
                        <tr>
                          <th className="table-without-top">Code</th>
                          <th className="table-without-top">Geburtsdatum</th>
                          <th className="table-without-top">Geschlecht</th>
                          <th className="table-without-top">ID Diagnose</th>
                          <th className="table-without-top">Mitarbeitende</th>
                          <th className="table-without-top">Aufnahmedatum</th>
                          <th className="table-without-top">Entlassdatum</th>
                          <th className="table-without-top">Labels</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.bulkData.patients &&
                          this.state.bulkData.patients.length > 0 &&
                          this.state.bulkData.patients.map((patient) => {
                            return (
                              <tr key={patient.items[0].value}>
                                <td
                                  className={
                                    !patient.items[0].valid
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[0].value}
                                </td>
                                <td
                                  className={
                                    !patient.items[1].valid
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[1].value}
                                </td>
                                <td
                                  className={
                                    !patient.items[2].valid
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[2].value}
                                </td>
                                <td
                                  className={
                                    !patient.items[3].valid
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[3].value}
                                </td>
                                <td
                                  className={
                                    !patient.items[4].valid
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[4].value}
                                </td>
                                <td
                                  className={
                                    !patient.items[5].valid
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[5].value}
                                </td>
                                <td
                                  className={
                                    !patient.items[6].valid
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[6].value}
                                </td>
                                <td
                                  className={
                                    !patient.items[7].valid
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[7].value.match(
                                    /value=(.*?)\)/,
                                  )?.[1] || "Value not found"}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>

                    <Button variant="primary" onClick={this.closeValidModal}>
                      Schließen
                    </Button>
                  </ModalBody>
                </Modal>

                <Modal
                  show={this.state.modalClinicianOpen}
                  onHide={this.closeClinicianModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Mitarbeitende anlegen</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleClinicianRegist}>
                      <Form.Group>
                        <Form.Label>Mitarbeitenden-ID</Form.Label>
                        <Form.Control
                          className="form-control"
                          required
                          type="username"
                          placeholder="Mitarbeitenden-ID"
                          onChange={(text) => {
                            this.handleUsername(text);
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Button className="custom-btn" type="submit">
                        Mitarbeitende anlegen
                      </Button>
                    </Form>
                  </ModalBody>
                </Modal>

                <div>
                  <LoadingModal
                    modalLoadingOpen={this.state.modalLoadingOpen}
                    closeLoadingModal={this.closeLoadingModal}
                  />
                </div>
              </div>
            </div>
            <Footer>
              {this.state.clinic_id}
              {this.state.clinic_name}
              {this.state.username}
              {this.state.user_type}
            </Footer>
          </div>
        </div>
      </div>
    );
  }
}
export default HomeClinicAdmin;
