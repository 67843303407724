import React, { createContext, useContext } from "react";
import { AuthContext, UserInfo } from "../AuthProvider";
import { AccordionData, AppContext } from "../AppProvider";
import { CntStws, Stws } from "../types/commonTypes";
import { Survey, SurveyTimeWindow } from "../types/surveyTypes";
import { ReportEntry } from "../types/reportTypes";
import {
  getCurrentSurveyTimeWindow,
  getPatientReports,
  getSurvey,
  patientGetSurveyTimeWindows,
} from "../services/getFunctions";
import {
  reportGetPatientReportsError,
  reportGetStwsError,
  reportGetSurveyError,
} from "../helper/alertBoxes";
import { useHistory } from "react-router-dom";

export interface HomePatientContextState {
  content?: string;
  stws: Stws[];
  survey: Survey;
  reports: ReportEntry[];
  currentSTW: SurveyTimeWindow;
  today: string;
  cntStws: CntStws;
  modalOpen: boolean;
  remainingTime: number;
  value: string;
  userInfo?: UserInfo;
  accordionData?: AccordionData;
}

export const initialState: HomePatientContextState = {
  content: "",
  survey: {
    dimension: {
      title: "",
      connector: "",
      footer: "",
      id: "",
      paragraph: "",
    },
    items: [],
    survey_session_id: 0,
  },
  stws: [],
  reports: [],
  currentSTW: {
    type: "",
    start: "",
    blocking: "",
    end: "",
  },
  today: new Date().toISOString().split("T")[0],
  value: "",
  cntStws: {
    current: 0,
    completed: 0,
    planned: 0,
  },
  modalOpen: false,
  remainingTime: 0,
};

export interface HomePatientContextType {
  state: HomePatientContextState;
  setState: (state: HomePatientContextState) => void;
  openSurvey: () => void;
  handleAccordion: (intput: string) => void;
  openReport: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const HomePatientContext = createContext<HomePatientContextType>({
  state: initialState,
  setState: () => {},
  openSurvey: () => {},
  openReport: () => {},
  handleAccordion: () => {},
});

function useHomePatientState(extraData: Partial<HomePatientContextState>) {
  const [state, setState] = React.useState({
    ...initialState,
    ...(extraData ?? {}),
  });

  const cntStws = (data: Stws[]) => {
    const cntStws = {
      current: 0,
      completed: 0,
      planned: 0,
    };

    const stws = [...data];

    for (let i = 0; i < data.length; i++) {
      const start = data[i].start;
      const end = data[i].end;
      const start_date = new Date(start).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const end_date = new Date(end).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      console.log(
        "Start: ",
        new Date(start).toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        "Heute: ",
        state.today,
      );

      stws[i] = {
        ...data[i],
        start_show: start_date,
        end_show: end_date,
      };
    }

    for (let j = 0; j < data.length; j++) {
      if (data[j].start <= state.today && data[j].end >= state.today) {
        cntStws.current++;
      } else if (data[j].end < state.today) {
        cntStws.completed++;
      } else if (data[j].start > state.today) {
        cntStws.planned++;
      }
    }
    return {
      stws,
      cntStws,
    };
  };

  /**
   *This function calls `patientGetSurveyTimeWindows` to get survey time windows (stws) data.
   *If successful, it processes the retrieved data using `cntStws` and returns the result.
   *If an error occurs, it reports the error using `reportGetStwsError` and returns the initial state.
   */

  const tryPatientGetSurveyTimeWindows = async () => {
    try {
      const result = await patientGetSurveyTimeWindows();
      return cntStws(result.stws);
    } catch (error) {
      reportGetStwsError(error);
      return {
        stws: initialState.stws,
        cntStws: initialState.cntStws,
      };
    }
  };

  /**
   * This function calls `getCurrentSurveyTimeWindow` to get the current survey time window data.
   * If successful, it returns the result as an object with `currentSTW` property.
   * If an error occurs, it reports the error using `reportGetStwsError` and returns the initial state.
   */

  const tryGetCurrentSurveyTimeWindow = async () => {
    try {
      const result = await getCurrentSurveyTimeWindow();
      return { currentSTW: result };
    } catch (error) {
      reportGetStwsError(error);
      return { currentSTW: initialState.currentSTW };
    }
  };

  /**
   *This function calls `getSurvey` to get the survey data.
   * If successful, it extracts and returns the survey as an object with a `survey` property.
   * If an error occurs, it reports the error using `reportGetSurveyError` and returns the initial state.
   */

  const tryGetSurvey = async () => {
    try {
      const result = await getSurvey();
      const { survey } = result;
      return { survey };
    } catch (error) {
      reportGetSurveyError(error);
      return { survey: initialState.survey };
    }
  };

  /**
   *This function calls `getPatientReports` to get the patient reports data.
   * If successful, it returns the reports as an object with a `reports` property containing the report entries.
   * If an error occurs, it reports the error using `reportGetPatientReportsError` and returns the initial state.
   */

  const tryGetPatientReports = async () => {
    try {
      const result = await getPatientReports();
      return { reports: result.report_entries };
    } catch (error) {
      reportGetPatientReportsError(error);
      return { reports: initialState.reports };
    }
  };

  /**
   * `useEffect` hook that asynchronously retrieves various pieces of survey-related data
   * when the component mounts and updates the state with the results.
   *
   *  Executes an immediately invoked asynchronous function.
   *  Retrieves data by calling four functions concurrently: `tryPatientGetSurveyTimeWindows`,
   *    `tryGetCurrentSurveyTimeWindow`, `tryGetSurvey`, and `tryGetPatientReports`.
   *  Combines the results into a single object, `resultState`.
   *  Updates the component's state with the combined results, merging them with the existing state.
   */

  React.useEffect(() => {
    (async function () {
      const results = await Promise.all([
        tryPatientGetSurveyTimeWindows(),
        tryGetCurrentSurveyTimeWindow(),
        tryGetSurvey(),
        tryGetPatientReports(),
      ]);
      const resultState = {};
      for (const r of results) {
        Object.assign(resultState, r);
      }
      setState({ ...state, ...resultState });
    })();
  }, []);

  return { state, setState };
}

/**
 * To manage state and context for home patient data.
 * The child component to be wrapped by this provider.
 * The provider component that supplies state and context to its children.
 */

const HomePatientProvider = ({ children }) => {
  const history = useHistory();
  const { state: authState } = useContext(AuthContext);
  const { state: appState, handleAccordion: appAccordionHandler } =
    useContext(AppContext);
  const { state, setState } = useHomePatientState({
    userInfo: authState.userInfo,
    accordionData: appState.accordionData,
  });

  /**Open patients survey. */
  const openSurvey = () => {
    history.push({
      pathname: "/patients/survey",
    });
  };

  /**Open patients report. */
  const openReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    // event.preventDefault();
    // console.log('Event: ', event.target.parentNode.parentElement.id);
    // const index = event.target.parentNode.parentElement.id;
    // const started_time = state.reports[index].started_time;
    // const started_time_date = started_time.split('T');
    // const started_time_split = started_time_date[0].split('-');
    // const started_time_format =
    //   started_time_split[2] +
    //   '.' +
    //   started_time_split[1] +
    //   '.' +
    //   started_time_split[0];
    // console.log('Datum: ', started_time_format);
    // history.push({
    //   pathname: '/patients/report',
    // });

    event.preventDefault();
    // Use currentTarget to get the element the event handler is attached to
    const buttonElement = event.currentTarget;

    // Attempt to find the grandparent element with an id
    const grandParentElement = buttonElement.closest("[id]");

    if (grandParentElement) {
      const index = grandParentElement.id; // Assuming the id is unique enough to serve as an index
      const started_time = state.reports[index].started_time;
      const started_time_date = started_time.split("T");
      const started_time_split = started_time_date[0].split("-");
      const started_time_format =
        started_time_split[2] +
        "." +
        started_time_split[1] +
        "." +
        started_time_split[0];
      console.log("Datum: ", started_time_format);
      history.push({
        pathname: "/patients/report",
      });
    }
  };

  return (
    <HomePatientContext.Provider
      value={{
        state,
        setState,
        handleAccordion: (input: string) =>
          appAccordionHandler({ input, userType: "patient" }),
        openSurvey,
        openReport,
      }}
    >
      {children}
    </HomePatientContext.Provider>
  );
};

export default HomePatientProvider;
