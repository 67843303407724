import axios from "axios";
import { retryIfTokenInvalid } from "./sessionManagement";
import { Labels } from "../components/sharedComponents/PatientLabels";
import { Stws } from "../types/commonTypes";

/**
 * All functions that read data from the API (GET requests) should go here
 */

/**
 * Gets a the list of clinics available
 * @returns
 */
export async function getClinics(): Promise<ClinicList> {
  const url = "/api/clinics";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the list of patients when signed in as admin. Not usable from another role
 * @returns
 */
export async function adminGetPatients(): Promise<PatientList> {
  const url = "/api/auth/clinic-admins/patients";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the list of clinicians when signed in as an admin.
 * @returns
 */
export async function adminGetClinicians(): Promise<ClinicianList> {
  const url = "/api/auth/clinic-admins/clinicians";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the list of diagnoses
 * @returns
 */
export async function getDiagnoses(): Promise<DiagnosesList> {
  const url = "/api/auth/diagnoses";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Resets the password of the clinician with the given id and returns the newly generated password.
 * Only usable when signed in as admin
 * @param clinicianId
 * @returns
 */
export async function adminResetClinicianPassword(
  clinicianId: string,
): Promise<string> {
  const url = "/api/auth/clinicians/" + clinicianId + "/new-password";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Activates the clinician with the given id.
 * @param clinicianId
 * @returns
 */
export async function activateClinician(clinicianId: number): Promise<void> {
  const url = "/api/auth/clinicians/" + clinicianId + "/activation";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Deactivates the clinician with the given id.
 * @param clinicianId
 * @returns
 */
export async function deactivateClinician(clinicianId: number): Promise<void> {
  const url = "/api/auth/clinicians/" + clinicianId + "/deactivation";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the clinician reports for the patient with the given patient code.
 * @param patientCode
 * @returns
 */
export async function getClinicianReportsForPatient(
  patientCode: string,
): Promise<ReportList> {
  //not sure if that is right, i just assume that that's what the api endpoint does
  const url = "/api/auth/clinician-reports/patients/" + patientCode;
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the clinician report for the given survey session id. Only usable when signed in as clinician.
 * @param surveySessionId
 * @returns
 */
export async function getClinicianReport(
  surveySessionId: number,
): Promise<ClinicianReport> {
  const url =
    "/api/auth/clinicians/survey-sessions/" + surveySessionId + "/reports";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets a list of patient reports of the patient with the given id. Only usable as clinician.
 * This function can NOT be used when signed in as a patient. Use the respective function for patients instead!
 * @param patientCode
 * @returns
 */
export async function clinicianGetPatientReportsList(
  patientCode: string,
): Promise<ReportList> {
  //it looks like this is returning a list of all patient reports.
  //Then the client side has to check if the report for the survey session currently looked at
  //is part of that list
  //@rebecca why is it done like this instead of just using
  const url = "/api/auth/clinicians/patient-reports/patients/" + patientCode;
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets a list of patients of the currently signed in clinician
 * FIXME:
 * @returns
 */
export async function clinicianGetPatients(): Promise<PatientList> {
  const url = "/api/auth/clinicians/patients";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the list of survey time windows of the currently signed in patient. Only usable when signed in as patient.
 * @returns
 */
export async function patientGetSurveyTimeWindows(): Promise<SurveyTimeWindowForPatients> {
  const url = "/api/auth/patients/stws";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the list of survey time windows of the patient with the given patient code.
 * Not entirely sure for which roles this endpoint works. I suspect it works for the role clinician and admin.
 * @params patientCode
 * @returns
 */
export async function clinicianGetSurveyTimeWindows(
  patientCode: string,
): Promise<SurveyTimeWindow[]> {
  const url = "/api/auth/clinics/patients/stws";
  const response = await genericGetWithRetry(url, {
    params: {
      patient_code: patientCode,
    },
  });

  return response.data;
}

/**
 * Gets the current survey time window of the currently signed in patient.
 * @returns
 */
export async function getCurrentSurveyTimeWindow(): Promise<SurveyTimeWindow> {
  const url = "/api/auth/patients/stws/now";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the list of patient reports when signed in as patient
 * @returns
 */
export async function getPatientReports(): Promise<ReportList> {
  const url = "/api/auth/patients/patient-reports/patients";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the patient report with the given surveySessionId. Only usable when signed in as patient
 * @param surveySessionId
 * @returns
 */
export async function getPatientReport(
  surveySessionId: number,
): Promise<PatientReport> {
  const url =
    "/api/auth/patients/survey-sessions/" + surveySessionId + "/reports";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets the current survey items if available. Only usable when signed in as patient.
 * @returns
 */
export async function getSurvey(): Promise<SurveyResponse> {
  const url = "/api/auth/patients/survey";
  const response = await genericGetWithRetry(url);

  return {
    statusCode: response.status,
    survey: response.data,
  };
}

/**
 * Gets the severity of the given survey session id
 * @param surveySessionId
 * @returns
 */
export async function getSeverity(surveySessionId: number): Promise<string> {
  const url =
    "/api/auth/surveys/" + surveySessionId + "/patient-condition-severity";
  const response = await genericGetWithRetry(url);

  return response.data;
}

/**
 * Gets severities for multiple survey session ids at once. In the background it does multiple
 * calls to getSeverity in parallel to save time
 * @param surveySessionIds
 * @returns
 */
export async function getSeverities(
  surveySessionIds: number[],
): Promise<string[]> {
  const promises = surveySessionIds.map((surveySessionId) => {
    return getSeverity(surveySessionId);
  });
  const responses = await Promise.all(promises);
  return responses;
}

/**
 * This is a generic function used to get files/do http get requests..
 * If it gets a 403 status code the first time (=token invalid) it triggers
 * a renew of the token and tries to do the original request again. If that second try fails too then the
 * error is passed along. Other errors then status code 403 are always passed along.
 *
 * @param url
 * @param additionalParams optional object of additional parameters that will be passed to axios
 */
// TODO: remove eslint disables, see issue rehacat_frontend#335
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function genericGetWithRetry(url, additionalParams = {}): Promise<any> {
  return await retryIfTokenInvalid(async () => {
    return await axios.get(url, {
      withCredentials: true,
      ...additionalParams,
    });
  });
}

interface ClinicList {
  clinics: Clinic[];
}

export interface Clinic {
  name: string;
  id: string;
  contact: string;
}

interface PatientList {
  list_patient_dto: Patient[];
}

export interface Patient {
  viewed: string;
  duplicate: boolean;
  date_of_birth: string;
  admission: string;
  discharge: string;
  code: string;
  clinician_username: string;
  created: string;
  clinician_id: string;
  diagnoses: DiagnosesList;
  gender: string; //maybe reduce to the possible values
  id: string;
  survey_statuses: SurveyState[];
  survey_time_windows: SurveyTimeWindow[];
  labels: Labels;
}

export interface SurveyState {
  survey_session_id: string;
  started_time: string;
  update_time: string;
  stw_type: string;
  finished: boolean;
  report_viewed: boolean;
  condition_severity: string;
}

interface ClinicianList {
  clinicians: Clinician[];
}

export interface Clinician {
  id: string;
  username: string;
  enabled: boolean;
  password: string;
  edit: JSX.Element;
  patientCount: number;
}

type DiagnosesList = Diagnosis;

export interface Diagnosis {
  diagnosis: Diagnose[];
}
export interface Diagnose {
  description: string;
  id: string;
}

type ClinicianReport = string;

interface ReportList {
  report_entries: ReportEntry[];
}

interface SurveyTimeWindowForPatients {
  stws: Stws[];
}

export interface ReportEntry {
  started_time: string;
  survey_session_id: number;
  stw_type: string;
}

export interface SurveyTimeWindow {
  type: string; //todo restrict only to possible values
  start: string;
  blocking: string;
  end: string;
}

type PatientReport = string;

interface SurveyResponse {
  statusCode: number;
  survey: Survey;
}
export interface Survey {
  dimension: SurveyDimension;
  items: SurveyItem[];
  survey_session_id: number;
}

interface SurveyDimension {
  connector: string;
  paragraph: string;
  title: string;
  id: string;
  footer: string;
}

export interface SurveyItem {
  type: string;
  content: string;
  response_options: ResponseOption[];
  id: number;
  head: boolean;
  value: number;
  group: boolean;
}

export interface ResponseOption {
  id: string;
  label: string;
  value: string;
}
