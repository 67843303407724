import { Col, Row, Tooltip } from "react-bootstrap";
import React from "react";
import { Card, Container, Form, OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Clinic, getClinics } from "../../services/getFunctions";
import { RiInformationFill } from "react-icons/ri";
import { BiHide, BiShow } from "react-icons/bi";
import {
  showAlertModal,
  reportNoClinicSelected,
} from "../../helper/alertBoxes";
import { RouteComponentProps } from "react-router-dom";
import { AuthContextType, withAuthContext } from "../../AuthProvider";
import { ILoginData } from "../../types/authTypes";
import Logo from "../../img/Logo.svg";
import Footer from "../Footer";
/**
 * Component for clinician login.
 */
interface LoginClinicianState {
  value: string;
  clinicname: string;
  username: string;
  password: string;
  result: string;
  isInvalid: boolean;
  unauthorized: boolean;
  clinics: Clinic[];
  hiddenPw: boolean;
}
interface LoginClinicianProps {
  // TODO: remove eslint disables, see issue rehacat_frontend#335
  location: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  title: string;
  history: RouteComponentProps["history"];
  authContext: AuthContextType;
}

class LoginClinician extends React.Component<
  LoginClinicianProps,
  LoginClinicianState
> {
  constructor(props: LoginClinicianProps) {
    super(props);

    this.state = {
      value: "",
      clinicname: "",
      username: "",
      password: "",
      result: "",
      isInvalid: true,
      unauthorized: true,
      clinics: [],
      hiddenPw: true,
    };
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleClinicId = this.handleClinicId.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.showPassword = this.showPassword.bind(this);
  }

  /**Loads clinic list from backend. */
  componentDidMount() {
    document.title = this.props.title;
    try {
      getClinics()
        .then((clinicList) => {
          console.log("Klinik-Liste: ", clinicList);
          this.setState({
            clinics: clinicList.clinics,
          });
        })
        .catch((error) => {
          console.error("Klinikliste Fail: ", error);
        });
    } catch (error) {
      /*empty*/
    }
  }

  /**Method to update the clinic ID state, when it is changed.
   * @param event {ChangeEvent} onChange delivers the event with the selection option
   */
  handleClinicId(event) {
    const clinicId = event.target.value;
    const clinic = this.state.clinics.find((c) => {
      return c.id === clinicId;
    });

    if (!clinic) {
      this.setState({ value: clinicId, clinicname: "" });
      return;
    }

    const clinicName = clinic.name;
    this.setState({ value: clinicId, clinicname: clinicName });
    console.log("Klinik-ID: ", this.state.value, "Klinikname: ", clinicName);
  }

  /**Method to update the username state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleUsername(text) {
    console.log("Username", this.state.username, " ausgefüllt");
    this.setState({ username: text.target.value });
  }

  /**Method to update the password state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handlePassword(text) {
    console.log("Passwort", this.state.password, " ausgefüllt");
    this.setState({ password: text.target.value });
  }

  /**Change hiddenPw state on true or false. */
  showPassword() {
    this.setState((prevState) => ({ hiddenPw: !prevState.hiddenPw }));
  }

  /**
   * Method for handle the login.
   * The input data are stringified to JSON.
   * Then send JSON to backend API and handle the response.
   */
  handleLogin = async (event) => {
    event.preventDefault();
    // console.log("Button aktiviert");
    const { login } = this.props.authContext;

    if (!this.state.username) {
      console.log("Kein Username");

      return;
    }
    if (!this.state.password) {
      console.log("Kein Passwort");
      return;
    }

    if (!this.state.value || this.state.value === "") {
      console.log("Keine Klinik");
      reportNoClinicSelected();
      return;
    }

    const loginData: ILoginData = {
      clinic_id: this.state.value,
      username: this.state.username,
      password: this.state.password,
      userType: "clinician",
    };
    await login(loginData, (authResponse: string) => {
      if (authResponse === "success") {
        this.setState({ unauthorized: false });
        this.props.history.push({
          pathname: "/clinician",
        });
      } else if (authResponse === "credentials_wrong") {
        showAlertModal(
          "Ihre Anmeldedaten sind fehlerhaft oder Ihr Account wurde noch nicht freigeschaltet.",
        );
      } else if (authResponse === "no_permissions") {
        showAlertModal("Sie haben für diese Seite keine Berechtigung.");
      } else {
        showAlertModal(
          "Irgendetwas ist schief gelaufen. Bitte versuchen Sie es erneut.",
        );
      }
    });
  };

  /**React render method. */
  render() {
    return (
      <Container className="container d-flex align-items-center justify-content-center">
        <Card className="card login col-lg-6 col-md-10">
          {/* WarningBanner component */}
          <div className="warning-banner">
            Bitte stellen Sie sicher, dass sie keine Übersetzungsplugins für
            diese Seite nutzen
          </div>
          <Card.Header className="card-header d-flex justify-content-center">
            <img src={Logo} alt="Logo" width="230px" height="auto" />
          </Card.Header>
          <Card.Body className="p-5">
            <Card.Title className="text-dark mb-5">
              Anmeldung Mitarbeitende
            </Card.Title>
            <Form noValidate validated onSubmit={this.handleLogin}>
              <Form.Group controlId="formGroupClinic">
                <Form.Label className="">
                  Ambulanz{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip role="tooltip">
                        Ihre Ambulanz ist in der Liste nicht enthalten? Wenden
                        Sie sich bitte an sysdok@uniklinik-freiburg.de
                      </Tooltip>
                    }
                  >
                    <span className="mdi">
                      <RiInformationFill />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  aria-describedby="tooltipClinic"
                  className="form-control"
                  required
                  defaultValue=""
                  onChange={(event) => {
                    this.handleClinicId(event);
                  }}
                >
                  {this.state.clinics &&
                    this.state.clinics.length > 0 &&
                    [
                      {
                        name: "Bitte auswählen",
                        id: "",
                      },
                      ...this.state.clinics,
                    ].map((clinic, index) => {
                      return (
                        <option key={index} value={clinic.id}>
                          {clinic.name}
                        </option>
                      );
                    })}
                </Form.Select>
                <div
                  role="tooltip"
                  className="tooltip-keyboard"
                  id="tooltipClinic"
                >
                  {" "}
                  Hilfe: Ist Ihre Ambulanz in der Liste nicht enthalten? Wenden
                  Sie sich bitte an sysdok@uniklinik-freiburg.de
                </div>
              </Form.Group>
              <Form.Group controlId="formGroupCode">
                <Form.Label className="login-label">
                  Mitarbeitenden-ID{" "}
                </Form.Label>

                <Form.Control
                  aria-describedby="tooltipClinicianID"
                  className="form-control"
                  required
                  type="username"
                  placeholder="Mitarbeitenden-ID"
                  onChange={(text) => {
                    this.handleUsername(text);
                  }}
                />
                <Form.Control.Feedback
                  type="invalid"
                  role="tooltip"
                  id="tooltipClinicianID"
                >
                  Bitte geben Sie Ihre Mitarbeitenden-ID ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupPassword">
                <Row className="login-form-control">
                  <Col sm="2" xs="4">
                    <Form.Label className="">Passwort</Form.Label>
                  </Col>
                  <Col>
                    <div className="">
                      {this.state.hiddenPw ? (
                        <Button
                          className="passwordShow"
                          variant=""
                          id="show"
                          onClick={this.showPassword}
                        >
                          <BiShow
                            className="hideShowPw"
                            id="show"
                            aria-label="Passwort einblenden"
                          />
                        </Button>
                      ) : (
                        <Button
                          className="passwordShow"
                          variant=""
                          id="hide"
                          onClick={this.showPassword}
                        >
                          <BiHide
                            className="hideShowPw"
                            id="hide"
                            aria-label="Passwort ausblenden"
                          />
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
                <Form.Control
                  className="form-control"
                  required
                  type={this.state.hiddenPw ? "password" : "text"}
                  placeholder="Passwort"
                  onChange={(text) => {
                    this.handlePassword(text);
                  }}
                />
              </Form.Group>
              <OverlayTrigger
                overlay={
                  <Tooltip className="tooltip" role="tooltip">
                    Wenn Sie Ihre Zugangsdaten vergessen haben, wenden Sie sich
                    an Ihre*n Administrator*in.
                  </Tooltip>
                }
              >
                <Button aria-describedby="tooltipLink" variant="link">
                  Zugangsdaten vergessen?
                </Button>
              </OverlayTrigger>
              <div hidden id="tooltipLink">
                Wenn Sie Ihre Zugangsdaten vergessen haben, wenden Sie sich an
                Ihren Administrator.
              </div>
              <div className="d-grid gap-2">
                <Button variant="primary" type="submit">
                  Anmelden
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <Footer>{"clinician"}</Footer>
      </Container>
    );
  }
}

export default withAuthContext(LoginClinician);
