import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export interface Label {
  key: string;
  value: string;
}

export type Labels = Label[];

interface PatientLabelsProps {
  labels: Labels;
  setLabels: (labels: Labels) => void;
}

export const PatientLabels = ({ labels, setLabels }: PatientLabelsProps) => {
  /**Handle add labels */
  const handleAddLabel = (e) => {
    if (e.key !== "Enter") return;
    e.preventDefault();
    const value = e.target.value;

    if (!value.trim()) return;

    const label = { key: "CLUSTER", value: value };
    setLabels([...labels, label]);
    e.target.value = "";
  };

  /**Handle delete labels */
  const handleRemoveLabel = (index) => {
    setLabels([...labels.filter((el, i) => i !== index)]);
  };

  return (
    <Row className="mb-3">
      <Col>
        <Form.Group controlId="formGridCode">
          <Form.Label>Labels</Form.Label>
          <div style={{ marginBottom: "15px" }}>
            {labels
              .filter((label) => label.key === "CLUSTER")
              .map((label, index) => (
                <div className="tag-item" key={index}>
                  <span className="text">{label.value}</span>
                  {/* TODO: remove eslint disables, see issue rehacat_frontend#335 */}
                  {/* eslint-disable-next-line */}
                  <span
                    className="close"
                    onClick={() => handleRemoveLabel(index)}
                  >
                    &times;
                  </span>
                </div>
              ))}
          </div>
          <Form.Control
            className="form-control"
            type="text"
            placeholder="Add a tag"
            onKeyDown={handleAddLabel}
            pattern="[a-zA-Z0-9\s]+"
          ></Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};
