import React, { useState, useContext, FC } from "react";
import { Button } from "react-bootstrap";
import {
  BsBellFill,
  BsFiles,
  BsFillPersonFill,
  BsQuestionSquareFill,
} from "react-icons/bs";
import { IoMdInformationCircle, IoIosDocument } from "react-icons/io";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PubSub } from "../../helper/pubSub";
import { AppContext, AppContextType } from "../../AppProvider";
import LogoUulm from "../../img/Logo_uulm.png";
import HospitalLogo from "../../img/user/hospital.png";
import "./Sidebar.css";

const SidebarPatient: FC = () => {
  const { state: appState, handleAccordion } =
    useContext<AppContextType>(AppContext);
  const location = useLocation();
  const generalPaths = ["/impressum", "/privacypolicy", "/contact"]; //all paths belonging to general section. For these paths the menu stays open
  const history = useHistory();
  const [generalShown, setGeneralShown] = useState(
    generalPaths.includes(location.pathname),
  );

  PubSub.getInstance().subscribeToTopic(
    "open-sidebar-submenu",
    ({ menuName }) => {
      if (menuName === "current") {
        handleCurrent();
      }
    },
  );
  const handleSidebarItemClick = (input: string) => {
    handleAccordion({ input, userType: "patient" });
    if (!location.pathname.endsWith("patients")) {
      history.replace({ pathname: "/patients" });
    }
  };

  const handleCurrent = () => {
    handleSidebarItemClick("current");
  };

  const handleProfile = () => {
    handleSidebarItemClick("info");
  };

  const handleSurvey = () => {
    handleSidebarItemClick("survey");
  };

  const handleReport = () => {
    handleSidebarItemClick("report");
  };

  /* Duplicate code from SidebarClinician.tsx */
  const handleClinicItem = () => {
    handleSidebarItemClick("clinics");
  };

  const handleGeneral = () => {
    setGeneralShown(!generalShown);
  };

  const renderCurrent = () => (
    <Button
      title="Aktuelles"
      aria-label="Aktuelles"
      variant=""
      className={
        appState.accordionData?.accordionCurrent !== "-1"
          ? "active sidenav-item-link"
          : "sidenav-item-link"
      }
      onClick={handleCurrent}
    >
      <i className="mdi">
        <BsBellFill />
      </i>
      <span className="nav-text">Aktuelles</span> <strong className="caret" />
    </Button>
  );

  const renderProfile = () => (
    <Button
      title="Profil"
      aria-label="Profil"
      variant=""
      className={
        appState.accordionData?.accordionInformation !== "-1"
          ? "active sidenav-item-link"
          : "sidenav-item-link"
      }
      onClick={handleProfile}
    >
      <i className="mdi">
        <BsFillPersonFill />
      </i>
      <span className="nav-text">Profil</span> <strong className="caret" />
    </Button>
  );

  const renderSurvey = () => (
    <Button
      title="Befragungen"
      aria-label="Befragungen"
      variant=""
      className={
        appState.accordionData?.accordionSurvey !== "-1"
          ? "active sidenav-item-link"
          : "sidenav-item-link"
      }
      onClick={handleSurvey}
    >
      <i className="mdi">
        <BsQuestionSquareFill />
      </i>
      <span className="nav-text">Befragungen</span> <strong className="caret" />
    </Button>
  );

  const renderReports = () => (
    <Button
      title="Berichte"
      aria-label="Berichte"
      variant=""
      className={
        appState.accordionData?.accordionReport !== "-1"
          ? "active sidenav-item-link"
          : "sidenav-item-link"
      }
      onClick={handleReport}
    >
      <i className="mdi">
        <BsFiles />
      </i>
      <span className="nav-text">Berichte</span> <strong className="caret" />
    </Button>
  );

  const renderClinics = () => (
    <Button
      title="Ambulanz"
      aria-label="Ambulanz"
      variant=""
      className={
        appState.accordionData?.accordionMyClinics !== "-1"
          ? "active sidenav-item-link"
          : "sidenav-item-link"
      }
      onClick={handleClinicItem}
    >
      <i className="mdi">
        <img
          className="rounded-circle user-role"
          src={HospitalLogo}
          width="25px"
          alt="Ambulanz"
        />
      </i>
      <span className="nav-text">Ambulanz</span> <strong className="caret" />
    </Button>
  );

  const renderInformation = () => (
    <Link
      title="Informationen"
      aria-label="Informationen"
      id="sidenav-link"
      className={
        location.pathname === "/patients/information"
          ? "active sidenav-item-link"
          : "sidenav-item-link"
      }
      to={{
        pathname: "/patients/information",
      }}
    >
      <i className="mdi">
        <IoMdInformationCircle />
      </i>
      <span className="nav-text">Informationen</span>{" "}
      <strong className="caret" />
    </Link>
  );

  const renderGeneralSubMenu = () => (
    <ul
      className={generalShown ? "show" : "collapse"}
      id="documentation"
      data-parent="#sidebar-menu"
    >
      <div className="sub-menu p-0">
        <Link
          to={{
            pathname: "/impressum",
          }}
        >
          <span className="nav-text" role="menuitem">
            Impressum
          </span>
        </Link>
        <Link
          to={{
            pathname: "/privacypolicy",
          }}
        >
          <span className="nav-text" role="menuitem">
            Datenschutz
          </span>
        </Link>

        <Link
          to={{
            pathname: "/contact",
          }}
        >
          <span className="nav-text" role="menuitem">
            Kontakt
          </span>
        </Link>
      </div>
    </ul>
  );

  const renderGeneral = () => (
    <div tabIndex={-1}>
      <Button
        role="menu"
        title="Allgemeines"
        aria-label="Allgemeines"
        variant=""
        className={
          generalShown ? "active sidenav-item-link" : "sidenav-item-link"
        }
        onClick={handleGeneral}
        id="patient-sidebar-general"
        data-toggle="collapse"
        data-target="#documentation"
        // aria-expanded="false"
        aria-controls="documentation"
      >
        <i className="mdi">
          <IoIosDocument />
        </i>
        <span className="nav-text">Allgemeines</span>{" "}
        <strong className="caret" />
      </Button>
      {renderGeneralSubMenu()}
    </div>
  );

  return (
    <div>
      <div
        role="navigation"
        id="sidebar"
        className="sidebar sidebar-with-footer"
      >
        <div data-simplebar data-height="100%">
          <ul className="nav sidebar-inner" id="sidebar-menu">
            {renderCurrent()}
            {renderProfile()}
            {renderSurvey()}
            {renderReports()}
            {renderClinics()}
            <hr className="separator mb-0" />
            {renderInformation()}
            {renderGeneral()}
          </ul>
        </div>

        <div className="sidebar-footer sidebar-footer-content">
          <a
            id="sidebar-logo"
            href="https://www.uni-ulm.de/in/abt-klinische-psychologie-und-psychotherapie"
            target="blank"
          >
            <img
              src={LogoUulm}
              width="160px"
              alt="Logo Universität Ulm: Öffnet neuen Tab: Universitätswebseite der Abteilung klinische Psychologie und Psychotherapie"
              title="Öffnet neuen Tab: Universisätswebseite der Abteilung klinische Psychologie und Psychotherapie"
            />
          </a>
          <a
            id="sidebar-logo-two"
            href="https://www.uniklinik-freiburg.de/de.html"
            target="blank"
          >
            <img
              src={"../../img/UKF_LOGO.png"}
              width="160px"
              alt="Logo Universitätsklinikum Freiburg: Öffnet neuen Tab: Universitätsklinikumwebseite"
              title="Öffnet neuen Tab: Universisätsklinikumwebseite"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarPatient;
