import React from "react";
import { Modal, ModalBody, ModalTitle, Nav, Navbar } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { Link } from "react-router-dom";
import {
  AuthContextState,
  AuthContextType,
  withAuthContext,
} from "../AuthProvider";

/**Footer component */
interface FooterState {
  authState?: AuthContextState;
  modalContactOpen: boolean;
}
interface FooterProps {
  children?: React.ReactNode;
  authContext: AuthContextType;
}
class Footer extends React.Component<FooterProps, FooterState> {
  preservedSpace = React.createRef<HTMLDivElement>();

  constructor(props: FooterProps) {
    super(props);
    this.state = {
      authState: undefined,
      modalContactOpen: false,
    };
  }

  /**Checks wheter the user is logged in or not.
   * If the user is logged in, the states would be set.
   */
  componentDidMount() {
    const { state: authState } = this.props.authContext;

    this.setState({
      authState,
    });
  }

  openContactModal = () => {
    this.setState({ modalContactOpen: true });
  };

  closeContactModal = () => this.setState({ modalContactOpen: false });

  /**Preserved footer space*/
  preservedFooter() {
    return (
      <footer>
        <div ref={this.preservedSpace}></div>
      </footer>
    );
  }

  /**React render method */
  render() {
    const { userInfo, isAuthenticated } = this.state.authState || {};
    return (
      <>
        <Navbar
          role="contentinfo"
          className="footer-custom nav-custom"
          fixed="bottom"
        >
          <NavbarCollapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Item>
                {isAuthenticated ? (
                  <Link
                    className="footer-link"
                    to={{
                      pathname: "/impressum",
                    }}
                  >
                    <span className="nav-text">Impressum</span>
                  </Link>
                ) : (
                  <Nav.Link
                    className="footer-link footer-nav-link"
                    href="/impressum"
                  >
                    Impressum
                  </Nav.Link>
                )}
              </Nav.Item>
              <Nav.Item>
                {isAuthenticated ? (
                  <Link
                    className="footer-link"
                    to={{
                      pathname: "/privacypolicy",
                    }}
                  >
                    <span className="nav-text">Datenschutz</span>
                  </Link>
                ) : (
                  <Nav.Link
                    className="footer-link footer-nav-link"
                    href="/privacypolicy"
                  >
                    Datenschutz
                  </Nav.Link>
                )}
              </Nav.Item>
              <Nav.Item>
                {isAuthenticated ? (
                  <Link
                    className="footer-link"
                    to={{
                      pathname: "/contact",
                    }}
                  >
                    <span className="nav-text">Kontakt</span>
                  </Link>
                ) : (
                  <Nav.Link
                    className="footer-link footer-nav-link"
                    onClick={this.openContactModal}
                  >
                    Kontakt
                  </Nav.Link>
                  // <Nav.Link href="/contact">Kontakt</Nav.Link>
                )}
              </Nav.Item>
            </Nav>
          </NavbarCollapse>
        </Navbar>

        <Modal
          show={this.state.modalContactOpen}
          onHide={this.closeContactModal}
        >
          <ModalHeader closeButton>
            <ModalTitle>Kontakt</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {userInfo?.userType === "patient" ? (
              <p>Bitte wenden Sie sich an Ihr zuständiges Klinikpersonal.</p>
            ) : userInfo?.userType === "clinician" ? (
              <p>Bitte wenden Sie sich an Ihre*n Klinikadministrator*in.</p>
            ) : (
              <p>
                Bitte wenden Sie sich an{" "}
                <a href="mailto:rehacat.technik@uni-ulm.de">
                  rehacat.technik@uni-ulm.de
                </a>
                .{" "}
              </p>
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withAuthContext(Footer);
