import React, { FC, useContext, useEffect } from "react";
import TimeoutSession from "../helper/TimeoutSession";
import Footer from "./Footer";
import NavigationBar from "./navigation/NavigationBar";
import { AuthContext, AuthContextState } from "../AuthProvider";
import { AppContext } from "../AppProvider";
import SidebarAdmin from "./sidebars/SidebarAdmin";
import SidebarClinician from "./sidebars/SidebarClinician";
import SidebarPatient from "./sidebars/SidebarPatient";

interface ContactProps {
  title: string;
}

const Contact: FC<ContactProps> = ({ title }) => {
  const { state: authState } = useContext(AuthContext);
  const { handleAccordion } = useContext(AppContext);
  const { userInfo } = authState as AuthContextState;

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="header-fixed" id="body">
      <TimeoutSession />
      <div className="skip-link-div">
        <a className="skip-link" href="#main-content">
          Direkt zum Inhalt springen
        </a>
      </div>
      <NavigationBar />
      <div className="page-wrapper-new">
        <div className="wrapper">
          <div className="skip-sidebar-div">
            <a className="skip-sidebar" href="#main-content">
              Navigation überspringen
            </a>
          </div>
          {userInfo?.userType === "patient" ? <SidebarPatient /> : null}
          {userInfo?.userType === "clinician" ? (
            <SidebarClinician
              handleAccordion={() =>
                handleAccordion({ userType: userInfo?.userType })
              }
            />
          ) : null}
          {userInfo?.userType === "admin" ? (
            <SidebarAdmin
              handleAccordion={() =>
                handleAccordion({ userType: userInfo?.userType })
              }
            />
          ) : null}

          <div className="content-wrapper">
            <div className="content" id="main-content">
              <h3>Kontakt</h3>
              {userInfo?.userType === "patient" ? (
                <div>
                  <p>
                    Häufige Fragen werden{" "}
                    <a
                      href="https://sysdok.de/de/fuer-patientinnen/#FAQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      hier
                    </a>{" "}
                    beantwortet.
                  </p>
                  <p>
                    Bei weiteren technischen Fragen, kontaktieren Sie bitte
                    zuerst Ihre*n Therapeut*in. Sollte Ihr*e Therapeut*in nicht
                    verfügbar sein, kontaktieren Sie bitte Ihr
                    Ambulanzsekretariat.
                  </p>
                </div>
              ) : userInfo?.userType === "clinician" ? (
                <div>
                  <p>
                    Bei technischen Fragen, kontaktieren Sie bitte Ihr
                    Ambulanzsekretariat, bzw. den*die SysDok-Administrator*in
                    Ihrer Ambulanz.
                  </p>
                  <p>
                    Häufige Fragen werden{" "}
                    <a
                      href="https://sysdok.de/de/fuer-therapeutinnen/#FAQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      hier
                    </a>{" "}
                    beantwortet.{" "}
                  </p>
                  <p>
                    Weitere inhaltliche Fragen zur SysDok Studie können Sie hier
                    stellen:{" "}
                  </p>
                  <p>
                    <a href="mailto:sysdok@uniklinik-freiburg.de">
                      sysdok@uniklinik-freiburg.de
                    </a>
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    {" "}
                    E-Mail:{" "}
                    <a href="mailto:sysdok@uniklinik-freiburg.de">
                      sysdok@uniklinik-freiburg.de
                    </a>
                  </p>
                  <p>Telefon: +49 761 270 68841</p>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Contact;
