import React, { Fragment, useContext } from "react";
import RehacatModal from "./helper/RehactModal";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Start from "./components/Start";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Impressum from "./components/Impressum";
import Contact from "./components/Contact";
import HomePatient from "./components/componentsPatient/HomePatient";
import RunSurvey from "./components/componentsPatient/RunSurvey";
import PatientReport from "./components/componentsPatient/PatientReport";
import HomeClinician from "./components/componentsClinician/HomeClinician";
import HomeClinicAdmin from "./components/componentsClinicAdmin/HomeClinicAdmin";
import LoginPatient from "./components/componentsPatient/LoginPatient";
import LoginClinician from "./components/componentsClinician/LoginClinician";
import LoginClinicAdmin from "./components/componentsClinicAdmin/LoginClinicAdmin";
import Registration from "./components/componentsClinicAdmin/Registration";
import ClinicianReport from "./components/componentsClinician/ClinicianReport";
import InformationPagePatient from "./components/componentsPatient/InformationPagePatient";
import InformationPageClinician from "./components/componentsClinician/InformationPageClinician";
import InformationPageAdmin from "./components/componentsClinicAdmin/InformationPageAdmin";
import { AuthContext } from "./AuthProvider";
import HomePatientProvider from "./contexts/HomePatientContext";

declare let window;

export const documentTitle = window.APP_CONFIG.REACT_APP_REHACAT_DOCUMENT_TITLE;

const Page404 = () => <h1> 404 Not Found </h1>;

const App: React.FC = () => {
  const { state: authState } = useContext(AuthContext);
  const { isAuthenticated } = authState;
  console.log("Titel: ", documentTitle);
  console.log("Using " + process.env.NODE_ENV + " build.");
  let logLevel = "info";
  if (window.APP_CONFIG.REACT_APP_REHACAT_LOG_LEVEL) {
    logLevel = window.APP_CONFIG.REACT_APP_REHACAT_LOG_LEVEL;
  }
  if (process.env.NODE_ENV === "production") {
    switch (logLevel) {
      case "debug":
        break;
      case "info":
        console.debug = function () {
          /* empty */
        };
        console.log = function () {
          /* empty */
        };
        break;
      case "warning":
        console.debug = function () {
          /* empty */
        };
        console.log = function () {
          /* empty */
        };
        console.info = function () {
          /* empty */
        };
        break;
      case "error":
        console.log = function () {
          /* empty */
        };
        console.warn = function () {
          /* empty */
        };
        console.debug = function () {
          /* empty */
        };
        console.info = function () {
          /* empty */
        };
        break;
      default:
        console.debug = function () {
          /* empty */
        };
        console.log = function () {
          /* empty */
        };
        break;
    }
  }

  return (
    <Fragment>
      <RehacatModal topic="alert" />
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Start {...props} title={documentTitle} />}
          />
          <Route
            path="/loginpatient"
            render={(props) => (
              <LoginPatient
                {...props}
                title={documentTitle + " | Login Patient"}
              />
            )}
          />
          <Route
            path="/loginclinician"
            render={(props) => (
              <LoginClinician
                {...props}
                title={documentTitle + " | Login Mitarbeitende"}
              />
            )}
          />
          <Route
            path="/loginadmin"
            render={(props) => (
              <LoginClinicAdmin
                {...props}
                title={documentTitle + " | Login Admin"}
              />
            )}
          />
          <Route
            path="/regist"
            render={(props) => (
              <Registration
                {...props}
                title={documentTitle + " | Regisitrierung"}
              />
            )}
          />
          <Route
            path="/privacypolicy"
            render={(props) => (
              <PrivacyPolicy
                {...props}
                title={documentTitle + " | Datenschutz"}
              />
            )}
          />
          <Route
            path="/impressum"
            render={(props) => (
              <Impressum {...props} title={documentTitle + " | Impressum"} />
            )}
          />
          <Route
            path="/contact"
            render={(props) => (
              <Contact {...props} title={documentTitle + " | Kontakt"} />
            )}
          />
          <Route
            exact
            path="/patients"
            render={(props) => {
              return isAuthenticated ? (
                <HomePatientProvider>
                  <HomePatient
                    {...props}
                    title={documentTitle + " | Startseite"}
                  />
                </HomePatientProvider>
              ) : (
                <Redirect to="/loginpatient" />
              );
            }}
          />{" "}
          <Route
            path="/patients/survey"
            render={(props) => {
              return isAuthenticated ? (
                <RunSurvey {...props} title={documentTitle + " | Befragung"} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/loginpatient",
                    state: { redirectTo: "/patients/survey" },
                  }}
                />
              );
            }}
          />{" "}
          <Route
            path="/patients/report"
            render={(props) => {
              return isAuthenticated ? (
                <PatientReport
                  {...props}
                  title={documentTitle + " | Patientenbericht"}
                />
              ) : (
                <Redirect to="/loginpatient" />
              );
            }}
          />{" "}
          <Route
            path="/clinician/patient-report"
            render={(props) => {
              return isAuthenticated ? (
                <PatientReport
                  {...props}
                  title={documentTitle + " | Patientenbericht"}
                />
              ) : (
                <Redirect to="/loginclinician" />
              );
            }}
          />{" "}
          <Route
            path="/patients/information"
            render={(props) => {
              return isAuthenticated ? (
                <InformationPagePatient
                  {...props}
                  title={documentTitle + " | Informationen"}
                />
              ) : (
                <Redirect to="/loginpatient" />
              );
            }}
          />
          <Route
            exact
            path="/clinician"
            render={(props) => {
              return isAuthenticated ? (
                <HomeClinician
                  {...props}
                  title={documentTitle + " | Startseite"}
                />
              ) : (
                <Redirect to="/loginclinician" />
              );
            }}
          />{" "}
          <Route
            path="/clinician/clinician-report"
            render={(props) => {
              return isAuthenticated ? (
                <ClinicianReport
                  {...props}
                  title={documentTitle + " | Mitarbeiterbericht"}
                />
              ) : (
                <Redirect to="/loginclinician" />
              );
            }}
          />{" "}
          <Route
            path="/clinician/information"
            render={(props) => {
              return isAuthenticated ? (
                <InformationPageClinician
                  {...props}
                  title={documentTitle + " | Informationen"}
                />
              ) : (
                <Redirect to="/loginclinician" />
              );
            }}
          />
          <Route
            exact
            path="/admin"
            render={(props) => {
              return isAuthenticated ? (
                <HomeClinicAdmin
                  {...props}
                  title={documentTitle + " | Startseite"}
                />
              ) : (
                <Redirect to="/loginadmin" />
              );
            }}
          />{" "}
          <Route
            path="/admin/information"
            render={(props) => {
              return isAuthenticated ? (
                <InformationPageAdmin
                  {...props}
                  title={documentTitle + " | Informationen"}
                />
              ) : (
                <Redirect to="/loginadmin" />
              );
            }}
          />
          <Route component={Page404} />{" "}
        </Switch>{" "}
      </Router>
    </Fragment>
  );
};

export default App;
