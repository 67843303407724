import React from "react";
import { Row, Card, Button } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";

const InfoTabPatient = () => {
  return (
    <Row>
      <Card tabIndex={0} className="card-default card-custom">
        <Card.Body>
          <Card.Title>Was ist SysDok?</Card.Title>
          <Card.Text>
            Bei SysDok handelt es sich um eine Online-Plattform, auf der
            Patient:innen (und gegebenenfalls Angehörige) im Verlauf ihrer
            Psychotherapie regelmäßig an anonymen Befragungen teilnehmen können.
            Die zugehörigen Therapeut:innen erhalten automatisch eine Auswertung
            der Befragungen zur Nutzung für die weitere Therapie.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card tabIndex={0} className="card-default card-custom">
        <Card.Body>
          <Card.Title>Anleitung</Card.Title>
          <Card.Text>
            Hier finden Sie eine Anleitung zur Benutzung der SysDok-Plattform.
          </Card.Text>

          <a
            tabIndex={-1}
            href="https://sysdok.de/%5Efiler/canonical/1698387252/146/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">
              <FiDownload /> Anleitung herunterladen
            </Button>
          </a>
        </Card.Body>
      </Card>
      <Card tabIndex={0} className="card-default card-custom">
        <Card.Body>
          <Card.Title>Studieninformationen für Patient*innen</Card.Title>
          <Card.Text>
            Für Patient*innen, die an der Studie teilnehmen, können hier die
            Informationen zur Studie und zum Datenschutz heruntergeladen werden.
          </Card.Text>

          <a
            tabIndex={-1}
            href="https://sysdok.de/%5Efiler/canonical/1698387252/148/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">
              <FiDownload /> Studieninformationen herunterladen
            </Button>
          </a>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default InfoTabPatient;
