import React from "react";
import { Form, Row } from "react-bootstrap";
import { Labels } from "./PatientLabels";

interface SessionEndReasonProps {
  labels: Labels;
  setLabels: (labels: Labels) => void;
}

const SessionEndReason = ({ labels, setLabels }: SessionEndReasonProps) => {
  const handleEndReason = (event) => {
    const endReason = { key: "THERAPY_END_REASON", value: event.target.value };
    const reasonExists = labels.some(
      (label) => label.key === "THERAPY_END_REASON",
    );

    let updatedLabels;
    if (reasonExists) {
      updatedLabels = labels.map((label) =>
        label.key === "THERAPY_END_REASON" ? endReason : label,
      );
    } else {
      updatedLabels = [...labels, endReason];
    }

    setLabels(updatedLabels); // Update the state with the new labels array
  };

  return (
    <Row className="mb-3">
      <Form.Group>
        <Form.Label>Beendigungsgrund</Form.Label>
        <Form.Select
          className="form-control"
          onChange={(event) => {
            handleEndReason(event);
          }}
          defaultValue=""
        >
          <option value="" disabled selected>
            Auswählen...
          </option>
          <option value="REGULAR_ENDING">Reguläres Therapieende</option>
          <option value="EARLY_TERMINATION_BY_MUTUAL_AGREEMENT">
            Vorzeitiges Therapieende(im Einverständnis)
          </option>
          <option value="TERMINATION_BY_PATIENT">
            Therapieabbruch durch Patient*in
          </option>
          <option value="TERMINATION_BY_THERAPIST">
            Therapieabbruch durch Therapeut*in
          </option>
        </Form.Select>
      </Form.Group>
    </Row>
  );
};

export default SessionEndReason;
