import React, { useEffect, useState } from "react";
import { Patient } from "../../services/getFunctions";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { SurveyStatusAndStws, zipSurveyStatusAndStw } from "../../helper/utils";

interface SurveyStatusModalProps {
  currentPatient: Patient;
  modalSurveyStateOpen: boolean;
  closeSurveyStateModal: () => void;
}

const SurveyStatusModal = ({
  currentPatient,
  modalSurveyStateOpen,
  closeSurveyStateModal,
}: SurveyStatusModalProps) => {
  let stws;
  let surveyStatuses;
  const [surveyStatusAndStws, setSurveyStatusAndStws] = useState<
    SurveyStatusAndStws[]
  >([]);

  useEffect(() => {
    if (modalSurveyStateOpen) {
      console.log("This current patient:", currentPatient);
      surveyStatuses = currentPatient.survey_statuses;
      stws = currentPatient.survey_time_windows;

      console.log("survey statestate", surveyStatuses);
      // transform to date from string and English to Germen

      console.log("stws stws stws", stws);

      // stws = sortStwsByStartTime(stws);

      const zippedSurveyStatusAndStws = zipSurveyStatusAndStw(
        stws.stws,
        surveyStatuses,
      );
      setSurveyStatusAndStws(zippedSurveyStatusAndStws);
      console.log("zippppppppppp", zippedSurveyStatusAndStws);
    }
  }, [modalSurveyStateOpen]);

  if (!modalSurveyStateOpen) return null;

  return (
    <div>
      <Modal
        backdrop="static"
        show={modalSurveyStateOpen}
        onHide={closeSurveyStateModal}
      >
        <ModalHeader closeButton>
          <ModalTitle>
            Befragungsstatus von <em>{currentPatient.code}</em>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          {surveyStatusAndStws.map((state) => {
            const key = state.stw_start;
            return (
              <div key={key}>
                {state.stw_type_deutsch} : {state.survey_status} <br />
              </div>
            );
          })}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default SurveyStatusModal;
