import React from "react";
import { Row, Card, Button } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { IoMdOpen } from "react-icons/io";

const InfoTabClinician = () => {
  return (
    <Row>
      <Card className="card-default card-custom">
        <Card.Body>
          <Card.Title>Schulungsmaterial</Card.Title>
          <Card.Text>
            Hier finden Sie die Folien der Schulungspräsentation.
          </Card.Text>

          <a
            href="https://sysdok.de/%5Efiler/canonical/1698387252/145/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">
              <FiDownload /> Schulungsmaterial herunterladen
            </Button>
          </a>
        </Card.Body>
      </Card>
      <Card className="card-default card-custom">
        <Card.Body>
          <Card.Title>Testmanual</Card.Title>
          <Card.Text>
            Hier finden Sie ein Handbuch mit Informationen zur Verwendung und
            Bedienung von SysDok.
          </Card.Text>

          <a
            href="https://sysdok.de/%5Efiler/canonical/1698387252/147/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="primary">
              <FiDownload /> Testmanual herunterladen
            </Button>
          </a>
        </Card.Body>
      </Card>
      <Card className="card-default card-custom">
        <Card.Body>
          <Card.Title>FAQs</Card.Title>
          <Card.Text>
            Hier finden Sie Antworten auf häufige Fragen von Therapeut:innen.
          </Card.Text>

          <a
            href="https://sysdok.de/de/fuer-therapeutinnen/#FAQ"
            target="blank"
          >
            <Button variant="primary">
              <IoMdOpen /> Link öffnen
            </Button>
          </a>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default InfoTabClinician;
