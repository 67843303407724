import React, { FC, useContext, useEffect, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import { PubSub } from "../../helper/pubSub";
import { Button } from "react-bootstrap";
import { AppContext, AppContextType } from "../../AppProvider";
import LogoUulm from "../../img/Logo_uulm.png";
import HospitalLogo from "../../img/user/hospital.png";
import PatientLogo from "../../img/user/patient.png";

interface SidebarClinicianProps {
  handleAccordion: (accordion: string) => void;
}

const SidebarClinician: FC<SidebarClinicianProps> = ({ handleAccordion }) => {
  //const { state: authState } = useContext<AuthContextType>(AuthContext);
  const { state: appState } = useContext<AppContextType>(AppContext);
  // const { accordionMyPatients } = props;
  const location = useLocation();
  const informationPaths = [
    "/clinician/information",
    "/impressum",
    "/privacypolicy",
    "/contact",
  ]; //all paths belonging to information section. For these paths the menu stays open
  let info = false;

  if (informationPaths.includes(location.pathname)) {
    info = true;
  }

  const [display, setDisplay] = useState({
    patient: true,
    clinic: false,
    info,
  });

  const closeAllAccordions = () => {
    console.log("Called closeAllAccordions");
    setDisplay((prevState) => ({
      ...prevState,
      patient: false,
      clinic: false,
      info: false,
    }));
    handleAccordion("closeAll");
  };

  const handlePatientItem = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patient: !display.patient,
      clinic: false,
      info: false,
    }));
    handleAccordion("myPatients");
  };

  const handleClinicItem = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patient: false,
      clinic: !display.clinic,
      info: false,
    }));
    handleAccordion("clinics");
  };

  const handleInfoItem = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patient: false,
      clinic: false,
      info: !display.info,
    }));
    handleAccordion("info");
  };

  useEffect(() => {
    const { accordionMyPatients, accordionMyClinics } = appState.accordionData;
    setDisplay((prevState) => ({
      ...prevState,
      patient: accordionMyPatients === "0",
      clinic: accordionMyClinics === "1",
      // info: false,
    }));
  }, [appState]);

  useEffect(() => {
    //runs only once when component is initialized because of the empty array as second argument
    PubSub.getInstance().subscribeToTopic("close-sidebar-submenus", () => {
      closeAllAccordions();
    });
  }, []);

  const renderPatientItem = () => (
    <Button
      title="Patient*innen"
      aria-label="Patient*innen"
      variant=""
      className={
        display.patient ? "active sidenav-item-link" : "sidenav-item-link"
      }
      onClick={handlePatientItem}
    >
      <i className="mdi">
        <img
          className="rounded-circle user-role"
          src={PatientLogo}
          width="25px"
          alt="Patient*innen"
        />
      </i>
      <span className="nav-text">Patient*innen</span>{" "}
      <strong className="caret" />
    </Button>
  );

  const renderClinicItem = () => (
    <Button
      title="Klinik"
      aria-label="Klinik"
      variant=""
      className={
        display.clinic ? "active sidenav-item-link" : "sidenav-item-link"
      }
      onClick={handleClinicItem}
    >
      <i className="mdi">
        <img
          className="rounded-circle user-role"
          src={HospitalLogo}
          width="25px"
          alt="Klinik"
        />
      </i>
      <span className="nav-text">Klinik</span> <strong className="caret" />
    </Button>
  );

  const renderInformationSubMenu = () => (
    <ul
      id="documentation"
      data-parent="#sidebar-menu"
      className={display.info ? "show" : "collapse"}
    >
      <div className="sub-menu p-0">
        <Link
          to={{
            pathname: "/clinician/information",
          }}
        >
          <span className="nav-text">SysDok</span>
        </Link>

        <Link
          to={{
            pathname: "/impressum",
          }}
        >
          <span className="nav-text">Impressum</span>
        </Link>

        <Link
          to={{
            pathname: "/privacypolicy",
          }}
        >
          <span className="nav-text">Datenschutz</span>
        </Link>

        <Link
          to={{
            pathname: "/contact",
          }}
        >
          <span className="nav-text">Kontakt</span>
        </Link>
      </div>
    </ul>
  );

  const renderInformation = () => (
    <div>
      <Button
        title="Informationen"
        aria-label="Informationen"
        variant=""
        className={
          display.info ? "active sidenav-item-link" : "sidenav-item-link"
        }
        style={{ cursor: "pointer" }}
        onClick={handleInfoItem}
        id="clinic-stuff-sidebar-information"
        data-toggle="collapse"
        data-target="#documentation"
        aria-expanded="false"
        aria-controls="documentation"
      >
        <i className="mdi">
          <IoMdInformationCircle />
        </i>
        <span className="nav-text">Informationen</span>
        <strong className="caret" />
      </Button>
      {renderInformationSubMenu()}{" "}
    </div>
  );

  return (
    <div>
      <div id="sidebar" className="sidebar sidebar-with-footer">
        <div data-simplebar data-height="100%">
          <ul className="nav sidebar-inner" id="sidebar-menu">
            {renderPatientItem()}
            {renderClinicItem()}
            <hr className="separator mb-0" />
            {renderInformation()}
          </ul>
        </div>
        <div className="sidebar-footer sidebar-footer-content">
          <a
            id="sidebar-logo"
            href="https://www.uni-ulm.de/in/abt-klinische-psychologie-und-psychotherapie/"
            target="blank"
          >
            <img
              src={LogoUulm}
              width="160px"
              alt="Logo Universität Ulm: Öffnet neuen Tab: Universitätswebseite der Abteilung klinische Psychologie und Psychotherapie"
              title="Öffnet neuen Tab: Universisätswebseite der Abteilung klinische Psychologie und Psychotherapie"
            />
          </a>
          <a
            id="sidebar-logo-two"
            href="https://www.uniklinik-freiburg.de/de.html"
            target="blank"
          >
            <img
              src={"../../img/UKF_LOGO.png"}
              width="160px"
              alt="Logo Universitätsklinikum Freiburg: Öffnet neuen Tab: Universitätsklinikumwebseite"
              title="Öffnet neuen Tab: Universisätsklinikumwebseite"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
export default SidebarClinician;
